import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import JobSearchResult from "../search/JobSearchResult";
import useQueryJobLastViewed from "./useQueryJobLastViewed";
import LastViewedList from "../../common/LastViewedList";

const LIST_LENGTH = 20;

export default function LastViewedJobs(props) {

  const {clientId} = props;

  const {data: items, loading, errors} = useQueryJobLastViewed(LIST_LENGTH, clientId);

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "LastViewedJobs");

  const listTitle = t("reporting:jobs_last_viewed_title");
  const emptyMsg = t("reporting:jobs_search_empty_msg");
  const getItemId = (job) => job._id;

  return (
    <LastViewedList
      items = {items}
      loading = {loading}
      errors = {errors}
      className = {"LastViewedJobs"}
      listTitle = {listTitle}
      emptyMsg = {emptyMsg}
      getItemId = {getItemId}
      SearchResult = {<JobSearchResult/>}
    />
  )
}
