import React from "react";
import StringUtils from "../../../utils/StringUtils";
import "./InlineEditInputTypeahead.scss";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import InlineEditBase from "./InlineEditBase";
import withOnBlur from "react-onblur";
import useInlineEditInputEvents from "./useInlineEditInputEvents";
import {Typeahead} from "react-bootstrap-typeahead";
import TypeUtils from "../../../utils/TypeUtils";
import {InputGroup} from "react-bootstrap";
import ActionLink from "./ActionLink";

/**
 * Select box that display the value as read only until we click the Edit icon next to the label.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditInputTypeahead(props) {

  // Add onBlur listeners on the whole edit component to detect when focus is not in any of its children anymore
  const WithOnBlurInputTextEditComponent = withOnBlur()(TypeaheadEditComponent);

  return <InlineEditBase {...props} EditComponent={WithOnBlurInputTextEditComponent}/>
};

function TypeaheadEditComponent(props) {

  const {/*placeholder,*/ value, onSubmit, validate, onCancel, setBlurListener, unsetBlurListener, options, labelKey, emptyLabel, removeLabel} = props;
  const focusRef = React.useRef();

  const formatInput = React.useCallback(input => {
    // Input is an ID, find the complete option and feed the typeahead with it. It may return null.
    return TypeUtils.ensureArray(options).find(item => item._id === input)
  }, [options]);

  const formatOutput = React.useCallback(output => {
    // Output is an option, return only its ID
    return output ? output._id : "";
  }, []);

  // Find the option to display on first render. Select box accepts a list of options
  const initialOptions = React.useMemo(() => {
    const option = formatInput(value);
    return option ? [option] : [];
  }, [formatInput, value])

  const {currentValue, submit, /*cancel,*/ changeValue, validationError} = useInlineEditInputEvents(
    value, onSubmit, validate, onCancel, setBlurListener, unsetBlurListener, focusRef, formatInput, formatOutput
  );

  const hasError = !StringUtils.isNullOrEmpty(validationError);

  const onChangeSelection = selectedValues => {
    if (!TypeUtils.arrayIsEmpty(selectedValues)) {
      changeValue(selectedValues[0]);
    }
    // Don't call submit here, wait until the hook updates its value and submit callback. Trigger submit in a useEffect instead.
  }

  const onClickClear = event => {
    event.preventDefault();
    changeValue({_id: ""});
  }

  React.useEffect(() => {
    // Submit when the selected value changes, but not on first render
    const currentId = currentValue ? currentValue._id : "";
    if (StringUtils.nullToEmpty(currentId) !== StringUtils.nullToEmpty(value)) {
      submit();
    }

  }, [currentValue, value, submit]);

  return (
    <div className={"TypeaheadEditComponent"}>
      <Row className={"input-row"}>
        <Col className="input-col">
          <InputGroup className={"search-input-group"}>
            <Typeahead
              id="searchInput"
              onChange={selected => onChangeSelection(selected)}
              labelKey={labelKey}
              options={options}
              emptyLabel={emptyLabel}
              highlightOnlyResult
              paginate={false}
              className={"search-input"}
              defaultSelected={initialOptions}
              open
            />
            <InputGroup.Text className={"search-input-group-text"}><ActionLink onClick={onClickClear} className={"search-action"}>{removeLabel}</ActionLink></InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {hasError && <div className={"error"}>{validationError}</div>}
        </Col>
      </Row>
    </div>
  );
}
