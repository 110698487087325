import React from "react";
import ActionDiv from "../../../common/widgets/ActionDiv";
import "./PoolJobAdd.scss";
import {Image} from "react-bootstrap";
import AddImage from "../../../../img/add.png";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

export default function PoolJobAdd(props) {

  const {onClick} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobAdd");

  return (
    <div className="PoolJobAdd">
      <ActionDiv className="action" onClick={onClick}>
        <Image src={AddImage} alt={t("market:pool_jobs_create_action")}/>
      </ActionDiv>
    </div>
  );
};
