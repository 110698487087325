import React from "react";
import "./FadeInLoader.scss";

/**
 * Wrap a loading icon with this component to add a fade in effect when the loading widget is displayed.
 *
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
export default function FadeInLoader(props) {

  const {icon} = props;
  return (
    <div className="FadeInLoader">
      {icon}
    </div>
  );
}
