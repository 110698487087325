import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import * as ROUTES from "../../../../constants/routes";
import {useNavigate} from "react-router-dom";
import JobDeleteButton from "../mutate/JobDeleteButton";
import RouterUtils from "../../../../utils/RouterUtils";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import JobInlineEditInputText from "../mutate/JobInlineEditInputText";
import StringUtils from "../../../../utils/StringUtils";
import "./JobDetails.scss";
import JobInlineEditInputTextArray from "../mutate/JobInlineEditInputTextArray";
import useQuestionnaireTypeaheadOptions from "../../clients/view/useQuestionnaireTypeaheadOptions";
import WaitForData from "../../../common/data-fetching/WaitForData";
import JobInlineEditInputTypeahead from "../mutate/JobInlineEditInputTypeahead";

export default function JobDetails(props) {

  const {job} = props;
  const navigate = useNavigate();
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "JobDetails");

  // Prepare the options for the questionnaire select box
  const {loading, errors, ...typeaheadProps} = useQuestionnaireTypeaheadOptions(job.questionnaire);

  const onDeleteCompleted = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.CLIENT, {clientId: job.clientId}));
  }

  const validateNotificationEmail = React.useCallback(email => {

    if (!StringUtils.isNullOrEmpty(email) && !StringUtils.isEmail(email)) {
      return t("reporting:jobs_details_notification_email_invalid");
    }
  }, [t]);

  return (
    <div className="JobDetails">
      <Row>
        <Col>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col className={"section"}>
          <Row>
            <Col className={"label"}>
              {t("reporting:jobs_details_questionnaire_title")}
            </Col>
          </Row>
          <Row>
            <Col>
              <WaitForData loading={loading} errors={errors} onLoaded={() =>
                <JobInlineEditInputTypeahead
                  job={job}
                  property={"questionnaireId"}
                  {...typeaheadProps}
                />
              }/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={"section"}>
          <Row>
            <Col className={"label"}>
              {t("reporting:jobs_details_notification_sender_title")}
            </Col>
          </Row>
          <Row>
            <Col>
              <JobInlineEditInputText
                job={job}
                placeholder={t("reporting:jobs_details_notification_sender_name_placeholder")}
                property={"notificationSenderName"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <JobInlineEditInputText
                job={job}
                placeholder={t("reporting:jobs_details_notification_sender_email_placeholder")}
                property={"notificationSenderEmail"}
                validate={validateNotificationEmail}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={"section"}>
          <Row>
            <Col className={"label"}>
              {t("reporting:jobs_details_notification_recipient_title")}
            </Col>
          </Row>
          <Row>
            <Col>
              <JobInlineEditInputTextArray
                job={job}
                placeholder={t("reporting:jobs_details_notification_recipient_email_placeholder")}
                property={"notificationRecipientEmails"}
                validate={validateNotificationEmail}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={"section"}>
          <Row>
            <Col className={"label"}>
              {t("reporting:details_other_actions_title")}
            </Col>
          </Row>
          <Row>
            <Col>
              <JobDeleteButton jobId={job._id} onCompleted={onDeleteCompleted}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr/>
        </Col>
      </Row>
    </div>
  );
};
