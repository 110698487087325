import React from "react";
import InlineEditInputTypeahead from "../../../common/widgets/InlineEditInputTypeahead";
import ClientInlineEditInput from "./ClientInlineEditInput";

/**
 * Generic component for all Client editable select fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ClientInlineEditInputTypeahead(props) {

  return <ClientInlineEditInput InlineEditInputComponent={InlineEditInputTypeahead} {...props}/>
};
