import React from "react";
import Loader from "../../common/loading-widgets/Loader";
import {Collapse} from "react-collapse";
import "./DetailsCollapsibleSection.scss";

export default function DetailsCollapsibleSection(props) {

  const {children, isOpened} = props;

  return (
    <Collapse isOpened={isOpened}>
      <Loader>
        {children}
      </Loader>
    </Collapse>
  );
};
