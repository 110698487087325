import {gql} from "@apollo/client";
import useWrappedQuery from "../data-fetching/useWrappedQuery";

export const QUERY_NAME = "globalMaxUploadFileSize";

export const QUERY = gql`
  query ${QUERY_NAME} {
    ${QUERY_NAME}
  }
`;

export default function useQueryMaxUploadFileSize() {

  const variables = {
  };

  return useWrappedQuery(QUERY, "", QUERY_NAME, {variables});
}
