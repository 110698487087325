import React from "react";
import StringUtils from "../../../../utils/StringUtils";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ReportInlineEditInputText from "./ReportInlineEditInputText";

export default function ReportInlineEditName(props) {

  const {report, viewAs} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportInlineEditName");

  const validateName = React.useCallback(value => {
    if (StringUtils.isNullOrEmpty(value))
      return t("reporting:reports_details_name_required_error");
  }, [t]);

  return (
    <ReportInlineEditInputText
      report={report}
      placeholder={t("reporting:reports_details_name_placeholder")}
      property={"name"}
      validate={validateName}
      viewAs={viewAs}
      className="ReportInlineEditName"
    />
  );
}
