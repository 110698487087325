import React from "react";
import "./ClientsStatsBlock.scss";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import HtmlUtils from "../../../../utils/HtmlUtils";
import StringUtils from "../../../../utils/StringUtils";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryClientCount from "./useQueryClientCount";
import ClientListDownloadLink from "./ClientListDownloadLink";
import useQueryClientRecipientCount from "./useQueryClientRecipientCount";

export default function ClientsStatsBlock(/*props*/) {

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientsStatsBlock");

  return (
    <div className="ClientsStatsBlock">
      <Row>
        <Col className={"title"}>
          <h2>{t("reporting:clients_clients_stats_title")}</h2>
        </Col>
      </Row>
      <Row>
        <Col className={"stats-col"}>
          <Row>
            <Col>
              <AsyncStats/>
            </Col>
          </Row>
          <Row>
            <Col>
              <ClientListDownloadLink className={"list-download-link"}>{t("reporting:clients_list_download_action")}</ClientListDownloadLink>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

function AsyncStats(/*props*/) {

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientsStatsBlock");

  const {data: clientCount, clientLoading, clientErrors} = useQueryClientCount();
  const {data: recipientCount, recipientLoading, recipientErrors} = useQueryClientRecipientCount();

  return (
    <WaitForData loading={clientLoading || recipientLoading} errors={clientErrors || recipientErrors} onLoaded={() =>
      <div className="AsyncStats">
        <Row>
          <Col>
            {HtmlUtils.htmlToComponents(t("reporting:clients_clients_stats_client_count", {count: StringUtils.nullToZero(clientCount)}))}
          </Col>
        </Row>
        <Row>
          <Col>
            {HtmlUtils.htmlToComponents(t("reporting:clients_clients_stats_recipient_count", {count: StringUtils.nullToZero(recipientCount)}))}
          </Col>
        </Row>
      </div>
    }/>
  );
}
