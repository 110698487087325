import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_CLIENT_LAST_VIEWED} from "../view/useQueryClientLastViewed";

const QUERY_NAME = "clientCreate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $name: String!,
    ) {
      ${QUERY_NAME} (
        name: $name,
      ) {
        _id
        name
      }
    }
  `;

export default function useMutationClientCreate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_CLIENT_LAST_VIEWED]
  });
}
