import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import clientsActions from "../actions";
import Page from "../../../common/page/Page";
import {useParams} from "react-router";
import useQueryClientById from "./useQueryClientById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import ClientBlock from "./ClientBlock";
import useMutationClientViewUpdate from "../mutate/useMutationClientViewUpdate";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

export default function ClientPage(props) {

  // Fetch client from ID provided in the url params.
  const urlParams = useParams();
  const clientId = urlParams.clientId;
  const {data: client, loading: clientLoading, errors: clientErrors} = useQueryClientById(clientId);
  // We'll touch the client view silently in the background, no need to track loading state or error
  const {mutate} = useMutationClientViewUpdate();
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientPage");

  // Touch the client silently every time the page renders with a new clientId or the user has performed an action to
  // change the client.
  React.useEffect(() => {
    if (client) {
      mutate({
        variables: {
          clientId: client._id
        }
      }).catch(err => {/* fail silently */});
    }
  }, [client, mutate]);

  return (
    <WithRedirectionUserIsAuthorized action={clientsActions.CLIENTS_VIEW} yes={() =>
      <WaitForData loading={clientLoading} errors={clientErrors} onLoaded={() =>
        <>
          {client &&
          <Page className="ClientPage">
            <Page.Title loading={clientLoading} text={client.name}/>
            <Page.Content className="spaced-children"><ClientBlock client={client}/></Page.Content>
          </Page>
          }
          {!client &&
          <Page className="ClientPage">
            <Page.Title loading={clientLoading}>{t("reporting:clients_details_not_found")}</Page.Title>
          </Page>
          }
        </>
      }/>
    }/>
  );
}
