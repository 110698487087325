import React from "react";
import InlineEditInputBoolean from "../../../common/widgets/InlineEditInputBoolean";
import TalentPoolInlineEditInput from "./TalentPoolInlineEditInput";

/**
 * Generic component for all pool editable boolean fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TalentPoolInlineEditInputBoolean(props) {

  return <TalentPoolInlineEditInput InlineEditInputComponent={InlineEditInputBoolean} {...props}/>
};
