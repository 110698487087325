import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import TypeUtils from "../../../../utils/TypeUtils";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useContentModal from "../../../common/modals/useContentModal";
import TalentPoolListItem from "./TalentPoolListItem";
import TalentPoolPreview from "./TalentPoolPreview";
import TalentPoolAdd from "./TalentPoolAdd";
import "./TalentPoolList.scss";
import TalentPoolCreateForm from "../mutate/TalentPoolCreateForm";
import {useNavigate} from "react-router-dom";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import useQueryTalentPools from "../search/useQueryTalentPools";

export default function TalentPoolList(/*props*/) {

  const navigate = useNavigate();

  // Modal to show job creation form
  const {ContentModal: TalentPoolCreateModal, show: showTalentPoolCreateModal, hide: hideTalentPoolCreateModal} = useContentModal();

  const {data: talentPools, loading: talentPoolsLoading, errors: talentPoolsErrors} = useQueryTalentPools();
  // Remove null talentPools. This can happen after a deletion, before the list is updated
  const nonNullTalentPools = talentPools ? TypeUtils.ensureArray(talentPools.nodes).filter(node => node) : [];

  const goToTalentPoolDetails = (talentPoolId) => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.POOL, {talentPoolId}))
  }

  const onClickTalentPool = (talentPoolId) => {
    goToTalentPoolDetails(talentPoolId);
  }

  const onClickAddTalentPool = () => {
    const talentPoolCreateForm = <TalentPoolCreateForm onAbort={hideTalentPoolCreateModal} onSuccess={onTalentPoolCreated}/>;
    showTalentPoolCreateModal(talentPoolCreateForm);
  }

  const onTalentPoolCreated = (talentPool) => {
    hideTalentPoolCreateModal();
    goToTalentPoolDetails(talentPool._id)
  }

  return (
    <>
      {TalentPoolCreateModal}
      <div className="TalentPoolList">
        <WaitForData loading={talentPoolsLoading} errors={talentPoolsErrors} onLoaded={() =>
          <Row className={"talentPools-row"}>
            {nonNullTalentPools.map(talentPool =>
              <Col key={talentPool._id} className={"talentPool-col"}>
                <TalentPoolListItem>
                  <TalentPoolPreview talentPool={talentPool} onClick={() => onClickTalentPool(talentPool._id)}/>
                </TalentPoolListItem>
              </Col>
            )}
            <Col className={"talentPool-col"}>
              <TalentPoolListItem>
                <TalentPoolAdd onClick={onClickAddTalentPool}/>
              </TalentPoolListItem>
            </Col>
          </Row>
        }/>
      </div>
    </>
  );
};
