import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "clientById";

export const QUERY = gql`
  query ${QUERY_NAME}($id: ID!) {
    ${QUERY_NAME}(id: $id) {
      _id
      name
      questionnaireId
      questionnaire {
        _id
        name
      }
    }
  }
`;

export default function useQueryClientById(id) {

  const variables = {id};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {variables});
}
