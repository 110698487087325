import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_REPORT} from "../view/useQueryReportById";
import {QUERY_NAME as QUERY_REPORT_LAST_VIEWED} from "../view/useQueryReportLastViewed";
import {QUERY_NAME as QUERY_CANDIDATES} from "../../candidates/view/useQueryCandidatesByReportId";

const QUERY_NAME = "reportResetViewed";

export const QUERY = gql`
    mutation ${QUERY_NAME} ($id: ID!) {
      ${QUERY_NAME} (id: $id) {
        _id
      viewed
      }
    }
  `;

export default function useMutationReportResetViewed() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_REPORT, QUERY_REPORT_LAST_VIEWED, QUERY_CANDIDATES]
  });
}
