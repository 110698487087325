import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import TypeUtils from "../../../../utils/TypeUtils";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useContentModal from "../../../common/modals/useContentModal";
import JobCategoryListItem from "./JobCategoryListItem";
import JobCategoryPreview from "./JobCategoryPreview";
import JobCategoryAdd from "./JobCategoryAdd";
import "./JobCategoryList.scss";
import useQueryJobCategoriesByPoolJobId from "./useQueryJobCategoriesByPoolJobId";
import JobCategoryCreateForm from "../mutate/JobCategoryCreateForm";
import {useNavigate} from "react-router-dom";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";

export default function JobCategoryList(props) {

  const {talentPoolId, poolJobId} = props;

  const navigate = useNavigate();

  // Modal to show job creation form
  const {ContentModal: JobCategoryCreateModal, show: showJobCategoryCreateModal, hide: hideJobCategoryCreateModal} = useContentModal();

  const {data: jobCategories, loading: jobCategoriesLoading, errors: jobCategoriesErrors} = useQueryJobCategoriesByPoolJobId(poolJobId);
  // Remove null jobCategories. This can happen after a deletion, before the list is updated
  const nonNullJobCategories = jobCategories ? TypeUtils.ensureArray(jobCategories.nodes).filter(node => node) : [];

  const goToJobCategoryDetails = (jobCategoryId) => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.JOB_CATEGORY, {talentPoolId, poolJobId, jobCategoryId}))
  }

  const onClickJobCategory = (jobCategoryId) => {
    goToJobCategoryDetails(jobCategoryId);
  }

  const onClickAddJobCategory = () => {
    const jobCategoryCreateForm = <JobCategoryCreateForm onAbort={hideJobCategoryCreateModal} onSuccess={onJobCategoryCreated} poolJobId={poolJobId}/>;
    showJobCategoryCreateModal(jobCategoryCreateForm);
  }

  const onJobCategoryCreated = (jobCategory) => {
    hideJobCategoryCreateModal();
    goToJobCategoryDetails(jobCategory._id)
  }

  return (
    <>
      {JobCategoryCreateModal}
      <div className="JobCategoryList">
        <WaitForData loading={jobCategoriesLoading} errors={jobCategoriesErrors} onLoaded={() =>
          <Row className={"jobCategories-row"}>
            {nonNullJobCategories.map(jobCategory =>
              <Col key={jobCategory._id} className={"jobCategory-col"}>
                <JobCategoryListItem>
                  <JobCategoryPreview jobCategory={jobCategory} onClick={() => onClickJobCategory(jobCategory._id)}/>
                </JobCategoryListItem>
              </Col>
            )}
            <Col className={"jobCategory-col"}>
              <JobCategoryListItem>
                <JobCategoryAdd onClick={onClickAddJobCategory}/>
              </JobCategoryListItem>
            </Col>
          </Row>
        }/>
      </div>
    </>
  );
};
