import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_QUESTIONNAIRE_LAST_VIEWED} from "../view/useQueryQuestionnaireLastViewed";

const QUERY_NAME = "questionnaireViewUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $questionnaireId: ID!,
    ) {
      ${QUERY_NAME} (
        questionnaireId: $questionnaireId,
      )
    }
  `;

export default function useMutationQuestionnaireViewUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    // Beware that these queries won't fire if their React component is not mounted
    refetchQueries: [QUERY_QUESTIONNAIRE_LAST_VIEWED]
  });
}
