import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import talentPoolsActions from "../actions";
import Page from "../../../common/page/Page";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import TalentPoolList from "./TalentPoolList";

export default function TalentPoolsPage(/*props*/) {

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolsPage");

  return (
    <WithRedirectionUserIsAuthorized action={talentPoolsActions.TALENT_POOLS_VIEW_ALL} yes={() =>
      <Page className="TalentPoolsPage">
        <Page.Title>{t("market:talent_pools_all_title")}</Page.Title>
          <Page.Content>
            <TalentPoolList/>
          </Page.Content>
      </Page>
    }/>
  );

}
