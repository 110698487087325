import React from "react";
import Modal from "react-bootstrap/Modal";
import "./DisconnectedModal.scss";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import * as PropTypes from "prop-types";

function ActionButtonWithIconCancelButton(props) {
  return null;
}

ActionButtonWithIconCancelButton.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node
};
export default function DisconnectedModal(props) {

  const {t, loading: tLoading} = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "DisconnectedModal");

  // RENDER

  return (
    <Modal show={props.show} onHide={props.handleClose} className="DisconnectedModal">
      <Modal.Body className={"spaced-children"}>
        <div>{t("authentication-ui:disconnected_msg")}</div>
        <div className={"button-row"}>
          <ActionButtonWithIconCancelButton onClick={props.handleClose}>
            {t("authentication-ui:close_button")}
          </ActionButtonWithIconCancelButton>
        </div>
      </Modal.Body>
    </Modal>
  );
}
