import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_CANDIDATE_SEARCH} from "../view/useQueryCandidatesByReportId";

const QUERY_NAME = "candidateCreate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $name: String!,
      $reportId: ID!
    ) {
      ${QUERY_NAME} (
        name: $name,
        reportId: $reportId
      ) {
        _id
        name
        reportId
      }
    }
  `;

export default function useMutationCandidateCreate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_CANDIDATE_SEARCH]
  });
}
