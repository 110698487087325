import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useQueryClientLastViewed from "./useQueryClientLastViewed";
import LastViewedList from "../../common/LastViewedList";
import ClientSearchResult from "../search/ClientSearchResult";

const LIST_LENGTH = 20;

export default function LastViewedClients(props) {

  const {data: items, loading, errors} = useQueryClientLastViewed(LIST_LENGTH);
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "LastViewedClients");

  const listTitle = t("reporting:clients_last_viewed_title");
  const emptyMsg = t("reporting:clients_search_empty_msg");
  const getItemId = (client) => client._id;

  return (
    <LastViewedList
      items = {items}
      loading = {loading}
      errors = {errors}
      className = {"LastViewedClients"}
      listTitle = {listTitle}
      emptyMsg = {emptyMsg}
      getItemId = {getItemId}
      SearchResult = {<ClientSearchResult/>}
    />
  )
};
