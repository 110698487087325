import useModuleTranslation from "../infra-no-ui/translation/useModuleTranslation";
import React from "react";

/**
 * Dynamically load translation files pertaining to the pages module.
 * @return {*}
 */
export default function useTranslationHelpPage() {

  // Imports must be memoized so that they don't change on every render
  const imports = React.useMemo(() => ({
    'en': import('./locales/en/help-page.json'),
    'fr': import('./locales/fr/help-page.json')
  }), []);

  return useModuleTranslation('help-page', imports);
}
