import React from "react";
import useMutationAnswerUpdate from "./useMutationAnswerUpdate";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useErrorModal from "../../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import StringUtils from "../../../../utils/StringUtils";
import useMutationAnswerCreate from "../../answers/mutate/useMutationAnswerCreate";

/**
 * Generic component for all answer editable fields. If no answer is provided but a questionId and candidateId is provided,
 * the answer is created. If an answer with empty text is provided, it is deleted (update returns null).
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function AnswerInlineEditInput(props) {

  const {InlineEditInputComponent, answer: answerProp, viewAs, validate, placeholder, property, className, questionId, candidateId, onCompleted, ...otherProps} = props;

  // Initialize answer. If ID is empty, it means we want to create a answer.
  const answer = answerProp ? answerProp : {};
  const isUpdate = !!answer._id;

  const {mutate: updateMutate, loading: updateLoading, errors: updateErrors} = useMutationAnswerUpdate();
  const {mutate: createMutate, loading: createLoading, errors: createErrors} = useMutationAnswerCreate({onCompleted});

  const mutate = isUpdate ? updateMutate : createMutate;
  const loading = isUpdate ? updateLoading : createLoading;
  const errors = isUpdate ? updateErrors : createErrors;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "AnswerInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("reporting:answers_details_update_error")}</p>
      {errorMsg}
    </>
  );

  const onSubmit = (value) => {
    const variables = {};

    // Fill in variables that are different whether we create or update an answer.
    if (isUpdate) {
      variables["id"] = answer._id;
    } else {
      variables["questionId"] = questionId;
      variables["candidateId"] = candidateId;
    }

    // Add the dynamic property to set (must be "text" for a creation)
    variables[property] = value;

    // Creation of an answer with empty text is not possible, so don't create it
    if (!isUpdate && property === 'text' && StringUtils.isNullOrEmpty(value))
      return Promise.resolve();

    return mutate({variables})
      .catch(error => {
        showErrorModal();
        // rethrow error so that InlineEdit component acts on it
        throw new Error(error);
      });
  }

  return (
    <>
      {ErrorModal}
      <div className={["AnswerInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          placeholder={placeholder}
          value={StringUtils.nullToEmpty(answer[property])}
          onSubmit={onSubmit}
          validate={validate}
          viewAs={viewAs}
          loading={loading}
          {...otherProps}
        />
      </div>
    </>
  );
}
