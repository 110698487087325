import {useContext} from "react";
import ReactDOM from "react-dom";
import SidebarContainerRefContext from "./SidebarContainerRefContext";

/**
 * Displays the sidebar component in an html node somewhere else in the React tree, using a portal to the target container div.
 * If reference to target node is null, does not display anything.
 * @return {*}
 * @constructor
 */
export default function SidebarPortal(props) {

  const {component} = props;
  const SidebarContainerRef = useContext(SidebarContainerRefContext);

  // RENDER

  return SidebarContainerRef.current !== null ? ReactDOM.createPortal(component, SidebarContainerRef.current) : null;
}
