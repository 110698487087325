import React from "react";
import { useMutation } from "@apollo/client";
import QueryUtils from "./QueryUtils";
import useGraphQLErrorFormatter from "./useGraphQLErrorFormatter";

// noinspection JSValidateJSDoc
/**
 * Calls useMutation with the given query and options. When mutate callback is called, return the data contents
 * instead of the data attribute (remove unnecessary levels).
 * @param query GQL query
 * @param queryScope Query scope (first child of Query). Leave undefined if scopes are not used.
 * @param queryName Query name (child of scope)
 * @param options Options, if any, to pass to useMutation
 * @return {{mutate: (function(*=): Promise<* | null>), called: boolean, data: (*|null), client: ApolloClient<object>, loading: boolean, error: ApolloError}}
 */
export default function useWrappedMutation(query, queryScope, queryName, options = {}) {

  // Override the onCompleted callback passed as an option with a callback that will remove the query name before passing the result to the onCompleted callback
  const higherLevelOnCompleted = options.onCompleted;
  if (higherLevelOnCompleted != null)
    options.onCompleted = data => higherLevelOnCompleted(QueryUtils.removeQueryScopeAndName(data, queryScope, queryName));

  const [mutate, { data, loading, error, called, client }] = useMutation(
    query,
    options
  );

  // Decorate lower-level "mutate" callback to remove the query name from the result passed to the caller
  const mutateWrapper = React.useCallback(params => {
    return mutate(params).then((result) =>
      result ? QueryUtils.removeQueryScopeAndName(result.data, queryScope, queryName) : null
    );
  }, [mutate, queryScope, queryName]);

  // Convert the ApolloError into a normalized error
  const normalizedErrors = useGraphQLErrorFormatter(error);

  return {
    mutate: mutateWrapper,
    loading,
    errors: normalizedErrors,
    called,
    client,
    data: QueryUtils.removeQueryScopeAndName(data, queryScope, queryName),
  };
}
