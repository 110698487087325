import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_CLIENT_LAST_VIEWED} from "../view/useQueryClientLastViewed";

const QUERY_NAME = "clientViewUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $clientId: ID!,
    ) {
      ${QUERY_NAME} (
        clientId: $clientId,
      )
    }
  `;

export default function useMutationClientViewUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    // Beware that these queries won't fire if their React component is not mounted
    refetchQueries: [QUERY_CLIENT_LAST_VIEWED]
  });
}
