import React from "react";
import Dropzone from 'react-dropzone'
import "./ResumeDropbox.scss";
import xbytes from "xbytes";
import useTranslationCommon from "../../common/useTranslationCommon";
import useShowLoader from "../loading-widgets/useShowLoader";
import useQueryMaxUploadFileSize from "../file-upload/useQueryMaxUploadFileSize";
import useServerErrorFormatter from "../modals/useServerErrorFormatter";
import useErrorModal from "../modals/useErrorModal";
import WaitForData from "../data-fetching/WaitForData";
import ResumeImage from "../../../img/resume-download.png";
import Image from "react-bootstrap/Image";

export default function ResumeDropbox(props) {

  const {candidateId, onUploadResume, loading, errors, onSuccess} = props;

  // Make checks on file return a promise so that the same catch clause can be used with saveJobOpening
  const fileCheckAsPromise = (files) => {

    // Display error if more than one file is dragged
    if (files.length > 1) {
      return Promise.reject(Error(t("common:candidates_resume_multiple_files_error")));
    }
    // Display error if file size is too big
    if (files[0].size > maxUploadFileSize)
      return Promise.reject(Error(t("common:candidates_resume_file_size_error")));

    return Promise.resolve(null);
  };

  const onDrop = (files) => {

    // If we are processing another file, do nothing
    if (loading)
      return;

    // Return nothing if, for some reason, there are no files
    if (!files || files.length === 0 || files[0] === null)
      return;

    fileCheckAsPromise(files)
      .then(() => onUploadResume(candidateId, files[0]))
      .then(candidate => {
        if (candidate) {
          if (onSuccess)
            onSuccess(candidate);
        }
        else {
          showErrorModal();
        }
      }).catch(error => {
        // error message formatting is handled by hook, just tell to show the error
        showErrorModal();
      });
  };

  const {t, loading: tLoading} = useTranslationCommon();
  useShowLoader(tLoading, "ResumeDropbox");
  const {data: maxUploadFileSize, loading: loadingMaxUploadFileSize, errors: errorsMaxUploadFileSize} = useQueryMaxUploadFileSize();
  const errorMsg = useServerErrorFormatter(errors);
  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("common:candidates_resume_update_error")}</p>
      {errorMsg}
    </>
  );

  // RENDER

  return (
    <>
      {ErrorModal}
      <WaitForData loading={loadingMaxUploadFileSize} errors={errorsMaxUploadFileSize} onLoaded={() =>
        <div className={"ResumeDropbox dropzone " + (loading ? "disabled" : "")}>
          <Dropzone onDrop={(files) => onDrop(files)}>
            {({getRootProps, getInputProps}) => (
              <section className="container">
                <div {...getRootProps()}>
                  {loading && <p>{t("common:candidates_resume_processing")}</p>}
                  {!loading &&
                    <React.Fragment>
                      <input {...getInputProps()} />
                      <p><Image src={ResumeImage}/></p>
                      <p>{t("common:candidates_resume_dropbox_placeholder_1")}</p>
                      <p>{t("common:candidates_resume_dropbox_placeholder_2", {"max": xbytes(maxUploadFileSize, {space: false})})}</p>
                    </React.Fragment>
                  }
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      }/>
    </>
  );
}
