import React from "react";
import useMutationPoolCandidateUploadResume from "./useMutationPoolCandidateUploadResume";
import ResumeUpdate from "../../../common/resume-upload/ResumeUpdate";

export default function PoolCandidateResumeUpdate(props) {

  const {candidateId, onSuccess, onAbort} = props;

  const {mutate, loading, errors} = useMutationPoolCandidateUploadResume();

  const onUploadResume = (candidateId, file) => {
    return mutate({
      variables: {
        id: candidateId,
        file: file,
      }
    });
  }

  return (
    <ResumeUpdate candidateId={candidateId} loading={loading} errors={errors} onSuccess={onSuccess} onUploadResume={onUploadResume} onAbort={onAbort}/>
  );
};
