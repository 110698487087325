import React from "react";
import useMutationQuestionUpdate from "./useMutationQuestionUpdate";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useErrorModal from "../../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import StringUtils from "../../../../utils/StringUtils";
import useMutationQuestionnaireAddQuestion from "../../questionnaires/mutate/useMutationQuestionnaireAddQuestion";

/**
 * Generic component for all question editable fields. If no question is provided but a questionnaireId is provided,
 * the question is created.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function QuestionInlineEditInput(props) {

  const {InlineEditInputComponent, question: questionProp, viewAs, validate, placeholder, property, className, questionnaireId, onCompleted, ...otherProps} = props;

  // Initialize question. If ID is empty, it means we want to create a question.
  const question = questionProp ? questionProp : {};
  const isUpdate = !!question._id;

  const {mutate: updateMutate, loading: updateLoading, errors: updateErrors} = useMutationQuestionUpdate();
  const {mutate: createMutate, loading: createLoading, errors: createErrors} = useMutationQuestionnaireAddQuestion({onCompleted});

  const mutate = isUpdate ? updateMutate : createMutate;
  const loading = isUpdate ? updateLoading : createLoading;
  const errors = isUpdate ? updateErrors : createErrors;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("reporting:questions_details_update_error")}</p>
      {errorMsg}
    </>
  );

  const localValidate = (value) => {
    if (property === 'text' && StringUtils.isNullOrEmpty(value))
      return t("reporting:questions_details_save_without_text_error");

    if (validate)
      return validate();
  }

  const onSubmit = (value) => {
    const variables = {};

    // Fill in variables that are different whether we create or update a question.
    if (isUpdate) {
      variables["id"] = question._id;
    } else {
      variables["questionnaireId"] = questionnaireId;
    }

    // Add the dynamic property to set (must be "text" for a creation)
    variables[property] = value;

    return mutate({variables})
      .catch(error => {
        showErrorModal();
        // rethrow error so that InlineEdit component acts on it
        throw new Error(error);
      });
  }

  return (
    <>
      {ErrorModal}
      <div className={["QuestionInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          placeholder={placeholder}
          value={StringUtils.nullToEmpty(question[property])}
          onSubmit={onSubmit}
          validate={localValidate}
          viewAs={viewAs}
          loading={loading}
          {...otherProps}
        />
      </div>
    </>
  );
}
