import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "jobLastViewed";

export const QUERY = gql`
  query ${QUERY_NAME}($count: Int, $clientId: ID) {
    ${QUERY_NAME}(count: $count, clientId: $clientId) {
      _id
      title
      clientId
    }
  }
`;

export default function useQueryJobLastViewed(count, clientId) {

  const variables = {count, clientId};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network"
  });
}
