import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import useMutationJobCategoryCreate from "./useMutationJobCategoryCreate";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import ActionButtonWithIconGo from "../../../common/widgets/ActionButtonWithIconGo";
import ActionButtonWithIconCancel from "../../../common/widgets/ActionButtonWithIconCancel";
import StringUtils from "../../../../utils/StringUtils";

export default function JobCategoryCreateForm(props) {

  const {onAbort, onSuccess, poolJobId} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading);

  const {mutate, loading: mutationLoading, errors: mutationErrors} = useMutationJobCategoryCreate();

  const errorMsg = useServerErrorFormatter(mutationErrors);
  const {launch, FeedbackModal} = useFeedbackModal({errorMsg});

  const [name, setName] = React.useState("");

  const createJobCategory = () => {
    return mutate({
      variables: {
        name: name.trim(),
        poolJobId: poolJobId
      }
    });
  }

  const onSubmit = (event) => {
    event.preventDefault();
    launch(createJobCategory, onSuccess);
  }

  const canSubmit = () => {
    return !StringUtils.isNullOrEmpty(name);
  }

  return (
    <>
      {FeedbackModal}
      <div className="JobCategoryCreateForm">
        <Form onSubmit={onSubmit} className={"form-with-rows"} autoComplete={"off"}>
          <Row>
            <Col>
              <h1>{t("market:job_categories_create_title")}</h1>
            </Col>
          </Row>
          <Row>
            <Col className={"form-label form-required"}>
              {t("market:job_categories_create_name_label")}
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControl
                name="name"
                type="text"
                value={name}
                onChange={event => setName(event.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col className={"form-actions"}>
              <ActionButtonWithIconGo loading={mutationLoading} type={"submit"} disabled={!canSubmit()}>
                {t("market:job_categories_create_submit_action")}
              </ActionButtonWithIconGo>
              <ActionButtonWithIconCancel onClick={onAbort}>{t("market:job_categories_create_cancel_action")}</ActionButtonWithIconCancel>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
