import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import reportsActions from "../actions";
import Page from "../../../common/page/Page";
import {useParams} from "react-router";
import useQueryReportById from "./useQueryReportById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import ReportBlock from "./ReportBlock";
import useMutationReportViewUpdate from "../mutate/useMutationReportViewUpdate";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

export default function ReportPage(props) {

  // Fetch report from ID provided in the url params.
  const urlParams = useParams();
  const reportId = urlParams.reportId;
  const clientId = urlParams.clientId;
  const jobId = urlParams.jobId;

  const {data: report, loading: reportLoading, errors: reportErrors} = useQueryReportById(reportId);
  // We'll touch the report view silently in the background, no need to track loading state or error
  const {mutate} = useMutationReportViewUpdate();
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportPage");

  // Touch the report silently every time the page renders with a new reportId or the user has performed an action to
  // change the report.
  React.useEffect(() => {
    if (report) {
      mutate({
        variables: {
          reportId: report._id
        }
      }).catch(err => {/* fail silently */});
    }
  }, [report, mutate]);

  return (
    <WithRedirectionUserIsAuthorized action={reportsActions.REPORTS_VIEW} yes={() =>
      <WaitForData loading={reportLoading} errors={reportErrors} onLoaded={() =>
        <>
          {report &&
          <Page className="ReportPage">
            <Page.Title loading={reportLoading} text={report.name}/>
            <Page.Content className="spaced-children"><ReportBlock report={report} clientId={clientId} jobId={jobId}/></Page.Content>
          </Page>
          }
          {!report &&
          <Page className="ReportPage">
            <Page.Title loading={reportLoading}>{t("reporting:reports_details_not_found")}</Page.Title>
          </Page>
          }
        </>
      }/>
    }/>
  );
}
