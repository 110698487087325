import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "emailCreate";

// Return the status of the email in order to notify the sender if the email was sent successfully
export const QUERY = gql`
    mutation ${QUERY_NAME} (
        $reportId: ID!,
        $to: [String],
        $from: String,
        $subject: String,
        $text: String
        $html: String
        $replyTo: String
        $fromName: String
    ) {
      ${QUERY_NAME} (
        reportId: $reportId,
        to: $to,
        from: $from,
        subject:$subject,
        text: $text,
        html: $html,
        replyTo: $replyTo,
        fromName: $fromName
      ) {
        _id
        status
      }
    }
  `;

export default function useMutationEmailCreate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
  });
}
