class StringUtils {

  /**
   * Returns the string passed as argument, or the empty string "" if argument is null or undefined
   * @param string String to return, if not null nor undefined
   * @returns {*} String passed as argument if not null nor undefined, otherwise the empty string ""
   */
  static nullToEmpty = (string) => {
    return (string == null ? "" : string);
  };

  /**
   * Returns the string passed as argument, or undefined if argument is either null or the empty string ""
   * @param string String to return, if not null nor empty
   * @returns {undefined|*} String passed as argument if not null nor empty, otherwise undefined
   */
  static emptyToUndefined = (string) => {
    return StringUtils.isNullOrEmpty(string) ? undefined : string;
  }

  /**
   * Returns the number passed as argument, or 0 if argument is null or undefined
   * @param number Number to return, if not null nor undefined
   * @returns {*} Number passed as argument if not null nor undefined, otherwise 0
   */
  static nullToZero = (number) => {
    return (number == null ? 0 : number);
  };

  /**
   * Returns the number passed as argument, or 0 if argument is null or undefined.
   * When number has more than the specified number of digits, it is formatted as
   * +99 (for max 2 digits).
   * @param number Number to return, if not null nor undefined
   * @param digits Max number of digits
   * @returns {*} Number passed as argument if not null nor undefined, otherwise 0
   */
  static nullToZeroWithMaxDigits = (number, digits) => {
    const nullToZero = StringUtils.nullToZero(number);

    if (digits > 0 && nullToZero >= Math.pow(10, digits)) {
      return "+" + "9".repeat(digits);
    } else
      return nullToZero;
  };

  /**
   * Returns true if the string is either null, undefined or empty ("")
   * @param string String
   * @returns {boolean} True if string is null, undefined or empty ("")
   */
  static isNullOrEmpty = (string) => {
    return StringUtils.nullToEmpty(string) === "";
  };

  /**
   * Returns true if the string is either "1", "true" or "TRUE", otherwise returns false.
   * @param string String to analyze (such as a configuration parameter from file)
   * @return {boolean} True if string is either "1", "true" or "TRUE"
   */
  static stringIsTrue = (string) => {
    return (string === "1" || string === "true" || string === "TRUE");
  };

  /**
   * Returns the number as a string, so that null, undefined and 0 produces "0" instead
   * of an empty string
   * @param number Number to convert to string
   * @returns {string} Number as a string
   */
  static numberToString = (number) => {
    return String(this.nullToZero(number));
  }

  /**
   * Normalize strings so that close duplicates can be removed.
   * Trim, then convert to lowercase.
   * @param string
   * @returns {string}
   */
  static normalizeString = (string) => {

    // For now, do not remove accents, because fields indexed in GraphQL are case-insensitive (collation strength = 2)
    // but they are still diacritics-sensitive. So it's ok to display to user the accented variation and unaccented
    // variation of a string because they will produce 2 different search results.
    // More info on using .normalize to remove accents:
    // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
    // return string.trim().toLocaleLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return string.trim().toLocaleLowerCase();
  };

  /**
   * Split a string on spaces
   * @param string String to tokenize
   * @return {*} Tokens
   */
  static tokenize = (string) => {
    return StringUtils.nullToEmpty(string).split(" ").filter(x => x.length > 0);
  }

  /**
   * Escapes all special characters in a string so that it can be used in a RegExp.
   * @param string String to escape
   * @return {*} Escaped string
   */
  static escapeForRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  /**
   * Returns whether a string is a valid email.
   * @param string Email
   * @returns {boolean} True if string is a valid email
   */
  static isEmail(string) {
    return /[^@]+@[^@]+\.[^@]+/.test(string);
  }
}

export default StringUtils;
