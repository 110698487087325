import React from "react";
import ClientInlineEditName from "../mutate/ClientInlineEditName";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import ActionLink from "../../../common/widgets/ActionLink";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import CollapseIcon from "@mui/icons-material/ExpandLess";
import ClientDetails from "./ClientDetails";
import LastViewedJobs from "../../jobs/view/LastViewedJobs";
import useContentModal from "../../../common/modals/useContentModal";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import JobCreateForm from "../../jobs/mutate/JobCreateForm";
import JobSearchBox from "../../jobs/search/JobSearchBox";
import {useNavigate} from "react-router-dom";
import "./ClientBlock.scss";
import DetailsCollapsibleSection from "../../common/DetailsCollapsibleSection";
import ClientStatsBlock from "./ClientStatsBlock";
import ActionIcon from "../../../common/widgets/ActionIcon";
import BackIcon from "@mui/icons-material/KeyboardBackspace";

export default function ClientBlock(props) {

  const {client} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientBlock");
  // Modal to show job creation form
  const {ContentModal: JobCreateModal, show: showJobCreateModal, hide: hideJobCreateModal} = useContentModal();
  const [showDetails, setShowDetails] = React.useState(false);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(ROUTES.CLIENTS);
  }

  const onClickDetails = event => {
    event.preventDefault();
    setShowDetails(prevState => !prevState);
  }

  const onClickCreate = () => {
    showJobCreateModal(jobCreateForm);
  }

  const onJobCreated = (job) => {
    hideJobCreateModal();
    navigate(RouterUtils.injectParamsInRoute(ROUTES.JOB, {
      clientId: client._id,
      jobId: job._id
    }));
  };

  const jobCreateForm = <JobCreateForm clientId={client._id} onAbort={hideJobCreateModal} onSuccess={onJobCreated}/>;

  const DetailsIcon = showDetails ? CollapseIcon : ExpandIcon;

  return (
    <>
      {JobCreateModal}
      <div className="ClientBlock">
        <Row className={"title-row"}>
          <Col className={"back-button-container"}>
            <div className={"back-button"}>
              <ActionIcon onClick={goBack} icon={BackIcon}>{t("reporting:go_back_action")}</ActionIcon>
            </div>
            <ClientInlineEditName client={client} viewAs="h1"/>
          </Col>
          <Col className={"details-col"}>
            <ActionLink onClick={onClickDetails}>{t("reporting:clients_details_view_action")} <DetailsIcon/></ActionLink>
          </Col>
        </Row>
        <DetailsCollapsibleSection isOpened={showDetails}>
            <ClientDetails client={client}/>
        </DetailsCollapsibleSection>
        <Row className="search-and-stats-block">
          <Col>
            <Row className={"search-block-row"}>
              <Col>
                <Row >
                  <Col>
                    <JobSearchBox autofocus clientId={client._id}/>
                  </Col>
                </Row>
                <Row className={"create-link-row"}>
                  <Col>
                    <ActionLink onClick={onClickCreate}>{t("reporting:jobs_create_action")}</ActionLink>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="list-row">
              <Col>
                <LastViewedJobs clientId={client._id}/>
              </Col>
            </Row>
          </Col>
          <Col className={"stats-col"}>
            <ClientStatsBlock clientId={client._id}/>
          </Col>
        </Row>
      </div>
    </>
  );
};
