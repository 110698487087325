import {gql} from "@apollo/client";
import useDeferredQuery from "../../../common/data-fetching/useDeferredQuery";

export const QUERY_NAME = "clientRecipientListDownloadUrl";

export const QUERY = gql`
  query ${QUERY_NAME} {
    ${QUERY_NAME}
  } 
`;

export default function useLazyQueryClientRecipientListDownloadUrl() {

  return useDeferredQuery(QUERY, "", QUERY_NAME, {});
}
