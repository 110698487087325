import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_POOL_SEARCH} from "../search/useQueryTalentPools";

const QUERY_NAME = "talentPoolCreate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $name: String!,
    ) {
      ${QUERY_NAME} (
        name: $name,
      ) {
        _id
        name
      }
    }
  `;

export default function useMutationTalentPoolCreate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_POOL_SEARCH]
  });
}
