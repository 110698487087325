import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "clientUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $id: ID!,
      $name: String,
      $questionnaireId: ID
    ) {
      ${QUERY_NAME} (
        id: $id,
        name: $name,
        questionnaireId: $questionnaireId
      ) {
        _id
        name
        questionnaireId
      }
    }
  `;

export default function useMutationClientUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
