import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "reportLastViewed";

export const QUERY = gql`
  query ${QUERY_NAME}($count: Int, $jobId: ID) {
    ${QUERY_NAME}(count: $count, jobId: $jobId) {
      _id
      name
      jobId
      viewed
    }
  }
`;

export default function useQueryReportLastViewed(count, jobId) {

  const variables = {count, jobId};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network"
  });
}
