import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import QuestionDetails from "./QuestionDetails";

/**
 * Every question in a QuestionDetailsList. It is intended to be used as draggable items inside a react-movable List.
 */
export default React.forwardRef((props, ref) => {
  const {question, isDraggable, isDragged, questionnaireId, ...otherProps} = props;

  return (
    <Row ref={ref} {...otherProps} className={"QuestionDetailsListItem"}>
      <Col>
        <QuestionDetails question={question} isDraggable={isDraggable} isDragged={isDragged} questionnaireId={questionnaireId}/>
      </Col>
    </Row>
  );
});

