import React from "react";
import StringUtils from "../../../../utils/StringUtils";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import PoolJobInlineEditInputText from "./PoolJobInlineEditInputText";

export default function PoolJobInlineEditName(props) {

  const {poolJob, viewAs} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobInlineEditName");

  const validateName = React.useCallback(value => {
    if (StringUtils.isNullOrEmpty(value))
      return t("market:pool_jobs_details_title_required_error");
  }, [t]);

  return (
    <PoolJobInlineEditInputText
      poolJob={poolJob}
      placeholder={t("market:pool_jobs_details_title_placeholder")}
      property={"name"}
      validate={validateName}
      viewAs={viewAs}
      className="PoolJobInlineEditTitle"
    />
  );
};
