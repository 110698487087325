import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "jobCategorySearch";

export const QUERY = gql`
  query ${QUERY_NAME}($cursor: Int, $pageSize: Int, $filter: JobCategoryFilterInput, $sort: JobCategorySortEnum) {
    ${QUERY_NAME}(cursor: $cursor, pageSize: $pageSize, filter: $filter, sort: $sort) {
    nodes {
      _id
      name
      candidateCount
    }
    nextCursor
    hasNextPage
  }
  }
`;

export default function useQueryJobCategoriesByPoolJobId(poolJobId) {

  const variables = {filter: {poolJobId}};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network"
  });
}
