import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useQueryQuestionnaireNames from "../../questionnaires/search/useQueryQuestionnaireNames";
import TypeUtils from "../../../../utils/TypeUtils";

/**
 * Convenience hook that produces various props to be passed to an inline edit typeahead component for the selection
 * of a questionnaire.
 * @param currentQuestionnaire Client or job's questionnaire
 * @returns {{convertQuestionnaireIdToName: ((function(*=): (*))|*), formatValue: ((function(*=): (*))|*), options: *[], placeholder: *, loading: (*|boolean), labelKey: string, removeLabel: *, errors: (*|*[])}}
 */
export default function useQuestionnaireTypeaheadOptions(currentQuestionnaire) {

  const {t, loading: tLoading} = useTranslationReporting();
  const {data: questionnaireResults, loading: queryLoading, errors} = useQueryQuestionnaireNames();

  // Prepare the list of options for the questionnaire select box
  const questionnaireOptions = React.useMemo(() =>{
    const options = questionnaireResults ? [...questionnaireResults.nodes] : [];

    // In case the client has a questionnaire but it is not part of the available questionnaires (such as when it is deleted or
    // while loading the options), add it as an option so that the select box can be displayed with this option selected.
    if (currentQuestionnaire && !options.find(item => item._id === currentQuestionnaire._id)) {
      options.push({_id: currentQuestionnaire._id, name: currentQuestionnaire.name});
    }

    // Sort the options, first by name, then by ID
    options.sort((q1, q2) =>
      String(q1.name).localeCompare(String(q2.name)) || String(q1._id).localeCompare(String(q2._id)));

    return options;
  }, [questionnaireResults, currentQuestionnaire]);

  // Provide the select box with a function to display the questionnaire name when not in editing mode, given its ID
  const convertQuestionnaireIdToName = React.useCallback((id) => {

    if (!id)
      return t("reporting:questionnaires_select_empty");

    const questionnaire = TypeUtils.ensureArray(questionnaireOptions).find(item => item._id === id);
    if (questionnaire)
      return questionnaire.name;
    else {
      return t("reporting:questionnaires_select_no_name");
    }
  }, [questionnaireOptions, t]);

  return {
    loading: tLoading || queryLoading,
    errors,
    options: questionnaireOptions,
    convertQuestionnaireIdToName,
    labelKey: "name",
    placeholder: t("reporting:questionnaires_select_empty"),
    formatValue: convertQuestionnaireIdToName,
    removeLabel: t("reporting:questionnaires_select_remove_action")
  };
};
