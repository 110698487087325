import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import categoriesActions from "../actions";
import Page from "../../../common/page/Page";
import {useParams} from "react-router";
import useQueryJobCategoryById from "./useQueryJobCategoryById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import JobCategoryBlock from "./JobCategoryBlock";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

export default function JobCategoryPage(/*props*/) {

  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const jobCategoryId = urlParams.jobCategoryId;
  const talentPoolId = urlParams.talentPoolId;
  const {data: jobCategory, loading: categoryLoading, errors: categoryErrors} = useQueryJobCategoryById(jobCategoryId);
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "JobCategoryPage");

  return (
    <WithRedirectionUserIsAuthorized action={categoriesActions.JOB_CATEGORIES_VIEW} yes={() =>
      <WaitForData loading={categoryLoading} errors={categoryErrors} onLoaded={() =>
        <>
          {jobCategory &&
          <Page className="JobCategoryPage">
            <Page.Title loading={categoryLoading} text={jobCategory.name}/>
            <Page.Content className="spaced-children"><JobCategoryBlock jobCategory={jobCategory} talentPoolId={talentPoolId}/></Page.Content>
          </Page>
          }
          {!jobCategory &&
          <Page className="JobCategoryPage">
            <Page.Title loading={categoryLoading}>{t("market:job_categories_details_not_found")}</Page.Title>
          </Page>
          }
        </>
      }/>
    }/>
  );
}
