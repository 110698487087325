import React from "react";
import StringUtils from "../../../../utils/StringUtils";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import JobCategoryInlineEditInputText from "./JobCategoryInlineEditInputText";

export default function JobCategoryInlineEditName(props) {

  const {jobCategory, viewAs} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "JobCategoryInlineEditName");

  const validateName = React.useCallback(value => {
    if (StringUtils.isNullOrEmpty(value))
      return t("market:job_categories_details_title_required_error");
  }, [t]);

  return (
    <JobCategoryInlineEditInputText
      jobCategory={jobCategory}
      placeholder={t("market:job_categories_details_title_placeholder")}
      property={"name"}
      validate={validateName}
      viewAs={viewAs}
      className="JobCategoryInlineEditTitle"
    />
  );
};
