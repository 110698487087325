import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import JobCategoryInlineEditInput from "./JobCategoryInlineEditInput";

/**
 * Generic component for all pool job editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function JobCategoryInlineEditInputText(props) {

  return <JobCategoryInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
