import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import questionnairesActions from "../actions";
import Page from "../../../common/page/Page";
import {useParams} from "react-router";
import useQueryQuestionnaireById from "./useQueryQuestionnaireById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useMutationQuestionnaireViewUpdate from "../mutate/useMutationQuestionnaireViewUpdate";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import QuestionnaireBlock from "./QuestionnaireBlock";

export default function QuestionnairePage(/*props*/) {

  // Fetch questionnaire from ID provided in the url params.
  const urlParams = useParams();
  const questionnaireId = urlParams.questionnaireId;
  const {data: questionnaire, loading: questionnaireLoading, errors: questionnaireErrors} = useQueryQuestionnaireById(questionnaireId);
  // We'll touch the questionnaire view silently in the background, no need to track loading state or error
  const {mutate} = useMutationQuestionnaireViewUpdate();
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionnairePage");

  // Touch the questionnaire silently every time the page renders with a new questionnaireId or the user has performed an action to
  // change the questionnaire.
  React.useEffect(() => {
    if (questionnaire) {
      mutate({
        variables: {
          questionnaireId: questionnaire._id
        }
      }).catch(err => {/* fail silently */});
    }
  }, [questionnaire, mutate]);

  return (
    <WithRedirectionUserIsAuthorized action={questionnairesActions.QUESTIONNAIRES_VIEW} yes={() =>
      <WaitForData loading={questionnaireLoading} errors={questionnaireErrors} onLoaded={() =>
        <>
          {questionnaire &&
          <Page className="QuestionnairePage">
            <Page.Title loading={questionnaireLoading} text={questionnaire.name}/>
            <Page.Content className="spaced-children"><QuestionnaireBlock questionnaire={questionnaire}/></Page.Content>
          </Page>
          }
          {!questionnaire &&
          <Page className="QuestionnairePage">
            <Page.Title loading={questionnaireLoading}>{t("reporting:questionnaires_details_not_found")}</Page.Title>
          </Page>
          }
        </>
      }/>
    }/>
  );
}
