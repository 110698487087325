import React from "react";
import "./HeaderContainer.scss";

/**
 * Displays the sidebar on every page, but without its content because it depends on the translation function
 * (which might not be ready yet).
 *
 * Content is displayed using a SidebarPortal somewhere else under the main tree. The common parent of this
 * component and the SidebarPortal creates a reference
 * and stores it in a context so that it is available to the SidebarPortal. The reference is instantiated here on the
 * target container div. We have to use React.forwardRef when the reference is created in a component but instantiated
 * in a child.
 *
 * The component has to reserve the same space on the page, whether its contents is ready or not, so that
 * the main contents (including the loading icon) does not move when the sidebar contents is ready.
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const SidebarContainer = React.forwardRef((props, ref) => (

  // RENDER

  <div className="SidebarContainer">
    <div ref={ref}/>
  </div>
));

export default SidebarContainer;

