// Try to keep declarations here and in App.js in the same order, to improve maintainability

export const SIGN_OUT = "/signout";
export const SIGN_IN = "/signin";

export const HOME = "/";

// Sidebar uses url from left to right to determine highlighted menu items.
// reporting
export const REPORTS = "/reporting";
export const CLIENTS = "/reporting/client";
export const CLIENT = "/reporting/client/:clientId";
export const JOB = "/reporting/client/:clientId/job/:jobId";
export const REPORT = "/reporting/client/:clientId/job/:jobId/report/:reportId";
// questionnaires
export const QUESTIONNAIRES = "/questionnaires";
export const QUESTIONNAIRES_ALL = "/questionnaires/all";
export const QUESTIONNAIRE = "/questionnaires/questionnaire/:questionnaireId";
// settings
export const SETTINGS = "/settings";
export const REPORTING_SETTINGS = "/settings/reporting";
// Report URL on the public server
export const PUBLIC_REPORT = "/reporting/report/:reportId";

// talent market
export const MARKET = "/market";
export const POOLS = "/market/pool"
export const POOL = "/market/pool/:talentPoolId"
export const POOL_JOB = "/market/pool/:talentPoolId/job/:poolJobId";
export const JOB_CATEGORY = "/market/pool/:talentPoolId/job/:poolJobId/category/:jobCategoryId";

// Report URL on the public server
export const PUBLIC_TALENT_POOL = "/market/pool/:talentPoolId";

export const HELP = "/help";

//export const PASSWORD_CHANGE = "/pw-change";
export const RESET_PASSWORD = "/pw-forget";

// error page for tests
export const ERROR_TEST = "/error-test";

export const NOT_FOUND = "/not-found";
