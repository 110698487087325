import React from "react";
import {Editor} from "react-draft-wysiwyg";

export default function WysiwygEditor(props) {

  const {setEditorRef, value, onChange, placeholder, languageCode} = props;

  return (
    <Editor
      editorRef={setEditorRef} /* Get a ref to the editor so that we can focus on it */
      editorState={value}
      onEditorStateChange={onChange}
      placeholder={placeholder}
      toolbar={{
        options: ['inline', 'fontSize', 'list', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
        inline: {
          options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
        }
      }}
      localization={{
        locale: languageCode
      }}
    />

  );
};
