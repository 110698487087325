import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import * as ROUTES from "../../../../constants/routes";
import {useNavigate} from "react-router-dom";
import ReportDeleteButton from "../mutate/ReportDeleteButton";
import RouterUtils from "../../../../utils/RouterUtils";
import useQueryJobById from "../../jobs/view/useQueryJobById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ActionLink from "../../../common/widgets/ActionLink";
import EmailCreateForm from "../../emails/mutate/EmailCreateForm";
import useContentModal from "../../../common/modals/useContentModal";
import "./ReportDetails.scss";
import ReportPublicLink from "./ReportPublicLink";
import useErrorModal from "../../../common/modals/useErrorModal";
import StringUtils from "../../../../utils/StringUtils";
import ReportResetViewedButton from "../mutate/ReportResetViewedButton";
import useQuestionnaireTypeaheadOptions from "../../clients/view/useQuestionnaireTypeaheadOptions";
import ReportInlineEditInputTypeahead from "../mutate/ReportInlineEditInputTypeahead";

export default function ReportDetails(props) {

  const {report, jobId} = props;
  const navigate = useNavigate();
  const {data: job, loading: jobLoading, errors: jobErrors} = useQueryJobById(jobId);
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportDetails");

  const validationErrorMsg = !report.published ?
    t("reporting:reports_send_email_unpublished_error") : null;

  const {ErrorModal: ValidationErrorModal, show: showValidationErrorModal} = useErrorModal(<p>{validationErrorMsg}</p>);

  // Prepare the options for the questionnaire select box
  const {loading, errors, ...typeaheadProps} = useQuestionnaireTypeaheadOptions(report.questionnaire);

  const onDeleteCompleted = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.JOB, {
      clientId: job.clientId,
      jobId: report.jobId
    }));
  }
  const {ContentModal: EmailCreateModal, show: showEmailCreateModal, hide: hideEmailCreateModal} = useContentModal("EmailCreateModal");

  const onEmailCreated = () => {
    hideEmailCreateModal();
  };

  const emailCreateForm = <EmailCreateForm report={report} job={job} onAbort={hideEmailCreateModal} onSuccess={onEmailCreated}/>;

  const onClickSendEmail = () => {
    if (!StringUtils.isNullOrEmpty(validationErrorMsg)) {
      showValidationErrorModal()
    } else {
      showEmailCreateModal(emailCreateForm)
    }
  };

  return (
    <>
      {EmailCreateModal} {ValidationErrorModal}
      <div className="ReportDetails">
        <Row>
          <Col>
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col className={"section"}>
            <Row>
              <Col className={"label"}>
                {t("reporting:reports_details_questionnaire_title")}
              </Col>
            </Row>
            <Row>
              <Col>
                <WaitForData loading={loading} errors={errors} onLoaded={() =>
                  <ReportInlineEditInputTypeahead
                    report={report}
                    property={"questionnaireId"}
                    {...typeaheadProps}
                  />
                }/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={"section"}>
            <Row>
              <Col className={"label"}>
                {t("reporting:reports_details_url_title")}
              </Col>
            </Row>
            <Row>
              <Col>
                <ReportPublicLink report={report}/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={"section"}>
            <Row>
              <Col className={"label"}>
                {t("reporting:details_other_actions_title")}
              </Col>
            </Row>
            <Row>
              <Col>
                <WaitForData loading={jobLoading} errors={jobErrors} onLoaded={() =>
                  <ActionLink onClick={onClickSendEmail}>{t("reporting:reports_send_email_action")}</ActionLink>
                }/>
              </Col>
            </Row>
            <Row>
              <Col>
                <WaitForData loading={jobLoading} errors={jobErrors} onLoaded={() =>
                  <ReportResetViewedButton reportId={report._id}/>
                }/>
              </Col>
            </Row>
            <Row>
              <Col>
                <WaitForData loading={jobLoading} errors={jobErrors} onLoaded={() =>
                  <ReportDeleteButton reportId={report._id} onCompleted={onDeleteCompleted}/>
                }/>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr/>
          </Col>
        </Row>
      </div>
    </>
  );
};
