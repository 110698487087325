import React from "react";
import ReportInlineEditName from "../mutate/ReportInlineEditName";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import ActionLink from "../../../common/widgets/ActionLink";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import CollapseIcon from "@mui/icons-material/ExpandLess";
import ReportDetails from "./ReportDetails";
import ReportInlineEditDate from "../mutate/ReportInlineEditDate";
import ReportInlineEditInputBoolean from "../mutate/ReportInlineEditInputBoolean";
import CandidateList from "../../candidates/view/CandidateList";
import "./ReportBlock.scss";
import DetailsCollapsibleSection from "../../common/DetailsCollapsibleSection";
import ReportInlineEditInputWysiwyg from "../mutate/ReportInlineEditInputWysiwyg";
import ViewedBadge from "../../../common/widgets/ViewedBadge";
import {useNavigate} from "react-router-dom";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import ActionIcon from "../../../common/widgets/ActionIcon";
import BackIcon from "@mui/icons-material/KeyboardBackspace";

export default function ReportBlock(props) {

  const {report, clientId, jobId} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportBlock");
  const [showDetails, setShowDetails] = React.useState(false);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.JOB, {clientId, jobId}));
  }

  const onClickDetails = event => {
    event.preventDefault();
    setShowDetails(prevState => !prevState);
  };

  const DetailsIcon = showDetails ? CollapseIcon : ExpandIcon;

  return (
    <>
      <div className="ReportBlock">
        <Row className={"title-row"}>
          <Col className={"back-button-container"}>
            <div className={"back-button"}>
              <ActionIcon onClick={goBack} icon={BackIcon}>{t("reporting:go_back_action")}</ActionIcon>
            </div>
            <ReportInlineEditName report={report} viewAs="h1"/>
          </Col>
          <Col className={"viewed-col"}>
            <ViewedBadge show={report.viewed}/>
          </Col>
          <Col className={"published-col"}>
            <ReportInlineEditInputBoolean report={report} property={"published"} placeholder={t("reporting:reports_details_published_placeholder")}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReportInlineEditDate report={report}/>
          </Col>
          <Col className={"details-col"}>
            <ActionLink onClick={onClickDetails}>{t("reporting:reports_details_view_action")} <DetailsIcon/></ActionLink>
          </Col>
        </Row>
        <DetailsCollapsibleSection isOpened={showDetails}>
          <ReportDetails report={report} jobId={jobId}/>
        </DetailsCollapsibleSection>
        <Row>
          <Col>
            <ReportInlineEditInputWysiwyg className={"note-input"} report={report} placeholder={t("reporting:reports_details_note_placeholder")} property={"note"}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <CandidateList reportId={report._id}/>
          </Col>
        </Row>
      </div>
    </>
  );
};
