import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_REPORT_LAST_VIEWED} from "../view/useQueryReportLastViewed";

const QUERY_NAME = "reportDelete";

export const QUERY = gql`
    mutation ${QUERY_NAME} ($id: ID!) {
      ${QUERY_NAME} (id: $id) {
        _id
      }
    }
  `;

export default function useMutationReportDelete() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_REPORT_LAST_VIEWED]
  });
}
