import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useErrorModal from "../../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import StringUtils from "../../../../utils/StringUtils";
import useMutationJobUpdate from "./useMutationJobUpdate";
import TypeUtils from "../../../../utils/TypeUtils";

/**
 * Generic component for all job editable fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function JobInlineEditInput(props) {

  const {InlineEditInputComponent, job, viewAs, validate, placeholder, property, className, index, openEditing, ViewComponent, ...otherProps} = props;
  const {mutate, loading, errors} = useMutationJobUpdate();
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "JobInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  // Get the current value for the property. If an index is provided, it means the property refers to an array of values
  const currentValue = index == null ? job[property] : job[property][index];

  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("reporting:jobs_details_update_error")}</p>
      {errorMsg}
    </>
  );

  const onSubmit = (value) => {
    const variables = {};
    variables["id"] = job._id;

    // If an index is provided, the property is an array of values and we are modifying only one item
    if (index != null) {
      // First make a copy of the array before modifying it
      variables[property] = TypeUtils.shallowCopyArray(job[property]);
      variables[property][index] = value;

      // Remove all empty values
      variables[property] = variables[property].filter(item => !StringUtils.isNullOrEmpty(item));
    } else {
      // The property is a single value, replace it
      variables[property] = value;
    }

    return mutate({variables})
      .catch(error => {
        showErrorModal();
        // rethrow error so that InlineEdit component acts on it
        throw new Error(error);
      });
  }

  return (
    <>
      {ErrorModal}
      <div className={["JobInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          placeholder={placeholder}
          value={StringUtils.nullToEmpty(currentValue)}
          onSubmit={onSubmit}
          validate={validate}
          viewAs={viewAs}
          loading={loading}
          openEditing={openEditing}
          ViewComponent={ViewComponent}
          {...otherProps}
        />
      </div>
    </>
  );
};
