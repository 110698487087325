import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "questionnaireById";

// Query only the question count when needed (not with all the other properties) because it is time-consuming
export const QUERY = gql`
  query ${QUERY_NAME}($id: ID!) {
    ${QUERY_NAME}(id: $id) {
      _id
      questionCount
    }
  }
`;

export default function useQueryQuestionnaireQuestionCount(id) {

  const variables = {id};

  const {data, ...otherProps} = useWrappedQuery(QUERY, "", QUERY_NAME, {variables});

  return {
    data: data ? data.questionCount : undefined,
    ...otherProps
  }
}
