import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import ClientInlineEditInput from "./ClientInlineEditInput";

/**
 * Generic component for all client editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ClientInlineEditInputText(props) {

  return <ClientInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
