import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_CANDIDATE_SEARCH} from "../view/useQueryPoolCandidatesByJobCategoryId";

const QUERY_NAME = "poolCandidateCreate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $name: String!,
      $jobCategoryId: ID
    ) {
      ${QUERY_NAME} (
        name: $name,
        jobCategoryId: $jobCategoryId
      ) {
        _id
        name
        jobCategoryIds
      }
    }
  `;

export default function useMutationPoolCandidateCreate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_CANDIDATE_SEARCH]
  });
}
