import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useQueryClientNames from "./useQueryClientNames";
import {useNavigate} from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import RouterUtils from "../../../../utils/RouterUtils";
import SearchBox from "../../common/SearchBox";

export default function ClientSearchBox() {

  const {data: results, loading: clientsLoading, errors: clientsErrors} = useQueryClientNames();

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientSearchBox");
  const navigate = useNavigate();

  const useQueryOptions = () => {
    return {
      options: results ? results.nodes : [],
      loading: clientsLoading,
      errors: clientsErrors
    }
  }

  const onSelectOption = (option) => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.CLIENT, {clientId: option._id}));
  }

  const errorMsg = t("reporting:clients_search_filter_error");
  const emptyMsg =  t("reporting:clients_search_filter_empty");
  const placeholder = t("reporting:clients_search_filter_placeholder");

  return (
    <SearchBox
      className = {"ClientSearchBox"}
      useQueryOptions = {useQueryOptions}
      onSelectOption = {onSelectOption}
      errorMsg = {errorMsg}
      emptyMsg = {emptyMsg}
      placeholder = {placeholder}
      labelKey = {"name"}
    />
  );
}
