import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import "./QuestionnaireStats.scss";

export default function QuestionnaireStats(props) {

  const {answers} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading);

  const questionCount = answers ? answers.length : 0;
  const answerCount = answers ? answers.filter(answer => answer.answer !== null).length : 0;
  const isCompleted = answerCount === questionCount;

  return (
    <div className="QuestionnaireStats">
      <span className={"label"}>{t("reporting:candidates_preview_completion_label")}</span>
      <span className={"answer-count " + (isCompleted ? "complete" : "incomplete")}>{answerCount}</span>/<span className={"question-count"}>{questionCount}</span>
    </div>
  );
};
