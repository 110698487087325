import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "candidateSearch";

export const QUERY = gql`
  query ${QUERY_NAME}($cursor: Int, $pageSize: Int, $filter: CandidateFilterInput, $sort: CandidateSortEnum) {
    ${QUERY_NAME}(cursor: $cursor, pageSize: $pageSize, filter: $filter, sort: $sort) {
    nodes {
      _id
      name
      note
      viewed
      answers {
        answer {
          _id
        }
      }
    }
    nextCursor
    hasNextPage
  }
  }
`;

export default function useQueryCandidatesByReportId(reportId) {

  const variables = {filter: {reportId}};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network"
  });
}
