import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "clientSearchCount";

export const QUERY = gql`
  query ${QUERY_NAME} {
    ${QUERY_NAME} 
  }
`;

export default function useQueryClientCount() {

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    // In case we add a client, first return outdated cache and then replace with actual list
    fetchPolicy: "cache-and-network"
  });
}
