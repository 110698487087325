import React from "react";
import Page from "../common/page/Page";
import HtmlUtils from "../../utils/HtmlUtils";
import useTranslationHelpPage from "./useTranslationHelpPage";

function HelpPage(props) {

  const {t, loading: tLoading} = useTranslationHelpPage();

  // Translation contains markup, convert it to React html elements
  const pageContent = HtmlUtils.htmlToComponents(t("help-page:help_content"));

  return (
    <Page className="HelpPage">
      <Page.Title loading={tLoading}>{t("help-page:help_title")}</Page.Title>
      <Page.Content className="spaced-children">
        {pageContent}
      </Page.Content>
    </Page>
  );
}

export default HelpPage;
