import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useQueryJobTitles from "./useQueryJobTitles";
import {useNavigate} from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";
import RouterUtils from "../../../../utils/RouterUtils";
import SearchBox from "../../common/SearchBox";

export default function JobSearchBox(props) {

  const {clientId} = props;

  const {data: results, loading: clientsLoading, errors: clientsErrors} =  useQueryJobTitles(clientId);

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "JobSearchBox");
  const navigate = useNavigate();

  const useQueryOptions = () => {
    return {
      options: results ? results.nodes : [],
      loading: clientsLoading,
      errors: clientsErrors
    }
  }

  const onSelectOption = (option) => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.JOB, {
      clientId: clientId,
      jobId: option._id
    }));
  }

  const errorMsg = t("reporting:jobs_search_filter_error");
  const emptyMsg =  t("reporting:jobs_search_filter_empty");
  const placeholder = t("reporting:jobs_search_filter_placeholder");

  return (
    <SearchBox
      className = {"JobSearchBox"}
      useQueryOptions = {useQueryOptions}
      onSelectOption = {onSelectOption}
      errorMsg = {errorMsg}
      emptyMsg = {emptyMsg}
      placeholder = {placeholder}
      labelKey = {"title"}
    />
  );
};
