import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../img/logo-exotalent.svg";
import * as ROUTES from "../../../constants/routes";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import "./HeaderContainer.scss";
import Container from "react-bootstrap/Container";

/**
 * Displays the header of every page: a Navbar with the site logo, but no menu items.
 *
 * This header needs to be displayable even if some helpers from the main tree fail, such as Firebase authentication
 * and Apollo initialization. Because user authentication is further down another branch of the React tree, the menu
 * items (which need the state of the authenticated user to display properly) are displayed using a MenuPortal somewhere
 * else under the authentication component. The common parent of this component and the MenuPortal creates a reference
 * and stores it in a context so that it is available to the MenuPortal. The reference is instantiated here on the
 * target container div. We have to use React.forwardRef when the reference is created in a component but instantiated
 * in a child.
 *
 * The component has to reserve the same space on the page, whether its contents is ready or not, so that
 * the main contents (including the loading icon) does not move when the header contents is ready.
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const HeaderContainer = React.forwardRef((props, ref) => (

  // RENDER

  <div className="HeaderContainer">
    <Navbar expand="sm">
      <Container className={"navbar-container"}/*Remove Container to spread menu items over all screen width*/>
        <Navbar.Brand>
          <Link to={ROUTES.HOME}>
            <Image src={logo} className="logo"/>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-navbar-nav"/>
        {/* Menu will be filled here with a portal on ref */}
        <Navbar.Collapse id="main-navbar-nav" ref={ref}/>
      </Container>
    </Navbar>
  </div>
));

export default HeaderContainer;

