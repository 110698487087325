import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import JobInlineEditInput from "./JobInlineEditInput";

/**
 * Generic component for all job editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function JobInlineEditInputText(props) {

  return <JobInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
