import React from "react";
import InlineEditWysiwyg from "../../../common/widgets/InlineEditWysiwyg";
import CandidateInlineEditInput from "./CandidateInlineEditInput";

/**
 * Generic component for all candidate editable wysiwyg fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CandidateInlineEditInputWysiwyg(props) {
  return <CandidateInlineEditInput InlineEditInputComponent={InlineEditWysiwyg} {...props}/>
};
