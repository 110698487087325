import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "candidateSearchCount";

export const QUERY = gql`
  query ${QUERY_NAME}($filter: CandidateFilterInput) {
    ${QUERY_NAME}(filter: $filter)
  }
`;

export default function useQueryCandidateSearchCount(filter) {

  const variables = {
      filter
    };

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
      variables,
      // Get info from cache and ask the network at the same time, then update on response
      fetchPolicy: "cache-and-network"
  });
}
