import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "talentPoolSearch";

export const QUERY = gql`
  query ${QUERY_NAME} {
    ${QUERY_NAME} {
      nodes {
        _id
        name
        published
        jobCount
      }
    }
  }
`;

export default function useQueryTalentPools() {

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    // In case we add a client, first return outdated cache and then replace with actual list
    fetchPolicy: "cache-and-network"
  });
}
