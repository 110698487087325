import React from "react";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import "./TalentPoolBlock.scss";
import useTranslationMarket from "../../useTranslationMarket";
import TalentPoolInlineEditInputBoolean from "../mutate/TalentPoolInlineEditInputBoolean";
import TalentPoolPublicLink from "./TalentPoolPublicLink";
import PoolJobList from "../../jobs/view/PoolJobList";
import TalentPoolInlineEditName from "../mutate/TalentPoolInlineEditName";
import ActionIcon from "../../../common/widgets/ActionIcon";
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import {useNavigate} from "react-router-dom";
import * as ROUTES from "../../../../constants/routes";

export default function TalentPoolBlock(props) {

  const {talentPool} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolBlock");

  const navigate = useNavigate();

  const goBack = () => {
    navigate(ROUTES.POOLS);
  }

  return (
    <div className="TalentPoolBlock">
      <Row>
        <Col className={"talent-pool-details"}>
          <Row className={"title-row"}>
            <Col className={"back-button-container"}>
              <div className={"back-button"}>
                <ActionIcon onClick={goBack} icon={BackIcon}>{t("reporting:go_back_action")}</ActionIcon>
              </div>
              <TalentPoolInlineEditName talentPool={talentPool} viewAs="h1"/>
            </Col>
            <Col className={"published-col"}>
              <TalentPoolInlineEditInputBoolean talentPool={talentPool} property={"published"} placeholder={t("market:talent_pools_details_published_placeholder")}/>
            </Col>
          </Row>
          <Row>
            <Col className={"section"}>
              <Row>
                <Col className={"label"}>
                  {t("market:talent_pools_details_url_title")}
                </Col>
              </Row>
              <Row>
                <Col>
                  <TalentPoolPublicLink talentPool={talentPool}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={"jobs-list"}>
          <Row>
            <Col>
              <h2>{t("market:talent_pools_jobs_title")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <PoolJobList talentPoolId={talentPool._id}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
