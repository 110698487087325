import React from "react";
import InlineEditInputTextArea from "../../../common/widgets/InlineEditInputTextArea";
import AnswerInlineEditInput from "./AnswerInlineEditInput";

/**
 * Generic component for all answer editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function AnswerInlineEditInputTextArea(props) {

  return <AnswerInlineEditInput InlineEditInputComponent={InlineEditInputTextArea} {...props}/>
};
