import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ReportInlineEditInputDate from "./ReportInlineEditInputDate";

export default function ReportInlineEditDate(props) {

  const {report, viewAs} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportInlineEditDate");

  const validateDate = React.useCallback(value => {
    if (value && !Date.parse(value))
      return t("reporting:reports_details_date_invalid_error");
  }, [t]);

  return (
    <ReportInlineEditInputDate
      report={report}
      placeholder={t("reporting:reports_details_date_placeholder")}
      property={"date"}
      validate={validateDate}
      view={viewAs}
      className="ReportInlineEditDate"
    />
  );
}
