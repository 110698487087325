import React from "react";
import InlineEditInputTypeahead from "../../../common/widgets/InlineEditInputTypeahead";
import ReportInlineEditInput from "./ReportInlineEditInput";

/**
 * Generic component for all Report editable select fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportInlineEditInputTypeahead(props) {

  return <ReportInlineEditInput InlineEditInputComponent={InlineEditInputTypeahead} {...props}/>
};
