import Dropdown from "react-bootstrap/Dropdown";
import TypeUtils from "../../utils/TypeUtils";
import {useTranslation} from "react-i18next";
import useLanguages from "../infra-no-ui/translation/useLanguages";
import React from "react";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import ActionDiv from "../common/widgets/ActionDiv";
import "./MenuComponents.scss";
import {NavLink} from "react-router-dom";
import Nav from "react-bootstrap/Nav";

function MenuItem(props) {

  const {to, children, ...otherProps} = props;
  const [text, setText] = React.useState();

  const item = <ActionDiv className="menu-item-link">{children}</ActionDiv>;
  const decoratedChildren = to ? <Nav.Link className={"menu-item-nav"} as={NavLink} href={to} to={to} end>{item}</Nav.Link> : item

  // Get the text content because we need to pass it to an html element as a "title" attribute
  // in order to create a hidden CSS component with same text but already in bold, so that
  // other menu items do not move when the current item is hovered.
  const itemRef = (node) => {
    if (node !== null) {
      setText(node.textContent);
    }
  };


  return (
    <div className="MenuItem" {...otherProps}>
      <div ref={itemRef} title={text} className={"same-width-on-hover"}>{decoratedChildren}</div>
    </div>

  );
}

function MenuDropdownItem(props) {

  // Wrap an item in a dropdown with Dropdown.Item so that the dropdown closes on click
  return (
    <Dropdown.Item>
      <MenuItem {...props}/>
    </Dropdown.Item>
  )
}

function MenuDropdown(props) {

  const {title, items} = props;

  const itemArray = TypeUtils.ensureArray(items);

  if (itemArray.length === 0)
    return null;

  return (
    <Dropdown className="MenuDropdown">
      <Dropdown.Toggle as="div"><ActionDiv className="menu-item-link">{title}</ActionDiv></Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        {itemArray}
      </Dropdown.Menu>
    </Dropdown>
  )
}

function LanguageMenuItem() {

  const onClickLanguage = (event, languageCode) => {
    event.preventDefault();

    // Mark the whole page as loading
    setLoading(true);

    // Tell i18next to fetch the files for the new language
    // Callback is called when other language has been fetched, then it resolves the promise (probably because the whole
    // promise is intended to be thrown and caught by the new Suspense component, but we don't use it yet because
    // using Suspense for loading state is experimental).
    i18n.changeLanguage(languageCode, (/*err*/) => {
      // Errors must be caught in the callback, they are not propagated to the promise catch clause
      // But do nothing if an error occurs (such as a missing translation namespace file), let i18next use fallbacks
    }).then(() => {
      // Whether an error occurred or not, stop the suspense that prevents the page from being rendered
      setLoading(false);
    });
  }

  /**
   * Returns the underlying data structure for the language menu. It returns an object with the following properties:
   *  "title": the language object that serves as the title of the language menu
   *  "items": an array of language objects that are the menu subitems
   *
   *  A language object has the following properties:
   *  "name": the 2-letter name of the language, viewable by the user, not to be translated (eg. FR)
   *  "code": the code of the language, used by i18n to retrieve translations (eg. fr)
   *
   *  If there are no other languages than the current language, returns null.
   *  If there is only one other language, returns it as the menu title.
   *  If there are many other languages, returns the current language as the title and all other available languages
   *  as subitems.
   *
   *  @return Underlying data structure as describe above
   */
  const getLanguageMenuStructure = () => {
    // Find current language in the list of defined languages (LANGUAGES).
    // For each language of the resolving chain used by i18n, check if it is in the list of LANGUAGES. Stop as soon as one is
    // found, it means it is the more specific. LANGUAGES[0] will always be somewhere in the resolving chain
    // because it is the fallback language, but it might not be the more specific.
    const currentLanguageCode = getCurrentLanguageCode();
    const currentLanguageName = getLanguageName(currentLanguageCode);

    // Alternate languages to select from
    const otherLanguages = getOtherLanguages();

    // If there is no other language, do not display menu item
    if (otherLanguages.length === 0)
      return null;

    // If there is only one alternative, display it as the menu item title (clickable), without menu subitems
    if (otherLanguages.length === 1)
      return ({
        "title": otherLanguages[0],
        "items": []
      });

    // If there are many alternatives, display the current language as the menu item title, then alternatives as subitems
    return ({
      "title": {
        "code": currentLanguageCode,
        "name": currentLanguageName
      },
      "items": otherLanguages
    })
  }

  // We don't call useTranslationMenu because it is not required here to wait for the translations to be loaded.
  // If t() is needed, call useTranslationMenu and watch for the loading property.
  const {i18n} = useTranslation();
  const {getCurrentLanguageCode, getLanguageName, getOtherLanguages} = useLanguages();
  const [loading, setLoading] = React.useState(false);
  useShowLoader(loading, "MenuComponents");

  const languageMenuStructure = getLanguageMenuStructure();

  if (!languageMenuStructure)
    return null;

  // No subitems to display in language menu, only a click to switch to the other language
  if (languageMenuStructure.items.length === 0)
    return <MenuItem onClick={(event) => onClickLanguage(event, languageMenuStructure.title.code)}>{languageMenuStructure.title.name}</MenuItem>

  // Subitems to display in language menu, display current language as dropdown title and then other languages
  const languageMenuItems = languageMenuStructure.items.map(language =>
    <MenuDropdownItem key={language.code} onClick={(event) => onClickLanguage(event, language.code)}>{language.name}</MenuDropdownItem>
  );

  return <MenuDropdown title={languageMenuStructure.title.name} items={languageMenuItems}/>
}

export {MenuItem, MenuDropdownItem, MenuDropdown, LanguageMenuItem};
