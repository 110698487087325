import React from "react";
import useLazyQueryCandidateResumeDownloadUrl from "./useLazyQueryCandidateResumeDownloadUrl";
import ResumeDownloadLink from "../../../common/resume-upload/ResumeDownloadLink";

export default function CandidateResumeDownloadLink(props) {

  const {candidateId, disabled, children} = props;
  const {fetch} = useLazyQueryCandidateResumeDownloadUrl();

  return (<ResumeDownloadLink candidateId={candidateId} disabled={disabled} onFetch={fetch} children={children}/>);
}
