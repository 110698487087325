import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";

/**
 * Return the public URL of the talent pool report, accessible to clients (no login required)
 * @param talentPoolId
 * @returns {string}
 */
export default function usePublicTalentPoolUrl(talentPoolId) {
  return process.env.REACT_APP_PUBLIC_REPORT_URI + RouterUtils.injectParamsInRoute(ROUTES.PUBLIC_TALENT_POOL, {talentPoolId});
};
