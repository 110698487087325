import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useMutationReportResetViewed from "./useMutationReportResetViewed";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import ActionLink from "../../../common/widgets/ActionLink";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";

export default function ReportResetViewedButton(props) {

  const {reportId, onCompleted} = props;

  const resetReport = () => {
    return mutate({
      variables: {
        id: reportId,
      },
    });
  }

  const onClickReset = (event) => {
    event.preventDefault();
    launch(resetReport, onCompleted);
  }

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportResetViewedButton");

  const {mutate, loading: resetLoading, errors: resetErrors} = useMutationReportResetViewed();

  const successMsg = <p>{t("reporting:reports_reset_success_msg")}</p>;
  const errorMsg = useServerErrorFormatter(resetErrors);
  const confirmMsg = <p>{t("reporting:reports_reset_confirm_msg")}</p>
  const {launch, FeedbackModal} = useFeedbackModal({successMsg, errorMsg, confirmMsg});

  return (
    <>
      {FeedbackModal}
      <ActionLink onClick={onClickReset} loading={resetLoading}>{t("reporting:reports_details_reset_action")}</ActionLink>
    </>
  );
}
