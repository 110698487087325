import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import TypeUtils from "../../../../utils/TypeUtils";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryCandidatesByReportId from "./useQueryCandidatesByReportId";
import useContentModal from "../../../common/modals/useContentModal";
import CandidateCreateForm from "../mutate/CandidateCreateForm";
import CandidateUpdateForm from "../mutate/CandidateUpdateForm";
import CandidateListItem from "./CandidateListItem";
import CandidatePreview from "./CandidatePreview";
import CandidateAdd from "./CandidateAdd";
import "./CandidateList.scss";

export default function CandidateList(props) {

  const {reportId} = props;

  // Modal to show candidate creation form
  const {ContentModal: CandidateCreateModal, show: showCandidateCreateModal, hide: hideCandidateCreateModal} = useContentModal();
  // Modal to show candidate details form
  const {ContentModal: CandidateUpdateModal, show: showCandidateUpdateModal, hide: hideCandidateUpdateModal} = useContentModal("CandidateUpdateModal");

  const {data: candidates, loading: candidatesLoading, errors: candidatesErrors} = useQueryCandidatesByReportId(reportId);
  // Remove null candidates. This can happen after a deletion, before the list is updated
  const nonNullCandidates = candidates ? TypeUtils.ensureArray(candidates.nodes).filter(node => node) : [];

  const onClickCandidate = (candidateId) => {
    showCandidateUpdateModal(getCandidateUpdateForm(candidateId))
  }

  const onClickAddCandidate = () => {
    const candidateCreateForm = <CandidateCreateForm onAbort={hideCandidateCreateModal} onSuccess={onCandidateCreated} reportId={reportId}/>;
    showCandidateCreateModal(candidateCreateForm);
  }

  const onCandidateCreated = (candidate) => {
    hideCandidateCreateModal();
    showCandidateUpdateModal(getCandidateUpdateForm(candidate._id));
  }

  const getCandidateUpdateForm = (candidateId) => {
    return <CandidateUpdateForm onClose={hideCandidateUpdateModal} candidateId={candidateId}/>
  }

  return (
    <>
      {CandidateCreateModal} {CandidateUpdateModal}
      <div className="CandidateList">
        <WaitForData loading={candidatesLoading} errors={candidatesErrors} onLoaded={() =>
          <Row className={"candidates-row"}>
            {nonNullCandidates.map(candidate =>
              <Col key={candidate._id} className={"candidate-col"}>
                <CandidateListItem>
                  <CandidatePreview candidate={candidate} onClick={() => onClickCandidate(candidate._id)}/>
                </CandidateListItem>
              </Col>
            )}
            <Col className={"candidate-col"}>
              <CandidateListItem>
                <CandidateAdd onClick={onClickAddCandidate}/>
              </CandidateListItem>
            </Col>
          </Row>
        }/>
      </div>
    </>
  );
};
