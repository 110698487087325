import React from "react";
import useMutationProfileReportingSettingsUpdate from "./useMutationProfileReportingSettingsUpdate";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useErrorModal from "../../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import StringUtils from "../../../../utils/StringUtils";

/**
 * Generic component for all report settings editable fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportingSettingsInlineEditInput(props) {

  const {InlineEditInputComponent, profileId, settings, viewAs, validate, placeholder, property, className, ...otherProps} = props;
  const {mutate, loading, errors} = useMutationProfileReportingSettingsUpdate();
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportingSettingsInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("reporting:settings_update_error")}</p>
      {errorMsg}
    </>
  );

  const onSubmit = (value) => {
    const variables = {};
    variables["profileId"] = profileId;
    variables[property] = value;

    return mutate({variables})
      .catch(error => {
        showErrorModal();
        // rethrow error so that InlineEdit component acts on it
        throw new Error(error);
      });
  }

  return (
    <>
      {ErrorModal}
      <div className={["ReportingSettingsInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          placeholder={placeholder}
          value={StringUtils.nullToEmpty(settings[property])}
          onSubmit={onSubmit}
          validate={validate}
          viewAs={viewAs}
          loading={loading}
          {...otherProps}
        />
      </div>
    </>
  );
}
