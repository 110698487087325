import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import TypeUtils from "../../../utils/TypeUtils";
import WaitForData from "../../common/data-fetching/WaitForData";
import "./LastViewedList.scss";

export default function LastViewedList(props) {

  const {items, loading, errors, className, listTitle, emptyMsg, getItemId, SearchResult} = props;

  // Remove null items. This can happen after a deletion, before the list of last viewed is updated
  const nonNullItems = TypeUtils.ensureArray(items).filter(item => !!item);

  if (TypeUtils.arrayIsEmpty(nonNullItems))
    return null;

  return (
    <div className = {["LastViewedList", className].join(" ")}>
      <Row>
        <Col className={"title-col"}>
          <h2>{listTitle}</h2>
        </Col>
      </Row>
      <Row>
        <Col className="list-items">
          <WaitForData loading={loading} errors={errors} onLoaded={() =>
            <>
              {TypeUtils.arrayIsEmpty(nonNullItems) && <span className={"empty-message"}>{emptyMsg}</span>}
              {!TypeUtils.arrayIsEmpty(nonNullItems) && nonNullItems.map(item =>
                <React.Fragment key={getItemId(item)}>
                  <Row className={"list-item"}>
                    <Col>
                      {React.cloneElement(SearchResult, {item})}
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </>
          }/>
        </Col>
      </Row>
    </div>
  );
}
