import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_REPORT_LAST_VIEWED} from "../view/useQueryReportLastViewed";

const QUERY_NAME = "reportCreate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $name: String!,
      $jobId: ID!
    ) {
      ${QUERY_NAME} (
        name: $name,
        jobId: $jobId
      ) {
        _id
        name
        jobId
      }
    }
  `;

export default function useMutationReportCreate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_REPORT_LAST_VIEWED]
  });
}
