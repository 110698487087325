import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import jobsActions from "../actions";
import Page from "../../../common/page/Page";
import {useParams} from "react-router";
import useQueryPoolJobById from "./useQueryPoolJobById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import PoolJobBlock from "./PoolJobBlock";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

export default function PoolJobPage(/*props*/) {

  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const poolJobId = urlParams.poolJobId;
  const {data: poolJob, loading: jobLoading, errors: jobErrors} = useQueryPoolJobById(poolJobId);
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobPage");

  return (
    <WithRedirectionUserIsAuthorized action={jobsActions.POOL_JOBS_VIEW} yes={() =>
      <WaitForData loading={jobLoading} errors={jobErrors} onLoaded={() =>
        <>
          {poolJob &&
          <Page className="PoolJobPage">
            <Page.Title loading={jobLoading} text={poolJob.name}/>
            <Page.Content className="spaced-children"><PoolJobBlock poolJob={poolJob}/></Page.Content>
          </Page>
          }
          {!poolJob &&
          <Page className="PoolJobPage">
            <Page.Title loading={jobLoading}>{t("market:pool_jobs_details_not_found")}</Page.Title>
          </Page>
          }
        </>
      }/>
    }/>
  );
}
