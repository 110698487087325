import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "talentPoolUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $id: ID!,
      $name: String,
      $published: Boolean,
    ) {
      ${QUERY_NAME} (
        id: $id,
        name: $name,
        published: $published,
      ) {
        _id
        name
        published
      }
    }
  `;

export default function useMutationTalentPoolUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
