import React from "react";
import TypeUtils from "../../../../utils/TypeUtils";
import JobInlineEditInputText from "./JobInlineEditInputText";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import ActionLink from "../../../common/widgets/ActionLink";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useTranslationReporting from "../../useTranslationReporting";
import "./JobInlineEditInputTextArray.scss";

/**
 * Generic component for all job editable fields that are arrays of text values
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function JobInlineEditInputTextArray(props) {

  const {job, property, ...otherProps} = props;
  const values = TypeUtils.ensureArray(job[property]);

  return (
    <div className={"JobInlineEditInputTextArray"}>
      {values.map((value, index) =>
        <Row key={index}>
          <Col>
            <JobInlineEditInputText
              job={job}
              property={property}
              index={index}
              value={value}
              {...otherProps}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <JobInlineEditInputText
            job={job}
            property={property}
            index={TypeUtils.ensureArray(values).length}
            value={""}
            ViewComponent={AddItemViewComponent}
            {...otherProps}
          />
        </Col>
      </Row>
    </div>
  );
};

function AddItemViewComponent(props) {
  // Gets the same props as the DefaultViewComponent used by InlineEditBase, but we don't use them all
  const {onEnterEdit} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "AddItemViewComponent");

  const onClickLink = event => {
    event.preventDefault();
    onEnterEdit();
  }

  return (
    <div className={"view"}>
      <ActionLink onClick={onClickLink}><span className={"action-label"}>{t("reporting:jobs_details_notification_recipient_add_action")}</span></ActionLink>
    </div>
  );

}
