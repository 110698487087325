import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import ActionDiv from "../../../common/widgets/ActionDiv";
import TalentPoolDeleteButton from "../mutate/TalentPoolDeleteButton";
import "./TalentPoolPreview.scss";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../../utils/StringUtils";

export default function TalentPoolPreview(props) {

  const {talentPool, onClick} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolPreview");

  return (
    <div className="TalentPoolPreview">
      <Row className={"delete-row"}>
        <Col className={"delete-col"}>
          <TalentPoolDeleteButton talentPoolId={talentPool._id}/>
        </Col>
      </Row>
      <Row className={"content-row"}>
        <Col className={"content-col"}>
          <Row className={"name-row"}>
            <Col className={"name-col"}>
              <ActionDiv onClick={() => onClick(talentPool._id)} className={"name"}>
                {talentPool.name}
              </ActionDiv>
            </Col>
          </Row>
          <Row className={"job-row"}>
            <Col className={"job-col"}>
              {t("market:talent_pools_preview_job_count", {count: StringUtils.nullToZero(talentPool.jobCount)})}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
