import React from "react";
import Modal from "react-bootstrap/Modal";
import "./SuccessModal.scss";
import StatusIcon from "../../../img/correct.png";
import Image from "react-bootstrap/Image";
import useTranslationCommon from "../useTranslationCommon";
import useShowLoader from "../loading-widgets/useShowLoader";
import ActionButtonWithIconCancel from "../widgets/ActionButtonWithIconCancel";

/**
 * Display a success modal. Content is passed as a child. onDismiss is called when user dismisses the modal.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function SuccessModal(props) {

  const {show, onDismiss, children} = props;

  const {t, loading: tLoading} = useTranslationCommon();
  useShowLoader(tLoading, "SuccessModal");

  // RENDER

  return (
    <Modal show={show} onHide={onDismiss} className="SuccessModal">
      <Modal.Body>
        <div>
          <Image src={StatusIcon} className={"status-icon"}/>
        </div>
        <div>
          {children ? children : null}
        </div>
        <div className={"button-row"}>
          <ActionButtonWithIconCancel onClick={onDismiss}>
            {t("common:close_button")}
          </ActionButtonWithIconCancel>
        </div>
      </Modal.Body>
    </Modal>
  );
}
