import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "reportSearch";

export const QUERY = gql`
  query ${QUERY_NAME} ($filter: ReportFilterInput!) {
    ${QUERY_NAME} (filter: $filter){
      nodes {
        _id
        name
      }
    }
  }
`;

export default function useQueryReportNames(jobId) {

  const variables = {filter: {jobId}};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    variables,
    // In case we add a report, first return outdated cache and then replace with actual list
    fetchPolicy: "cache-and-network"
  });
}
