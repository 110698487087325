import React from "react";
import useFirebase from "../infra-no-ui/firebase/useFirebase";
import Firebase from "../infra-no-ui/firebase/Firebase";
import useTranslationAuthenticationNoUi from "./useTranslationAuthenticationNoUi";

export default function useSignOut() {

  const {t, loading: tLoading} = useTranslationAuthenticationNoUi();
  const firebase = useFirebase();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  // Sign out callback (use useCallback to avoid re-rendering of useSignOut consumers)
  const signOut = React.useCallback(() => {
    setLoading(true);
    firebase.doSignOut()
      .catch((error) => {
        const message = t(Firebase.getErrorTranslation(t, error.code)) + " (code: " + error.code + ")";
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [firebase, t]);

  return ({
    signOut,
    loading, // We mean the loading state of the signout action, not the loading of translation
    ready: !tLoading, // The component is not ready to be used by caller until the translation is loaded
    error
  });
}
