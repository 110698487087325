import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as CANDIDATE_BY_ID_QUERY_NAME} from "../../candidates/view/useQueryCandidateById";

const QUERY_NAME = "answerUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $id: ID!,
      $text: String,
    ) {
      ${QUERY_NAME} (
        id: $id,
        text: $text,
      ) {
        _id
        text
      }
    }
  `;

export default function useMutationAnswerUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    // An empty text deletes the answer and this affects the answer list from candidateById query.
    refetchQueries: [CANDIDATE_BY_ID_QUERY_NAME]
  });
}
