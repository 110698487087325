import React from "react";
import useLazyQueryClientRecipientListDownloadUrl from "./useLazyQueryClientRecipientListDownloadUrl";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import StringUtils from "../../../../utils/StringUtils";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import useErrorModal from "../../../common/modals/useErrorModal";
import useTranslationReporting from "../../useTranslationReporting";
import ActionLink from "../../../common/widgets/ActionLink";

export default function ClientListDownloadLink(props) {

  const onClickDownload = (event) => {
    event.preventDefault();
    setQueryLoading(true);

    return fetch().then(downloadUrl => {

      if (!StringUtils.isNullOrEmpty(downloadUrl)) {
        // This will trigger the file download (window.open(downloadUrl) produces a glitch)
        const a = document.createElement('a');
        a.href = downloadUrl.toString();
        a.click();
      }
    }).catch(error => {
      setError(error);
      showErrorModal();
    }).finally(() => {
      setQueryLoading(false);
    })
  };

  const {className, children} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientListDownloadLink");
  const {fetch} = useLazyQueryClientRecipientListDownloadUrl();

  // error returned by useLazyQueryClientDownloadUrl is not updated after fetch, we have to manage it ourselves
  const [error, setError] = React.useState(null);
  const errorMsg = useServerErrorFormatter(error);
  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("reporting:clients_list_download_error")}</p>
      {errorMsg}
    </>
  );

  // loading status returned by useLazyQueryClientDownloadUrl is not updated after fetch, we have to manage it ourselves
  const [queryLoading, setQueryLoading] = React.useState(false);

  return (
    <>{ErrorModal}
      <ActionLink className={className} onClick={onClickDownload} loading={queryLoading}>{children}</ActionLink>
    </>
  );
}
