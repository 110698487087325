import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import talentPoolActions from "../actions";
import Page from "../../../common/page/Page";
import {useParams} from "react-router";
import useQueryTalentPoolById from "./useQueryTalentPoolById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import TalentPoolBlock from "./TalentPoolBlock";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

export default function TalentPoolPage(/*props*/) {

  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const talentPoolId = urlParams.talentPoolId;
  const {data: talentPool, loading: talentPoolLoading, errors: talentPoolErrors} = useQueryTalentPoolById(talentPoolId);
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolPage");

  return (
    <WithRedirectionUserIsAuthorized action={talentPoolActions.TALENT_POOLS_VIEW} yes={() =>
      <WaitForData loading={talentPoolLoading} errors={talentPoolErrors} onLoaded={() =>
        <>
          {talentPool &&
          <Page className="TalentPoolPage">
            <Page.Title loading={talentPoolLoading} text={talentPool.name}/>
            <Page.Content className="spaced-children"><TalentPoolBlock talentPool={talentPool}/></Page.Content>
          </Page>
          }
          {!talentPool &&
          <Page className="TalentPoolPage">
            <Page.Title loading={talentPoolLoading}>{t("market:talent_pools_details_not_found")}</Page.Title>
          </Page>
          }
        </>
      }/>
    }/>
  );
}
