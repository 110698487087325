import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "candidateDeleteResume";

export const QUERY = gql`
    mutation ${QUERY_NAME} ($id: ID!) {
      ${QUERY_NAME} (id: $id) {
        _id
        resumeFile {
          filename
        }
      }
    }
  `;

export default function useMutationCandidateDeleteResume() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
