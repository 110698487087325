import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_REPORT_LAST_VIEWED} from "../view/useQueryReportLastViewed";

const QUERY_NAME = "reportViewUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $reportId: ID!,
    ) {
      ${QUERY_NAME} (
        reportId: $reportId,
      )
    }
  `;

export default function useMutationReportViewUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    // Beware that these queries won't fire if their React component is not mounted
    refetchQueries: [QUERY_REPORT_LAST_VIEWED]
  });
}
