import React from "react";
import FormControl from "react-bootstrap/FormControl";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import "./ResetPasswordForm.scss";
import useResetPassword from "../authentication-no-ui/useResetPassword";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import * as ROUTES from "../../constants/routes";
import {Navigate, useNavigate} from "react-router-dom";
import ActionButtonWithIconGo from "../common/widgets/ActionButtonWithIconGo";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import useFeedbackModal from "../common/modals/useFeedbackModal";

export default function ResetPasswordForm() {

  const sendResetRequest = () => {
    return resetPassword(email.trim());
  }

  const onSubmit = event => {
    event.preventDefault();
    launch(sendResetRequest, onSuccess)
  };

  const canSubmit = () => {
    return email !== "";
  };

  const onSuccess = () => {
    // Redirect to HOME when done
    navigate(ROUTES.HOME);
  };

  const {t, loading: tLoading} = useTranslationAuthenticationUi();
  const authUser = useAuthUser();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const {resetPassword, loading, error} = useResetPassword();
  const {launch, FeedbackModal} = useFeedbackModal({successMsg: t("authentication-ui:reset_password_success_msg"), errorMsg: error});


  useShowLoader(tLoading, "ResetPasswordForm");

  // RENDER

  // If already logged, do not display this page, redirect to HOME
  if (authUser.authenticated) {
    return <Navigate replace to={ROUTES.HOME} />;
  }

  return (
    <>
      {FeedbackModal}
      <div className="ResetPasswordForm">
        <Form onSubmit={onSubmit} className={"form-with-rows"}>
          <Row>
            <Col>
              <FormControl
                name="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                placeholder={t("authentication-ui:reset_password_email_placeholder")}
                type="email"
                autoComplete="email"
              />
            </Col>
          </Row>
          <Row>
            <Col className={"form-actions"}>
              <ActionButtonWithIconGo loading={loading} disabled={!canSubmit()} type="submit">
                {t("authentication-ui:reset_password_reset_button")}
              </ActionButtonWithIconGo>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
