import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import PoolCandidateInlineEditInput from "./PoolCandidateInlineEditInput";

/**
 * Generic component for all pool candidate editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PoolCandidateInlineEditInputText(props) {

  return <PoolCandidateInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
