import React from "react";
import useLazyQueryPoolCandidateResumeDownloadUrl from "./useLazyQueryPoolCandidateResumeDownloadUrl";
import ResumeDownloadLink from "../../../common/resume-upload/ResumeDownloadLink";

export default function PoolCandidateResumeDownloadLink(props) {

  const {candidateId, disabled, children} = props;
  const {fetch} = useLazyQueryPoolCandidateResumeDownloadUrl();

  return (<ResumeDownloadLink candidateId={candidateId} disabled={disabled} onFetch={fetch} children={children}/>);
}
