import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as CANDIDATES_BY_REPORT_ID_QUERY_NAME} from "../../candidates/view/useQueryCandidatesByReportId";

const QUERY_NAME = "reportUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $id: ID!,
      $name: String,
      $date: Date,
      $published: Boolean
      $note: String
      $questionnaireId: ID
    ) {
      ${QUERY_NAME} (
        id: $id,
        name: $name,
        date: $date,
        published: $published
        note: $note
        questionnaireId: $questionnaireId
      ) {
        _id
        name
        date
        published
        note
        questionnaireId
      }
    }
  `;

export default function useMutationReportUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    // Changing the questionnaire impacts the stats on candidate answers
    refetchQueries: [CANDIDATES_BY_REPORT_ID_QUERY_NAME]
  });
}
