import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import questionnairesActions from "../actions";
import Page from "../../../common/page/Page";
import useTranslationReporting from "../../useTranslationReporting";
import QuestionnairesBlock from "./QuestionnairesBlock";

export default function QuestionnairesPage(/*props*/) {

  const {t, loading: tLoading} = useTranslationReporting();

  return (
    <WithRedirectionUserIsAuthorized action={questionnairesActions.QUESTIONNAIRES_VIEW_ALL} yes={() =>
      <Page className="QuestionnairesPage">
        <Page.Title loading={tLoading}>{t("reporting:questionnaires_all_questionnaires_title")}</Page.Title>
        <Page.Content>
          <QuestionnairesBlock/>
        </Page.Content>
      </Page>
    }/>
  );

}
