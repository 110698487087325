import React from "react";
import {FormControl} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import StringUtils from "../../../utils/StringUtils";
import Button from "react-bootstrap/Button";
import "./InlineEditInputTextArea.scss";
import CancelIcon from "@mui/icons-material/Clear";
import ConfirmIcon from "@mui/icons-material/Done";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import InlineEditBase from "./InlineEditBase";
import HtmlUtils from "../../../utils/HtmlUtils";
import withOnBlur from "react-onblur";
import useInlineEditInputEvents from "./useInlineEditInputEvents";

/**
 * Input box that display the value as read only until we click the Edit icon next to the label.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditInputTextArea(props) {

  const {className, ...otherProps} = props;

  // Add onBlur listeners on the whole edit component to detect when focus is not in any of its children anymore
  const WithOnBlurInputTextEditComponent = withOnBlur()(InputTextAreaEditComponent);

  return (
    <InlineEditBase
      {...otherProps}
      formatValue={HtmlUtils.nlToBr}
      EditComponent={WithOnBlurInputTextEditComponent}
      className={["InlineEditInputTextArea", className].join(" ")}
    />
  );
};

function InputTextAreaEditComponent(props) {

  const {viewAs, placeholder, value, onSubmit, validate, onCancel, setBlurListener, unsetBlurListener} = props;
  const focusRef = React.useRef();

  const formatOutput = React.useCallback(output => {
    return StringUtils.nullToEmpty(output).trim();
  }, []);

  const {currentValue, submit, cancel, changeValue, validationError} = useInlineEditInputEvents(
    value, onSubmit, validate, onCancel, setBlurListener, unsetBlurListener, focusRef,null, formatOutput
  );

  const hasError = !StringUtils.isNullOrEmpty(validationError);

  // Submit button is clicked
  const onLocalSubmit = (event) => {
    event.preventDefault();
    submit();
  }

  // User types a letter in the input box
  const onChange = event => {
    changeValue(event.target.value);
    // Don't call validate here, because the current value might not be rendered yet.
  }

  // Cancel button is clicked
  const onClickCancel = event => {
    event.preventDefault();
    cancel();
  }

  return (
    <Form onSubmit={onLocalSubmit} className={"InputTextAreaEditComponent"} autoComplete={"off"}>
      <Row className={"input-row"}>
        <Col className="input-col">
          <FormControl
            className={"textarea"}
            as={"textarea"}
            type="text"
            value={currentValue}
            onChange={onChange}
            placeholder={placeholder}
            size={viewAs === "h1" ? "lg" : "text"}
            ref={focusRef}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {hasError && <div className={"error"}>{validationError}</div>}
        </Col>
      </Row>
      <Row className={"button-row"}>
        <Col className={"button-col"}>
          <Button variant="success" type={"submit"} disabled={hasError}><ConfirmIcon/></Button>
          <Button variant="danger" onClick={onClickCancel}><CancelIcon/></Button>
        </Col>
      </Row>
    </Form>
  );
}
