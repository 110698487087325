import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "jobUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $id: ID!,
      $title: String,
      $notificationRecipientEmails: [String],
      $notificationSenderName: String,
      $notificationSenderEmail: String,
      $questionnaireId: ID
    ) {
      ${QUERY_NAME} (
        id: $id,
        title: $title,
        notificationRecipientEmails: $notificationRecipientEmails,
        notificationSenderName: $notificationSenderName,
        notificationSenderEmail: $notificationSenderEmail,
        questionnaireId: $questionnaireId
      ) {
        _id
        title
        notificationRecipientEmails
        notificationSenderName
        notificationSenderEmail
        questionnaireId
      }
    }
  `;

export default function useMutationJobUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
