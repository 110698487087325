import React from "react";
import "./UntranslatedContentError.scss";

/*
 * Pass this error component to an error boundary class such as ErrorBoundary to display an error message.
 * This message is not translated, so use this component only when the translation provider is not ready.
 */
export default function UntranslatedContentError(/*props*/) {

  const supportEmail = "techsupport@mouseatwork.com";

  return (
    <div className={"UntranslatedContentError"}>
      <h1>Oops! Something went wrong...</h1>
      <p>
        We are truly sorry for this. If you would like to help, please send us more details and we will fix this shortly.
      </p>
      <p>Contact us at the following address: </p>
      <p><a href={"mailto:" + supportEmail}>{supportEmail}</a></p>
      <p>Or call us at 514-807-5852, Monday to Friday, from 9 am to 4 pm.</p>
    </div>
  );
}
