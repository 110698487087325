import React from "react";
import InlineEditInputDate from "../../../common/widgets/InlineEditInputDate";
import ReportInlineEditInput from "./ReportInlineEditInput";

/**
 * Generic component for all report editable date fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportInlineEditInputDate(props) {

  return <ReportInlineEditInput InlineEditInputComponent={InlineEditInputDate} {...props}/>
};
