import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useMutationEmailCreate from "./useMutationEmailCreate";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import useAuthUser from "../../../authentication-no-ui/useAuthUser";
import useLanguages from "../../../infra-no-ui/translation/useLanguages";
import DateUtils from "../../../../utils/DateUtils";
import StringUtils from "../../../../utils/StringUtils";
import "./EmailCreateForm.scss";
import usePublicReportUrl from "../../reports/view/usePublicReportUrl";
import TypeUtils from "../../../../utils/TypeUtils";
import ActionButtonWithIconGo from "../../../common/widgets/ActionButtonWithIconGo";
import ActionButtonWithIconCancel from "../../../common/widgets/ActionButtonWithIconCancel";
import HtmlUtils from "../../../../utils/HtmlUtils";
import draftToHtml from "draftjs-to-html";
import {convertToRaw, EditorState} from "draft-js";
import {FormControl, InputGroup} from "react-bootstrap";
import useErrorModal from "../../../common/modals/useErrorModal";
import {stateFromHTML} from "draft-js-import-html";
import WysiwygEditor from "../../../common/widgets/WysiwygEditor";
import ActionLink from "../../../common/widgets/ActionLink";
import ActionDiv from "../../../common/widgets/ActionDiv";

export default function EmailCreateForm(props) {

  const {onAbort, onSuccess, report, job} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading);

  const {mutate, loading: mutationLoading, errors: mutationErrors} = useMutationEmailCreate();

  const authUser = useAuthUser();
  const settings = TypeUtils.ensureObject(authUser.profile.settings);
  const reportingSettings = TypeUtils.ensureObject(settings.reporting);

  const successMsg = <p>{t("reporting:emails_create_success_msg")}</p>;
  const errorMsg = useServerErrorFormatter(mutationErrors);
  const {launch, FeedbackModal} = useFeedbackModal({successMsg, errorMsg});

  // Use sender name from job params, otherwise use profile name
  const senderName = StringUtils.isNullOrEmpty(job.notificationSenderName) ?
    authUser.profile.firstName + " " + authUser.profile.lastName :
    job.notificationSenderName;

  // Use sender email from job params, otherwise use profile email
  const senderEmail = StringUtils.isNullOrEmpty(job.notificationSenderEmail) ?
    authUser.email : job.notificationSenderEmail;

  const {getCurrentLanguageCode} = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();
  const formattedReportDate = DateUtils.timelessDateToLongString(new Date(report.date), currentLanguageCode);

  const formatWysiwygInput = React.useCallback((input) => {
    return EditorState.createWithContent(stateFromHTML(input));
  }, []);

  const formatWysiwygOutput = React.useCallback(output => {
    return draftToHtml(convertToRaw(output.getCurrentContent()));
  }, []);

  // Init To array with recipients from job; if there are none, provide an empty box
  const jobNotificationRecipientEmails = React.useMemo(() => {
    const trimmedEmails = trimValues(job.notificationRecipientEmails);
    if (TypeUtils.arrayIsEmpty(trimmedEmails))
      trimmedEmails.push("");
    return trimmedEmails;
  }, [job.notificationRecipientEmails]);

  const [recipients, setRecipients] = React.useState(jobNotificationRecipientEmails);
  const [fromName, setFromName] = React.useState(senderName);
  const [from, setFrom] = React.useState(senderEmail);
  const [subject, setSubject] = React.useState(`${job.title} (${formattedReportDate})`);
  const [htmlContent, setHtmlContent] = React.useState(formatWysiwygInput(StringUtils.nullToEmpty(reportingSettings.defaultNotificationMessage)));
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const publicReportUrl = usePublicReportUrl(report._id);

  const validateEmail = React.useCallback(email => {
    if (!StringUtils.isNullOrEmpty(email) && !StringUtils.isEmail(email)) {
      return t("reporting:emails_create_email_invalid");
    }
  }, [t]);

  const validateFrom = React.useCallback((value) => {
    if (StringUtils.isNullOrEmpty(StringUtils.nullToEmpty(value).trim())) {
      return t("reporting:emails_create_empty_from_error");
    }
    return validateEmail(value);
  }, [t, validateEmail]);

  const validateFromName = React.useCallback((value) => {
    if (StringUtils.isNullOrEmpty(StringUtils.nullToEmpty(value).trim())) {
      return t("reporting:emails_create_empty_from_name_error");
    }
  }, [t]);

  const validateRecipient = React.useCallback((value) => {
    if (StringUtils.isNullOrEmpty(StringUtils.nullToEmpty(value).trim())) {
      return t("reporting:emails_create_empty_to_error");
    }
    return validateEmail(value);
  }, [t, validateEmail]);

  const validateRecipients = React.useCallback((values) => {
    // Fail as soon as one recipient is invalid
    for (let i = 0; i < values.length; i++) {
      const validationError = validateRecipient(values[i]);
      if (validationError) {
        return validationError;
      }
    }
  }, [validateRecipient]);

  const validateSubject = React.useCallback((value) => {
    if (StringUtils.isNullOrEmpty(StringUtils.nullToEmpty(value).trim())) {
      return t("reporting:emails_create_empty_subject_error");
    }
  }, [t]);

  /*
  const validateHtmlString = React.useCallback((value) => {
    // Remove tags from html string then see if there is textual content
    const trimmedHtml = StringUtils.nullToEmpty(value).replace(/(<([^>]+)>)/gi, "").trim();
    if (StringUtils.isNullOrEmpty(trimmedHtml)) {
      return t("reporting:emails_create_empty_message_error");
    }
  }, [t]);
  */

  const validateHtmlContent = React.useCallback((value) => {
    // Convert editor state to string before removing tags and then see if there is textual content
    const trimmedHtml = draftToHtml(convertToRaw(value.getCurrentContent())).replace(/(<([^>]+)>)/gi, "").trim();
    if (StringUtils.isNullOrEmpty(trimmedHtml)) {
      return t("reporting:emails_create_empty_message_error");
    }
  }, [t]);

  // Callback that validates nothing, because we don't want to display error messages until user attempts to submit
  const dontValidate = React.useCallback(() => null, []);

  // Validate inputs
  const validationErrors = [
    validateFrom(from),
    validateFromName(fromName),
    validateRecipients(recipients),
    validateSubject(subject),
    validateHtmlContent(htmlContent)
  ].filter(error => !StringUtils.isNullOrEmpty(error));
  const hasValidationErrors = !TypeUtils.arrayIsEmpty(validationErrors);

  const {ErrorModal: ValidationErrorModal, show: showValidationErrorModal} = useErrorModal(<p>{t("reporting:emails_create_validation_errors")}</p>);

  // Create and send the email.
  const createEmail = () => {
    // Replace [LINK] placeholder with public report URL
    const processedMessage = formatWysiwygOutput(htmlContent).replaceAll("[LINK]", "<a href=" + publicReportUrl + ">" +
        t("reporting:emails_client_report_link_msg") + "</a>").replaceAll("[FROM_NAME]", fromName);

    // Remove empty recipients
    const trimmedRecipients = trimValues(recipients);

    return mutate({
      variables: {
        reportId: report._id,
        to: trimmedRecipients,
        fromName: fromName.trim(),
        subject,
        text: " ",
        html: processedMessage.trim(),
        from: from.trim(),
      }
    });
  }

  const onChangeRecipient = (index, value) => {
    const newRecipients = TypeUtils.shallowCopyArray(recipients);
    newRecipients[index] = value;
    setRecipients(newRecipients);
  }

  const onAddRecipient = () => {
    // Add an empty recipient
    onChangeRecipient(recipients.length, "");
  }

  const onClearRecipient = (index) => {
    // Remove recipient
    const newRecipients = TypeUtils.shallowCopyArray(recipients);
    newRecipients.splice(index, 1);
    setRecipients(newRecipients);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    setSubmitAttempted(true);
    if (hasValidationErrors)
      showValidationErrorModal();
    else
      return launch(createEmail, onSuccess);
  }

  const canSubmit = () => {
    // For now, let the user submit and then display the validation errors
    return true;
  }

  return (
    <>
      {FeedbackModal} {ValidationErrorModal}
      <form className="EmailCreateForm form-with-rows" autoComplete={"off"}>
        <Row>
          <Col>
            <h1>{t("reporting:emails_create_title")}</h1>
          </Col>
        </Row>
        <Row className={"address-row"}>
          {/* "to" block will wrap under "from" block on narrow screens */}
          <Col className={"from-section"}>
            <Row>
              <Col className={"form-label form-required"}>
                {t("reporting:emails_create_from_label")}
              </Col>
            </Row>
            <Row>
              <Col>
                <InputTextEditComponent
                  value={from}
                  placeholder={t("reporting:emails_create_from_placeholder")}
                  onChange={setFrom}
                  validate={submitAttempted ? validateFrom : dontValidate}
                />
              </Col>
            </Row>
            <Row className={"from-name-row"}>
              <Col>
                <InputTextEditComponent
                  value={fromName}
                  placeholder={t("reporting:emails_create_from_name_placeholder")}
                  onChange={setFromName}
                  validate={submitAttempted ? validateFromName : dontValidate}
                />
              </Col>
            </Row>
          </Col>
          <Col className={"to-section"}>
            <Row>
              <Col className={"form-label form-required"}>
                {t("reporting:emails_create_to_label")}
              </Col>
            </Row>
            {recipients.map((recipient, index) =>
              <Row key={index} className={"recipient-row"}>
                <Col>
                  <InputTextEditComponent
                    value={recipient}
                    placeholder={t("reporting:emails_create_to_placeholder")}
                    onChange={(value) => onChangeRecipient(index, value)}
                    validate={submitAttempted ? validateRecipient : dontValidate}
                    onClear={recipients.length > 1 ? () => onClearRecipient(index) : undefined}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <ActionLink onClick={onAddRecipient}><span className={"to-add-label"}>{t("reporting:emails_create_to_add_action")}</span></ActionLink>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className={"form-label form-required"}>
            {t("reporting:emails_create_subject_label")}
          </Col>
        </Row>
        <Row>
          <Col>
            <InputTextEditComponent
              value={subject}
              placeholder={t("reporting:emails_create_subject_placeholder")}
              onChange={setSubject}
              validate={submitAttempted ? validateSubject : dontValidate}
            />
          </Col>
        </Row>
        <Row>
          <Col className={"form-label form-required"}>
            {t("reporting:emails_create_text_label")}
          </Col>
        </Row>
        <Row>
          <Col>
            <WysiwygEditComponent
              value={htmlContent}
              placeholder={t("reporting:emails_create_text_placeholder")}
              onChange={setHtmlContent}
              validate={submitAttempted ? validateHtmlContent : dontValidate}
            />
          </Col>
        </Row>
        <Row>
          <Col className={"form-info"}>
            {HtmlUtils.htmlToComponents(t("reporting:emails_create_link_msg"))}
          </Col>
        </Row>
        <Row>
          <Col className={"form-info"}>
            {HtmlUtils.htmlToComponents(t("reporting:emails_create_from_name_msg"))}
          </Col>
        </Row>
        <Row>
          <Col className={"form-actions"}>
            <ActionButtonWithIconGo loading={mutationLoading} type={"submit"} onClick={onSubmit} disabled={!canSubmit()}>
              {t("reporting:emails_send_submit_action")}
            </ActionButtonWithIconGo>
            <ActionButtonWithIconCancel onClick={onAbort}>{t("reporting:emails_send_cancel_action")}</ActionButtonWithIconCancel>
          </Col>
        </Row>
      </form>
    </>
  );
}

// Similar to InlineEditInputText.InputTextEditComponent, but without the submit buttons
function InputTextEditComponent(props) {
  const {value, onChange, placeholder, validate, onClear} = props;
  const [validationError, setValidationError] = React.useState();
  const hasError = !StringUtils.isNullOrEmpty(validationError);

  React.useEffect(() => {
    setValidationError(validate(value));
  }, [value, validate]);

  const onLocalChange = (event => {
    onChange(event.target.value);
  });

  return (
    <div className={"InputTextEditComponent"}>
      <Row className={"input-row"}>
        <Col className="input-col">
          <InputGroup>
            <FormControl
              type="text"
              value={value}
              onChange={onLocalChange}
              placeholder={placeholder}
            />
            {onClear &&
            <InputGroup.Text><ActionDiv onClick={onClear}>X</ActionDiv></InputGroup.Text>
            }
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {hasError && <div className={"error"}>{validationError}</div>}
        </Col>
      </Row>
    </div>
  );
}

// Similar to InlineEditWysiwyg.WysiwygEditComponent, but without the submit buttons
function WysiwygEditComponent(props) {
  const {
    placeholder,
    value, // Editor state, not html string
    onChange,
    validate
  } = props;

  const [validationError, setValidationError] = React.useState();
  const hasError = !StringUtils.isNullOrEmpty(validationError);

  React.useEffect(() => {
    setValidationError(validate(value));
  }, [value, validate]);

  const {getCurrentLanguageCode} = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  return (
    <div className={"WysiwygEditComponent"}>
      <Row className={"input-row"}>
        <Col className="input-col">
          <WysiwygEditor value={value} onChange={onChange} placeholder={placeholder} languageCode={currentLanguageCode}/>
        </Col>
      </Row>
      <Row>
        <Col>
          {hasError && <div className={"error"}>{validationError}</div>}
        </Col>
      </Row>
    </div>
  );
}

/**
 * Return an array made of all trimmed, unempty strings from another array
 * @param values Array to filter
 * @returns {*[]} String from values array, trimmed and with empty strings removed
 */
function trimValues(values) {
  return TypeUtils.ensureArray(values).map(item => StringUtils.nullToEmpty(item).trim()).filter(item => !StringUtils.isNullOrEmpty(item));
}
