import {InMemoryCache} from "@apollo/client";

/**
 * Create the caching strategy for Apollo client.
 */
export function apolloCacheManager() {
  return new InMemoryCache({
    typePolicies: {
      QuestionnaireType: {
        fields: {
          questions: _overwriteExisting()
        }
      }
    }
  });
}

const _overwriteExisting = () => {
  return {
    merge: false, // Do not merge existing and incoming, overwrite existing
  }
}
