import React from "react";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import "./PoolJobBlock.scss";
import useTranslationMarket from "../../useTranslationMarket";
import PoolJobInlineEditName from "../mutate/PoolJobInlineEditName";
import JobCategoryList from "../../categories/view/JobCategoryList";
import BackIcon from "@mui/icons-material/KeyboardBackspace";
import ActionIcon from "../../../common/widgets/ActionIcon";
import * as ROUTES from "../../../../constants/routes";
import RouterUtils from "../../../../utils/RouterUtils";
import {useNavigate} from "react-router-dom";

export default function PoolJobBlock(props) {

  const {poolJob} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolJobBlock");

  const navigate = useNavigate();

  const goBack = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.POOL, {talentPoolId: poolJob.talentPoolId}));
  }

  return (
    <div className="PoolJobBlock">
      <Row>
        <Col className={"pool-job-details"}>
          <Row className={"title-row"}>
            <Col className={"back-button-container"}>
              <div className={"back-button"}>
                <ActionIcon onClick={goBack} icon={BackIcon}>{t("market:go_back_action")}</ActionIcon>
              </div>
              <PoolJobInlineEditName poolJob={poolJob} viewAs="h1"/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className={"categories-list"}>
          <Row>
            <Col>
              <h2>{t("market:pool_jobs_categories_title")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <JobCategoryList talentPoolId={poolJob.talentPoolId} poolJobId={poolJob._id}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
