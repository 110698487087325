import React from "react";
import DotsIcon from "../../../img/horizontal-dots-small.gif";
import "./HorizontalLoader.scss";
import Loader from "./Loader";
import Image from "react-bootstrap/Image";

/**
 * Displays an horizontal loading spinner when a registered component triggers it.
 * See Loader.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function HorizontalLoader(props) {

    const LoadingIcon = <Image src={DotsIcon}/>

    // RENDER
    return <div className="HorizontalLoader"><Loader {...props} icon={LoadingIcon}/></div>
}