import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useErrorModal from "../../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import StringUtils from "../../../../utils/StringUtils";
import useMutationQuestionnaireUpdate from "./useMutationQuestionnaireUpdate";
import TypeUtils from "../../../../utils/TypeUtils";

/**
 * Generic component for all questionnaire editable fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function QuestionnaireInlineEditInput(props) {

  const {InlineEditInputComponent, questionnaire, viewAs, validate, placeholder, property, className, index, openEditing, ViewComponent} = props;
  const {mutate, loading, errors} = useMutationQuestionnaireUpdate();
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionnaireInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  // Get the current value for the property. If an index is provided, it means the property refers to an array of values
  const currentValue = index == null ? questionnaire[property] : questionnaire[property][index];

  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("reporting:questionnaires_details_update_error")}</p>
      {errorMsg}
    </>
  );

  const onSubmit = (value) => {
    const variables = {};
    variables["id"] = questionnaire._id;

    // If an index is provided, the property is an array of values and we are modifying only one item
    if (index != null) {
      // First make a copy of the array before modifying it
      variables[property] = TypeUtils.shallowCopyArray(questionnaire[property]);
      variables[property][index] = value;

      // Remove all empty values
      variables[property] = variables[property].filter(item => !StringUtils.isNullOrEmpty(item));
    } else {
      // The property is a single value, replace it
      variables[property] = value;
    }

    return mutate({variables})
      .catch(error => {
        showErrorModal();
        // rethrow error so that InlineEdit component acts on it
        throw new Error(error);
      });
  }

  return (
    <>
      {ErrorModal}
      <div className={["QuestionnaireInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          placeholder={placeholder}
          value={StringUtils.nullToEmpty(currentValue)}
          onSubmit={onSubmit}
          validate={validate}
          viewAs={viewAs}
          loading={loading}
          openEditing={openEditing}
          ViewComponent={ViewComponent}
        />
      </div>
    </>
  );
};
