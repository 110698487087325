import React from "react";
import "./QuestionDetailsList.scss";

/**
 * Container to hold individual questions. It is intended to be used as the main container inside a react-movable List.
 */
export default React.forwardRef((props, ref) => {
  const {children, ...otherProps} = props;

  return (
    <div ref={ref} {...otherProps} className={"QuestionDetailsList"}>{children}</div>
  )
});
