import clients from "../components/reporting/clients/actions";
import jobs from "../components/reporting/jobs/actions";
import reports from "../components/reporting/reports/actions";
import reportingSettings from "../components/reporting/settings/actions";
import questionnaires from "../components/reporting/questionnaires/actions";
import pools from "../components/market/pools/actions";
import poolJobs from "../components/market/jobs/actions";
import jobCategories from "../components/market/categories/actions";

// To simplify things, there are no dynamic rules to check "mine" and "myorg". They depend on specific data
// and this will be checked on the GraphQL side. Here, we only want to do a fast check whether a page is
// available to the user or whether the user must be redirected. Anyway, the user has access to all the code
// of the app. When a user can access a page but not with specific data, data won't be made available by GraphQL.
const authorizationRules = {

  // Authenticated users (any type)
  authenticated: {

    // Static rules
    static: [
      // Managing my profile will go here
    ],
  },

  // Recruiters (employees of an organization)
  recruiter: {

    // Static rules
    static: [
      clients.CLIENTS_VIEW,
      clients.CLIENTS_VIEW_ALL,
      jobCategories.JOB_CATEGORIES_VIEW,
      jobs.JOBS_VIEW,
      poolJobs.POOL_JOBS_VIEW,
      pools.TALENT_POOLS_VIEW,
      pools.TALENT_POOLS_VIEW_ALL,
      questionnaires.QUESTIONNAIRES_VIEW,
      questionnaires.QUESTIONNAIRES_VIEW_ALL,
      reports.REPORTS_VIEW,
      reportingSettings.REPORTING_SETTINGS_UPDATE
    ],

    // This role inherits authorizations from these other roles
    inherits: ['authenticated']
  },
};

export default authorizationRules;
