import React from "react";
import Form from "react-bootstrap/Form";
import StringUtils from "../../../utils/StringUtils";
import Button from "react-bootstrap/Button";
import "./InlineEditInputDate.scss";
import CancelIcon from "@mui/icons-material/Clear";
import ConfirmIcon from "@mui/icons-material/Done";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import InlineEditBase from "./InlineEditBase";
import DatePickerWithoutTime from "./DatePickerWithoutTime";
import DateUtils from "../../../utils/DateUtils";
import CalendarIcon from "@mui/icons-material/Today";
import {InputGroup} from "react-bootstrap";
import withOnBlur from "react-onblur";
import useInlineEditInputEvents from "./useInlineEditInputEvents";
/**
 * Input box that display the value as read only until we click the Edit icon next to the label.
 * Then a date picker is displayed. The date manipulated is a date without timezone
 * (expressed as 2021-12-20T00:00:00.000Z).
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditInputDate(props) {

  // Format the date for display: keep only YYYY-MM-DD
  // Input is a string (could also support a Date in the future)
  const formatDate = React.useCallback((date) => {
    if (StringUtils.isNullOrEmpty(date))
      return "";

    return DateUtils.timelessDateToString(new Date(date));
  }, []);

  // Add onBlur listeners on the whole edit component to detect when focus is not in any of its children anymore
  const WithOnBlurDateEditComponent = withOnBlur()(DateEditComponent);

  return <InlineEditBase {...props} EditComponent={WithOnBlurDateEditComponent} formatValue={formatDate} actionIcon={CalendarIcon}/>
};

function DateEditComponent(props) {
  const {placeholder, value, onSubmit, validate, onCancel, setBlurListener, unsetBlurListener} = props;

  // Value received is a string, convert it to a local date so that it can be manipulated by the datepicker as a regular date.
  const formatInput = React.useCallback(input => {
    return input ? DateUtils.timelessDateToLocalDate(new Date(input)) : input;
  }, []);

  // Date manipulated by the date picker is in current timezone, make it back a timeless date (timezone is UTC)
  const formatOutput = React.useCallback(output => {
    return output ? DateUtils.localDateToTimelessDate(output) : output
  }, []);

  const {currentValue, submit, cancel, changeValue, validationError} = useInlineEditInputEvents(
    value, onSubmit, validate, onCancel, setBlurListener, unsetBlurListener, null, formatInput, formatOutput
  );

  const hasError = !StringUtils.isNullOrEmpty(validationError);

  // Submit button is clicked
  const onLocalSubmit = (event) => {
    event.preventDefault();
    submit();
  }

  // User types a letter in the input box and the date is valid, or user clicks a date
  const onChange = localDate => {
    // DatePicker sends a Date object, no need to convert it to a Date
    changeValue(localDate);
    // Don't call validate here, because the current value might not be rendered yet.
  }

  // Cancel button is clicked
  const onClickCancel = event => {
    event.preventDefault();
    cancel();
  }

  return (
    <Form onSubmit={onLocalSubmit} className={"InputDateEditComponent"} autoComplete={"off"}>
      <Row className={"input-row"}>
        <Col className="input-col">
          <InputGroup>
            <DatePickerWithoutTime
              selected={currentValue}
              onChange={onChange}
              placeholderText={placeholder}
              startOpen
              autofocus
              onDateSelected={submit}
            />
            <Button variant="success" type={"submit"} disabled={hasError}><ConfirmIcon/></Button>
            <Button variant="danger" onClick={onClickCancel}><CancelIcon/></Button>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {hasError && <div className={"error"}>{validationError}</div>}
        </Col>
      </Row>
    </Form>
  );
}
