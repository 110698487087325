import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_CLIENT_LAST_VIEWED} from "../view/useQueryClientLastViewed";

const QUERY_NAME = "clientDelete";

export const QUERY = gql`
    mutation ${QUERY_NAME} ($id: ID!) {
      ${QUERY_NAME} (id: $id) {
        _id
      }
    }
  `;

export default function useMutationClientDelete() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_CLIENT_LAST_VIEWED]
  });
}
