import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import ActionDiv from "../../../common/widgets/ActionDiv";
import CandidateDeleteButton from "../mutate/CandidateDeleteButton";
import "./CandidatePreview.scss";
import HtmlUtils from "../../../../utils/HtmlUtils";
import StringUtils from "../../../../utils/StringUtils";
import ViewedBadge from "../../../common/widgets/ViewedBadge";
import QuestionnaireStats from "./QuestionnaireStats";

const NOTE_LENGTH = 70;

export default function CandidatePreview(props) {

  const {candidate, onClick} = props;

  const fullNote = StringUtils.nullToEmpty(HtmlUtils.removeHtml(candidate.note)).trim();

  const truncatedNote = fullNote.length > NOTE_LENGTH ?
      fullNote.substring(0, NOTE_LENGTH).concat("...") : StringUtils.nullToEmpty(fullNote);

  const note = truncatedNote.length > 0 ? `"${truncatedNote}"` : "";

  return (
    <div className="CandidatePreview">
      <Row className={"name-row"}>
        <Col className={"name-col"}>
          <ActionDiv onClick={() => onClick(candidate._id)} className={"name"}>
            {candidate.name}
          </ActionDiv>
        </Col>
        <Col className={"icon-col"}>
          <Row className={"icon-row"}>
            <Col className={"viewed-col"}>
              <ViewedBadge show={candidate.viewed}/>
            </Col>
            <Col className={"delete-col"}>
              <CandidateDeleteButton candidateId={candidate._id}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={"completion-row"}>
        <Col className={"completion-col"}>
          {candidate.answers ?
          <QuestionnaireStats answers={candidate.answers}/>
            :
            <>&nbsp;</>
          }
        </Col>
      </Row>
      <Row className={"note-row"}>
        <Col className={"note-col"}>
          <div className={"note"}>{note}</div>
        </Col>
      </Row>
    </div>
  );
};
