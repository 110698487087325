import React from "react";
import StringUtils from "../../../../utils/StringUtils";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import JobInlineEditInputText from "./JobInlineEditInputText";

export default function JobInlineEditTitle(props) {

  const {job, viewAs} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "JobInlineEditTitle");

  const validateTitle = React.useCallback(value => {
    if (StringUtils.isNullOrEmpty(value))
      return t("reporting:jobs_details_title_required_error");
  }, [t]);

  return (
    <JobInlineEditInputText
      job={job}
      placeholder={t("reporting:jobs_details_title_placeholder")}
      property={"title"}
      validate={validateTitle}
      viewAs={viewAs}
      className="JobInlineEditTitle"
    />
  );
};
