import React from "react";
import InlineEditWysiwyg from "../../../common/widgets/InlineEditWysiwyg";
import ReportingSettingsInlineEditInput from "./ReportingSettingsInlineEditInput";

/**
 * Generic component for all report settings editable wysiwyg fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportingSettingsInlineEditInputWysiwyg(props) {
  return <ReportingSettingsInlineEditInput InlineEditInputComponent={InlineEditWysiwyg} {...props}/>
};
