import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useContentModal from "../../../common/modals/useContentModal";
import ActionLink from "../../../common/widgets/ActionLink";
import {useNavigate} from "react-router-dom";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import "./QuestionnairesBlock.scss";
import QuestionnaireCreateForm from "../mutate/QuestionnaireCreateForm";
import QuestionnaireSearchBox from "../search/QuestionnaireSearchBox";
import LastViewedQuestionnaires from "./LastViewedQuestionnaires";

function QuestionnairesBlock(/*props*/) {

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionnairesBlock");
  // Modal to show questionnaire creation form
  const {ContentModal: QuestionnaireCreateModal, show: showQuestionnaireCreateModal, hide: hideQuestionnaireCreateModal} = useContentModal();
  const navigate = useNavigate();

  const onClickCreate = () => {
    showQuestionnaireCreateModal(questionnaireCreateForm);
  }

  const onQuestionnaireCreated = (questionnaire) => {
    hideQuestionnaireCreateModal();
    navigate(RouterUtils.injectParamsInRoute(ROUTES.QUESTIONNAIRE, {questionnaireId: questionnaire._id}));
  };

  const questionnaireCreateForm = <QuestionnaireCreateForm onAbort={hideQuestionnaireCreateModal} onSuccess={onQuestionnaireCreated}/>;

  return (
    <>
      {QuestionnaireCreateModal}
      <div className="QuestionnairesBlock">
        <Row className="search-and-stats-block">
          <Col>
            <Row className={"search-block-row"}>
              <Col>
                <Row>
                  <Col>
                    <QuestionnaireSearchBox autofocus/>
                  </Col>
                </Row>
                <Row className={"create-link-row"}>
                  <Col>
                    <ActionLink onClick={onClickCreate}>{t("reporting:questionnaires_create_action")}</ActionLink>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="list-row">
              <Col>
                <LastViewedQuestionnaires/>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default QuestionnairesBlock;
