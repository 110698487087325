import React from "react";
import "./ResetPasswordPage.scss";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ResetPasswordForm from "./ResetPasswordForm";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import Page from "../common/page/Page";

export default function ResetPasswordPage() {

  const {t, loading: tLoading} = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "ResetPasswordPage");

  // RENDER

  return (
    <Page className="ResetPasswordPage">
      <Page.Title loading={tLoading} text={t("authentication-ui:reset_password_title")}/>
      <Page.Content>
        <Row>
          <Col>{/*empty row for even spacing*/}</Col>
        </Row>
        <Row>
          <Col>
            {/*<Image src={logo_portal} className="portal_logo" />*/}
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6">
            <ResetPasswordBlock />
          </Col>
        </Row>
      </Page.Content>
    </Page>
  );
}

function ResetPasswordBlock() {


  const {t} = useTranslationAuthenticationUi(useShowLoader);

  // RENDER

  return (
    <Card className="ResetPasswordBlock">
      <Card.Body>
        <Row>
          <Col>
            <h2>{t("authentication-ui:reset_password_title")}</h2>
            <p>{t("authentication-ui:reset_password_msg")}</p>
            <ResetPasswordForm />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
