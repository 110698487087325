import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as CANDIDATE_BY_ID_QUERY_NAME} from "../../candidates/view/useQueryCandidateById";

const QUERY_NAME = "answerCreate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $questionId: ID!
      $candidateId: ID!
      $text: String!,
    ) {
      ${QUERY_NAME} (
        questionId: $questionId,
        candidateId: $candidateId,
        text: $text
      ) {
        _id
        questionId
        candidateId
        text
      }
    }
  `;

export default function useMutationAnswerCreate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [CANDIDATE_BY_ID_QUERY_NAME]
  });
}
