import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "questionnaireRemoveQuestion";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
    $questionnaireId: ID!
    $questionId: ID!
    ) {
      ${QUERY_NAME} (
      questionnaireId: $questionnaireId
      questionId: $questionId
      ) {
        _id
        questionCount
        questions {
          _id
        }
      }
    }
  `;

export default function useMutationQuestionnaireRemoveQuestion(options) {

  return useWrappedMutation(QUERY, "", QUERY_NAME, options);
}
