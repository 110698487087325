import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import ReportInlineEditInput from "./ReportInlineEditInput";

/**
 * Generic component for all report editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportInlineEditInputText(props) {

  return <ReportInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
