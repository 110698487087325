import React from "react";
import StringUtils from "../../../../utils/StringUtils";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import QuestionnaireInlineEditInputText from "./QuestionnaireInlineEditInputText";

export default function QuestionnaireInlineEditName(props) {

  const {questionnaire, viewAs} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionnaireInlineEditName");

  const validateName = React.useCallback(value => {
    if (StringUtils.isNullOrEmpty(value))
      return t("reporting:questionnaires_details_name_required_error");
  }, [t]);

  return (
    <QuestionnaireInlineEditInputText
      questionnaire={questionnaire}
      placeholder={t("reporting:questionnaires_details_name_placeholder")}
      property={"name"}
      validate={validateName}
      viewAs={viewAs}
      className="QuestionnaireInlineEditName"
    />
  );
};
