import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import {useNavigate} from "react-router-dom";
import "./JobSearchResult.scss";
import ActionButton from "../../../common/widgets/ActionButton";
import ReportByJobCount from "./ReportByJobCount";

export default function JobSearchResult(props) {

  const {item: job} = props;

  const navigate = useNavigate();


  function onClickJob(event, jobId, clientId) {
    event.preventDefault();
    navigate(RouterUtils.injectParamsInRoute(ROUTES.JOB, {jobId, clientId}))
  }

  return (
    <ActionButton as={"div"} variant="outline-primary" onClick={event => onClickJob(event, job._id, job.clientId)} className={"JobSearchResult"}>
      <Row>
        <Col className={"name-col"}>
          {job.title}
        </Col>
        <Col className={"job-col"}>
          <ReportByJobCount jobId={job._id}/>
        </Col>
      </Row>
    </ActionButton>
  );
};
