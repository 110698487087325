import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "questionUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $id: ID!,
      $text: String,
      $mandatory: Boolean,
    ) {
      ${QUERY_NAME} (
        id: $id,
        text: $text,
        mandatory: $mandatory,
      ) {
        _id
        text
        mandatory
      }
    }
  `;

export default function useMutationQuestionUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
