import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import ActionDiv from "../../../common/widgets/ActionDiv";
import PoolCandidateDeleteButton from "../mutate/PoolCandidateDeleteButton";
import "./PoolCandidatePreview.scss";
import HtmlUtils from "../../../../utils/HtmlUtils";
import StringUtils from "../../../../utils/StringUtils";
import ViewedBadge from "../../../common/widgets/ViewedBadge";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useTranslationMarket from "../../useTranslationMarket";

const NOTE_LENGTH = 35;

export default function PoolCandidatePreview(props) {

  const {poolCandidate, onClick} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "PoolCandidatePreview")

  const fullNote = StringUtils.nullToEmpty(HtmlUtils.removeHtml(poolCandidate.note)).trim();

  const truncatedNote = fullNote.length > NOTE_LENGTH ?
      fullNote.substring(0, NOTE_LENGTH).concat("...") : StringUtils.nullToEmpty(fullNote);

  const note = truncatedNote.length > 0 ? `"${truncatedNote}"` : "";

  return (
    <div className="PoolCandidatePreview">
      <Row className={"header-row"}>
        <Col className={"number-col"}>
          {t("market:pool_candidates_details_number_label", {number: poolCandidate.number})}
        </Col>
        <Col className={"icon-col"}>
          <Row className={"icon-row"}>
            <Col className={"viewed-col"}>
              <ViewedBadge show={poolCandidate.published}>{t("market:pool_candidates_details_published")}</ViewedBadge>
            </Col>
            <Col className={"delete-col"}>
              {<PoolCandidateDeleteButton poolCandidateId={poolCandidate._id}/>}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={"name-row"}>
        <Col className={"name-col"}>
          <ActionDiv onClick={() => onClick(poolCandidate._id)} className={"name"}>
            {poolCandidate.name}
          </ActionDiv>
        </Col>
      </Row>
      <Row className={"note-row"}>
        <Col className={"note-col"}>
          {/* To keep vertical alignment with other cards, force normal line height on empty note*/}
          <div className={"note"}>{note || <>&nbsp;</>}</div>
        </Col>
      </Row>
    </div>
  );
};
