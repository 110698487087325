import React from "react";
import "./ViewedBadge.scss";
import useTranslationCommon from "../useTranslationCommon";
import useShowLoader from "../loading-widgets/useShowLoader";

export default function ViewedBadge(props) {

  const {show, children} = props;

  const {t, loading: tLoading} = useTranslationCommon();
  useShowLoader(tLoading, "ViewedBadge");

  if (!show)
    return null;

  // Display content or provide a default label
  const content = children ? children : t("common:viewed_badge_label");

  return (
    <span className="ViewedBadge">
      {content}
    </span>
  );
};
