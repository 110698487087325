import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";

/**
 * Return the public URL of the report, accessible to clients (no login required)
 * @param reportId
 * @returns {string}
 */
export default function usePublicReportUrl(reportId) {
  return process.env.REACT_APP_PUBLIC_REPORT_URI + RouterUtils.injectParamsInRoute(ROUTES.PUBLIC_REPORT, {reportId});
};
