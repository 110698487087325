import React from "react";
import InlineEditWysiwyg from "../../../common/widgets/InlineEditWysiwyg";
import PoolCandidateInlineEditInput from "./PoolCandidateInlineEditInput";

/**
 * Generic component for all candidate editable wysiwyg fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PoolCandidateInlineEditInputWysiwyg(props) {
  return <PoolCandidateInlineEditInput InlineEditInputComponent={InlineEditWysiwyg} {...props}/>
};
