import React from "react";
import Page from "../../../common/page/Page";
import useAuthUser from "../../../authentication-no-ui/useAuthUser";
import ReportingSettingsBlock from "./ReportingSettingsBlock";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import reportingSettingsAction from "../actions";
import useTranslationReporting from "../../useTranslationReporting";

export default function ReportingSettingsPage(props) {

  const {t, loading: tLoading} = useTranslationReporting();
  const {profile} = useAuthUser();

  return (
    <WithRedirectionUserIsAuthorized action={reportingSettingsAction.REPORTING_SETTINGS_UPDATE} yes={() =>
      <Page className="ReportingSettingsPage">
        <Page.Title loading={tLoading}>{t("reporting:settings_title")}</Page.Title>
        <Page.Content className="spaced-children">
          <ReportingSettingsBlock profileId={profile._id}
                                  settings={profile.settings ? profile.settings.reporting : {}}/>
        </Page.Content>
      </Page>
    }/>
  );
}
