import * as FirebaseApp from "firebase/app";
import * as FirebaseAuth from "firebase/auth";

// Configure Firebase.
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, // required for authentication with Firebase UI
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, // Project ID is not required, API key implies it
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};

export default class Firebase {
  constructor() {
    try {
      // Initialize app only if none are already (causes an "already exists" error when hot-reloading)
      if (FirebaseApp.getApps().length === 0) {
        const app = FirebaseApp.initializeApp(config);
        this.auth = FirebaseAuth.getAuth(app);
      } else {
        this.auth = FirebaseAuth.getAuth(FirebaseApp.getApp());
      }
    } catch (err) {
      this.lastError = err;
    }
  }

  /**
   * Returns true if Firebase has been initialized successfully
   */
  isInitialized = () => {
    return !!this.auth;
  }

  /**
   * Sets the language used by Firebase to send emails and error messages
   * @param language Language code (supported codes: https://github.com/firebase/firebaseui-web/blob/master/LANGUAGES.md)
   */
  setLanguageCode = (language) => {
    this.auth.languageCode = language
  };

  /**
   * Create a user in the Firebase service and log the user in.
   * @param email
   * @param password
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  doCreateUserWithEmailAndPassword = (email, password) =>
    FirebaseAuth.createUserWithEmailAndPassword(this.auth, email, password);

  /**
   * Log the user in.
   * @param email
   * @param password
   * @returns {Promise<firebase.auth.UserCredential>}
   */
  doSignInWithEmailAndPassword = (email, password) =>
    FirebaseAuth.signInWithEmailAndPassword(this.auth, email.trim(), password);

  /**
   * Log the user out.
   * @returns {Promise<void>}
   */
  doSignOut = () => FirebaseAuth.signOut(this.auth);

  doPasswordReset = email => FirebaseAuth.sendPasswordResetEmail(this.auth, email);

  /**
   * Returns current Firebase user.
   * Do not use this to know if there is user currently logged because it will return null for a short time
   * after firebase app is initialized; use it only after onAuthStatusChanged event is fired
   * @returns {firebase.User}
   */
  getCurrentUser = () => this.auth.currentUser;

  onAuthStateChanged = (...params) => FirebaseAuth.onAuthStateChanged(this.auth, ...params);

  /**
   * Returns a translation key for a given Firebase error code. Error codes are listed in firebase-js-sdk project
   * on GitHub, file packages/auth/src/error_auth.js.
   * @param t Translation function
   * @param code Firebase error code
   * @return {string} Translation key
   * @public
   */
  static getErrorTranslation = (t, code) => {
      // The method receives a t function and repeats "authentication-no-ui:" in front of every translation key so that
      // the following keys are extracted when running scan-translations.js script (or "npm run scan-translations").
      // Without the t(), the keys wouldn't be recognized as translation keys.
      switch (code) {
          case "auth/email-already-in-use":
              return t("authentication-no-ui:firebase_auth_email_already_in_use_error");
          case "auth/invalid-email" :
              return t("authentication-no-ui:firebase_auth_invalid_email_error");
          case "auth/operation-not-allowed":
              return t("authentication-no-ui:firebase_auth_operation_not_allowed_error");
          case "auth/too-many-requests":
              return t("authentication-no-ui:firebase_auth_too_many_requests_error");
          case "auth/user-disabled" :
              return t("authentication-no-ui:firebase_auth_user_disabled_error");
          case "auth/user-not-found" :
              return t("authentication-no-ui:firebase_auth_user_not_found_error");
          case "auth/weak-password" :
              return t("authentication-no-ui:firebase_auth_weak_password_error");
          case "auth/wrong-password":
              return t("authentication-no-ui:firebase_auth_wrong_password_error");
          default:
              return t("authentication-no-ui:firebase_generic_error");
      }
  };
}
