import React from "react";
import InlineEditInputTypeahead from "../../../common/widgets/InlineEditInputTypeahead";
import JobInlineEditInput from "./JobInlineEditInput";

/**
 * Generic component for all Job editable select fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function JobInlineEditInputTypeahead(props) {

  return <JobInlineEditInput InlineEditInputComponent={InlineEditInputTypeahead} {...props}/>
};
