import React from "react";
import "./ClientStatsBlock.scss";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useQueryStatsForClient from "./useQueryStatsForClient";
import HtmlUtils from "../../../../utils/HtmlUtils";
import StringUtils from "../../../../utils/StringUtils";
import WaitForData from "../../../common/data-fetching/WaitForData";

export default function ClientStatsBlock(props) {

  const {clientId} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientStatsBlock");

  return (
    <div className="ClientStatsBlock">
      <Row>
        <Col className={"title"}>
          <h2>{t("reporting:clients_client_stats_title")}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <AsyncStats clientId={clientId}/>
        </Col>
      </Row>
    </div>
  );
};

function AsyncStats(props) {

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientStatsBlock");

  const {clientId} = props;

  const {data: stats, loading, errors} = useQueryStatsForClient(clientId);

  return (
    <WaitForData loading={loading} errors={errors} onLoaded={() =>
      <div className="AsyncStats">
        <Row>
          <Col>
            {HtmlUtils.htmlToComponents(t("reporting:clients_client_stats_job_count", {count: StringUtils.nullToZero(stats.jobCount)}))}
          </Col>
        </Row>
        <Row>
          <Col>
            {HtmlUtils.htmlToComponents(t("reporting:clients_client_stats_report_count", {count: StringUtils.nullToZero(stats.reportCount)}))}
          </Col>
        </Row>
        <Row>
          <Col>
            {HtmlUtils.htmlToComponents(t("reporting:clients_client_stats_candidate_count", {count: StringUtils.nullToZero(stats.candidateCount)}))}
          </Col>
        </Row>
      </div>
    }/>
  );
}
