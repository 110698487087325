import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import TalentPoolInlineEditInput from "./TalentPoolInlineEditInput";

/**
 * Generic component for all talentPool editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function TalentPoolInlineEditInputText(props) {

  return <TalentPoolInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
