import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_JOB_LAST_VIEWED} from "../view/useQueryJobLastViewed";

const QUERY_NAME = "jobViewUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $jobId: ID!,
    ) {
      ${QUERY_NAME} (
        jobId: $jobId,
      )
    }
  `;

export default function useMutationJobViewUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    // Beware that these queries won't fire if their React component is not mounted
    refetchQueries: [QUERY_JOB_LAST_VIEWED]
  });
}
