import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "poolCandidateById";

export const QUERY = gql`
  query ${QUERY_NAME}($id: ID!) {
    ${QUERY_NAME}(id: $id) {
      _id
      number
      name
      jobCategoryIds
      contactLines
      note
      published
      resumeFile {
        filename
      }
    }
  }
`;

export default function useQueryPoolCandidateById(id) {

  const variables = {id};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response.
    // Do not rely on cache only, so that we see modifications to the questionnaire every time we fetch the candidate
    fetchPolicy: "cache-and-network"
  });
}
