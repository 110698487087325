import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import clientsActions from "../actions";
import Page from "../../../common/page/Page";
import useTranslationReporting from "../../useTranslationReporting";
import ClientsBlock from "./ClientsBlock";

export default function ClientsPage(props) {

  const {t, loading: tLoading} = useTranslationReporting();

  return (
    <WithRedirectionUserIsAuthorized action={clientsActions.CLIENTS_VIEW_ALL} yes={() =>
      <Page className="ClientsPage">
        <Page.Title loading={tLoading}>{t("reporting:clients_all_clients_title")}</Page.Title>
        <Page.Content>
          <ClientsBlock/>
        </Page.Content>
      </Page>
    }/>
  );

}
