export default class InlineEditSubmitStatus {
  static READY = new InlineEditSubmitStatus("READY")
  static SUBMITTING = new InlineEditSubmitStatus("SUBMITTING")
  static SUCCESS = new InlineEditSubmitStatus("SUCCESS")
  static FAILURE = new InlineEditSubmitStatus("FAILURE")

  constructor(name) {
    this.name = name
  }
}
