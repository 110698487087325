import React from "react";
import JobInlineEditTitle from "../mutate/JobInlineEditTitle";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import ActionLink from "../../../common/widgets/ActionLink";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import CollapseIcon from "@mui/icons-material/ExpandLess";
import JobDetails from "./JobDetails";
import ReportSearchBox from "../../reports/search/ReportSearchBox";
import LastViewedReports from "../../reports/view/LastViewedReports";
import useContentModal from "../../../common/modals/useContentModal";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import ReportCreateForm from "../../reports/mutate/ReportCreateForm";
import {useNavigate} from "react-router-dom";
import DetailsCollapsibleSection from "../../common/DetailsCollapsibleSection";
import "./JobBlock.scss";
import ActionIcon from "../../../common/widgets/ActionIcon";
import BackIcon from "@mui/icons-material/KeyboardBackspace";

export default function JobBlock(props) {

  const {job} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "JobBlock");
  // Modal to show report creation form
  const {ContentModal: ReportCreateModal, show: showReportCreateModal, hide: hideReportCreateModal} = useContentModal();
  const [showDetails, setShowDetails] = React.useState(false);
  const navigate = useNavigate();

  const goBack = () => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.CLIENT, {clientId: job.clientId}));
  }

  const onClickDetails = event => {
    event.preventDefault();
    setShowDetails(prevState => !prevState);
  }

  const onClickCreate = () => {
    showReportCreateModal(reportCreateForm);
  }

  const onReportCreated = (report) => {
    hideReportCreateModal();
    navigate(RouterUtils.injectParamsInRoute(ROUTES.REPORT, {
      clientId: job.clientId,
      jobId: job._id,
      reportId: report._id
    }));
  };

  const reportCreateForm = <ReportCreateForm jobId={job._id} onAbort={hideReportCreateModal} onSuccess={onReportCreated}/>;

  const DetailsIcon = showDetails ? CollapseIcon : ExpandIcon;

  return (
    <>{ReportCreateModal}
      <div className="JobBlock">
        <Row className={"title-row"}>
          <Col className={"back-button-container"}>
            <div className={"back-button"}>
              <ActionIcon onClick={goBack} icon={BackIcon}>{t("reporting:go_back_action")}</ActionIcon>
            </div>
            <JobInlineEditTitle job={job} viewAs="h1"/>
          </Col>
          <Col className={"details-col"}>
            <ActionLink onClick={onClickDetails}>{t("reporting:jobs_details_view_action")} <DetailsIcon/></ActionLink>
          </Col>
        </Row>
        <DetailsCollapsibleSection isOpened={showDetails}>
          <JobDetails job={job}/>
        </DetailsCollapsibleSection>
        <Row className={"search-block-row"}>
          <Col>
            <Row>
              <Col>
                <ReportSearchBox autofocus jobId={job._id} clientId={job.clientId}/>
              </Col>
            </Row>
            <Row className={"create-link-row"}>
              <Col>
                <ActionLink onClick={onClickCreate}>{t("reporting:reports_create_action")}</ActionLink>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="list-row">
          <Col>
            <LastViewedReports jobId={job._id}/>
          </Col>
        </Row>
      </div>
    </>
  );
};
