import React from "react";
import "./InlineEditInputBoolean.scss";
import Switch from "react-switch";

/**
 * Input box that display a boolean as a switch.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function InlineEditInputBoolean(props) {

  const {value: initialValue, onSubmit, className, placeholder, disabled} = props;

  // Keep track of the value that is submitted for modification, so that we can display it until the modification is
  // successful and we get a new initial value (that may come from a cache update after a modification)
  const [value, setValue] = React.useState(initialValue);

  // Convert value to boolean in case it is an empty string
  const booleanValue = Boolean(value);

  // A re-render of the component with another initial value must reset the working value
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);


  const onLocalSubmit = () => {
    const newValue = !value;

    // Remember the submitted value until the modification callback updates the cache and re-render the component with
    // the updated value
    setValue(newValue);

    // Perform the modification callback
    onSubmit(newValue)
      .catch(error => {
        // Put back old value in case of an error
        setValue(value);
      });
  }

  return (
    <div className={["InlineEditInputBoolean", className].join(" ")}>
      <div className={"label"}>{placeholder}</div>
      <div className={"action"}>
        <Switch disabled={disabled} onChange={onLocalSubmit} checked={booleanValue} height={24}/>
      </div>
    </div>
  );
};
