import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";
import {useNavigate} from "react-router-dom";
import "./ReportSearchResult.scss";
import useQueryJobById from "../../jobs/view/useQueryJobById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import ActionButton from "../../../common/widgets/ActionButton";
import CandidateByReportCount from "./CandidateByReportCount"
import ViewedBadge from "../../../common/widgets/ViewedBadge";

export default function ReportSearchResult(props) {

  const {item: report} = props;

  const navigate = useNavigate();

  const {data: job, loading: jobLoading, errors: jobErrors} = useQueryJobById(report.jobId);

  function onClickReport(event, reportId, clientId, jobId) {
    event.preventDefault();
    navigate(RouterUtils.injectParamsInRoute(ROUTES.REPORT, {reportId, clientId, jobId}))
  }

  return (
    <WaitForData loading={jobLoading} errors={jobErrors} onLoaded={() =>
      <ActionButton as={"div"} variant="outline-primary" onClick={event => onClickReport(event, report._id, job.clientId, report.jobId)} className={"ReportSearchResult"}>
        <Row>
          <Col className={"name-col"}>
            {report.name}
          </Col>
          <Col className={"viewed-col"}>
            <ViewedBadge show={report.viewed}/>
          </Col>
          <Col className={"report-col"}>
            <CandidateByReportCount reportId={report._id}/>
          </Col>
        </Row>
      </ActionButton>
    }/>
  );
}
