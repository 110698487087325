import React from "react";
import WithRedirectionUserIsAuthorized from "../../../authentication-ui/WithRedirectionUserIsAuthorized";
import jobsActions from "../actions";
import Page from "../../../common/page/Page";
import {useParams} from "react-router";
import useQueryJobById from "./useQueryJobById";
import WaitForData from "../../../common/data-fetching/WaitForData";
import JobBlock from "./JobBlock";
import useMutationJobViewUpdate from "../mutate/useMutationJobViewUpdate";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

export default function JobPage(props) {

  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const jobId = urlParams.jobId;
  const {data: job, loading: jobLoading, errors: jobErrors} = useQueryJobById(jobId);
  // We'll touch the job view silently in the background, no need to track loading state or error
  const {mutate} = useMutationJobViewUpdate();
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "JobPage");

  // Touch the job silently every time the page renders with a new jobId or the user has performed an action to
  // change the job.
  React.useEffect(() => {
    if (job) {
      mutate({
        variables: {
          jobId: job._id
        }
      }).catch(err => {/* fail silently */});
    }
  }, [job, mutate]);

  return (
    <WithRedirectionUserIsAuthorized action={jobsActions.JOBS_VIEW} yes={() =>
      <WaitForData loading={jobLoading} errors={jobErrors} onLoaded={() =>
        <>
          {job &&
          <Page className="JobPage">
            <Page.Title loading={jobLoading} text={job.title}/>
            <Page.Content className="spaced-children"><JobBlock job={job}/></Page.Content>
          </Page>
          }
          {!job &&
          <Page className="JobPage">
            <Page.Title loading={jobLoading}>{t("reporting:jobs_details_not_found")}</Page.Title>
          </Page>
          }
        </>
      }/>
    }/>
  );
}
