import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import CandidateInlineEditInputText from "./CandidateInlineEditInputText";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useQueryCandidateById from "../view/useQueryCandidateById";
import StringUtils from "../../../../utils/StringUtils";
import CandidateInlineEditInputTextArray from "./CandidateInlineEditInputTextArray";
import ActionLink from "../../../common/widgets/ActionLink";
import useContentModal from "../../../common/modals/useContentModal";
import CandidateResumeDownloadLink from "../view/CandidateResumeDownloadLink";
import "./CandidateUpdateForm.scss";
import ResumeImage from "../../../../img/resume-download.png";
import Image from "react-bootstrap/Image";
import ActionButtonWithIconCancel from "../../../common/widgets/ActionButtonWithIconCancel";
import CandidateInlineEditInputWysiwyg from "./CandidateInlineEditInputWysiwyg";
import ViewedBadge from "../../../common/widgets/ViewedBadge";
import CandidateQuestionnaire from "./CandidateQuestionnaire";
import CandidateResumeUpdate from "./CandidateResumeUpdate";
import CandidateResumeDeleteLink from "./CandidateResumeDeleteLink";

export default function CandidateUpdateForm(props) {

  const {candidateId, onClose} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "CandidateUpdateForm");

  // Modal to show resume upload form
  const {ContentModal: CandidateResumeUpdateModal, show: showCandidateResumeUpdateModal, hide: hideCandidateResumeUpdateModal} = useContentModal();

  const {data: candidate, loading: candidateLoading, errors: candidateErrors} = useQueryCandidateById(candidateId);

  const hasResume = candidate && candidate.resumeFile && !StringUtils.isNullOrEmpty(candidate.resumeFile.filename);
  const viewAction = t("reporting:candidates_details_resume_view_action");
  const modifyAction = t("reporting:candidates_details_resume_modify_action");
  const deleteAction = t("reporting:candidates_details_resume_delete_action");

  const validateName = (name) => {
    if (StringUtils.isNullOrEmpty(name))
      return t("reporting:candidates_details_name_required_error");
  }

  const onClickUpdateResume = () => {
    showCandidateResumeUpdateModal(<CandidateResumeUpdate candidateId={candidateId} onSuccess={hideCandidateResumeUpdateModal} onAbort={hideCandidateResumeUpdateModal}/>);
  }

  return (
    <>
      {CandidateResumeUpdateModal}
      <WaitForData loading={candidateLoading} errors={candidateErrors} onLoaded={() =>
        <div className={"CandidateUpdateForm form-with-rows"}>
          <Row className={"first-row"}>
            <Col className="candidate-col">
              <Row className={"name-row"}>
                <Col className="name-col">
                  <CandidateInlineEditInputText
                    candidate={candidate}
                    validate={validateName}
                    placeholder={t("reporting:candidates_details_name_placeholder")}
                    property={"name"}
                    viewAs={"h1"}
                  />
                </Col>
                <Col className={"viewed-col"}>
                  <ViewedBadge show={candidate.viewed}/>
                </Col>
              </Row>
              <Row className={"contact-row"}>
                <Col className={"contact-col"}>
                  <CandidateInlineEditInputTextArray candidate={candidate} property={"contactLines"} placeholder={t("reporting:candidates_details_contacts_placeholder")}/>
                </Col>
              </Row>
            </Col>
            <Col className="resume-col">
              <Row className={"resume-row"}>
                <Col className={"resume-actions-col"}>
                  <Row>
                    <Col>
                      {/* Do not disable link, contrast with background is not high enough */}
                      {!hasResume && viewAction}
                      {hasResume && <CandidateResumeDownloadLink candidateId={candidate._id}>{viewAction}</CandidateResumeDownloadLink>}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ActionLink onClick={onClickUpdateResume}>{modifyAction}</ActionLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {/* Do not disable link, contrast with background is not high enough */}
                      {!hasResume && deleteAction}
                      {hasResume && <CandidateResumeDeleteLink candidateId={candidate._id}>{deleteAction}</CandidateResumeDeleteLink>}
                    </Col>
                  </Row>
                </Col>
                <Col className={"resume-icon-col"}>
                  <Image src={ResumeImage}/>
                </Col>
              </Row>
              {candidate.resumeViewed &&
              <Row>
                <Col className={"resume-viewed-col"}>
                  <ViewedBadge show={candidate.resumeViewed}>{t("reporting:candidates_resume_viewed")}</ViewedBadge>
                </Col>
              </Row>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <CandidateInlineEditInputWysiwyg
                className={"note-input"}
                candidate={candidate}
                placeholder={t("reporting:candidates_details_note_placeholder")}
                property={"note"}
              />
            </Col>
          </Row>
          {candidate.answers &&
          <Row>
            <Col>
              <CandidateQuestionnaire candidate={candidate}/>
            </Col>
          </Row>
          }
          <Row>
            <Col className="form-actions">
              <ActionButtonWithIconCancel onClick={onClose}>{t("reporting:candidates_details_close_action")}</ActionButtonWithIconCancel>
            </Col>
          </Row>
        </div>
      }/>
    </>
  )
}
