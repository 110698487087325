import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "questionnaireUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $id: ID!,
      $name: String,
      $questionIds: [ID]
    ) {
      ${QUERY_NAME} (
        id: $id,
        name: $name,
        questionIds: $questionIds,
      ) {
        _id
        name
        questions {
          _id
        }
      }
    }
  `;

export default function useMutationQuestionnaireUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
