import React from "react";
import useFirebaseAuth from "./useFirebaseAuth";
import {createApolloClient, stopClient} from "../infra-no-ui/apollo/ApolloHelper";
import usePreviousState from "../infra-no-ui/react-helpers/usePreviousState";
import {ApolloProvider} from "@apollo/client";

/**
 * Provide an Apollo client with ApolloProvider that adds an "Authorization" header to every request.
 * The authorization token is the token ID of the user as given by Firebase.
 */
export default function AuthenticatedApolloProvider(props) {

  const {children, cacheManager} = props;

  // Get current user's email
  const firebaseAuth = useFirebaseAuth();
  const userEmail = firebaseAuth.user ? firebaseAuth.user.email : null;
  const previousEmail = usePreviousState(userEmail);

  // Use a ref to access the current value of the token when executing the setContext callback on every request
  // (if we use state variables, the setContext function will be "frozen" to the values at the time the useEffect is set).
  // Set the token on every render, before queries inside the render tree are fired, so that they have the correct header when fired
  const tokenRef = React.useRef(null);
  tokenRef.current = firebaseAuth.idTokenResult ? firebaseAuth.idTokenResult.token : null;

  const createApolloClientWithCache = () => {
    return createApolloClient(tokenRef, cacheManager);
  }

  // Use a ref to the Apollo client instead of useState so that we can initialize it before the first rendering of children
  // (we cannot pass a null client to ApolloProvider)
  const apolloClient = React.useRef(createApolloClientWithCache());

  // If user email changed, it means user signed in or signed out. Create a new apollo client so that cache is cleared
  // (it is easier to manage this way than firing the cache clearing promise).
  // Don't do this in an effect, client must be set before the children are rendered (because children may fire queries)
  // There is no need to create a new apollo client when user token changes, headers are computed on every request.
  if (userEmail !== previousEmail) {
    stopClient(apolloClient.current);
    apolloClient.current = createApolloClientWithCache();
  }

  // RENDER

  return (
    <ApolloProvider client={apolloClient.current}>
      {children}
    </ApolloProvider>
  );
}
