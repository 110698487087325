import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_JOB_LAST_VIEWED} from "../view/useQueryJobLastViewed";

const QUERY_NAME = "jobCreate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $title: String!,
      $clientId: ID!
    ) {
      ${QUERY_NAME} (
        title: $title,
        clientId: $clientId
      ) {
        _id
        title
      }
    }
  `;

export default function useMutationJobCreate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_JOB_LAST_VIEWED]
  });
}
