import React from "react";
import InlineEditInputBoolean from "../../../common/widgets/InlineEditInputBoolean";
import PoolCandidateInlineEditInput from "./PoolCandidateInlineEditInput";

/**
 * Generic component for all poolCandidate editable boolean fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PoolCandidateInlineEditInputBoolean(props) {

  return <PoolCandidateInlineEditInput InlineEditInputComponent={InlineEditInputBoolean} {...props}/>
};
