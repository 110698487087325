import React from 'react';
import useQueryCandidateSearchCount from "../../candidates/search/useQueryCandidateSearchCount";
import StringUtils from "../../../../utils/StringUtils";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import HorizontalLoader from "../../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import "./CandidateByReportCount.scss";

function CandidateByReportCount(props) {

  const {reportId} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "CandidateByReportCount");


  const {data: count, loading, errors} = useQueryCandidateSearchCount({"reportId": reportId});

  return (
    <HorizontalLoader>
      <WaitForData loading={loading} errors={errors}
        onError={() =>
          <ReportProblemOutlined className="LoadingError"/>
        }
        onLoaded={() =>
          // t can use the _plural key because we provide a "count" and a number
          t("reporting:reports_search_candidate_count", {
            count: StringUtils.nullToZero(count)
          })
        }
      />
    </HorizontalLoader>
  );
}

export default CandidateByReportCount;
