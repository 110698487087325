import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import TypeUtils from "../../../../utils/TypeUtils";
import WaitForData from "../../../common/data-fetching/WaitForData";
import useContentModal from "../../../common/modals/useContentModal";
import PoolJobListItem from "./PoolJobListItem";
import PoolJobPreview from "./PoolJobPreview";
import PoolJobAdd from "./PoolJobAdd";
import "./PoolJobList.scss";
import useQueryPoolJobsByTalentPoolId from "./useQueryPoolJobsByTalentPoolId";
import PoolJobCreateForm from "../mutate/PoolJobCreateForm";
import {useNavigate} from "react-router-dom";
import RouterUtils from "../../../../utils/RouterUtils";
import * as ROUTES from "../../../../constants/routes";

export default function PoolJobList(props) {

  const {talentPoolId} = props;

  const navigate = useNavigate();

  // Modal to show job creation form
  const {ContentModal: PoolJobCreateModal, show: showPoolJobCreateModal, hide: hidePoolJobCreateModal} = useContentModal();

  const {data: poolJobs, loading: poolJobsLoading, errors: poolJobsErrors} = useQueryPoolJobsByTalentPoolId(talentPoolId);
  // Remove null poolJobs. This can happen after a deletion, before the list is updated
  const nonNullPoolJobs = poolJobs ? TypeUtils.ensureArray(poolJobs.nodes).filter(node => node) : [];

  const goToPoolJobDetails = (poolJobId) => {
    navigate(RouterUtils.injectParamsInRoute(ROUTES.POOL_JOB, {talentPoolId, poolJobId}))
  }

  const onClickPoolJob = (poolJobId) => {
    goToPoolJobDetails(poolJobId);
  }

  const onClickAddPoolJob = () => {
    const poolJobCreateForm = <PoolJobCreateForm onAbort={hidePoolJobCreateModal} onSuccess={onPoolJobCreated} talentPoolId={talentPoolId}/>;
    showPoolJobCreateModal(poolJobCreateForm);
  }

  const onPoolJobCreated = (poolJob) => {
    hidePoolJobCreateModal();
    goToPoolJobDetails(poolJob._id)
  }

  return (
    <>
      {PoolJobCreateModal}
      <div className="PoolJobList">
        <WaitForData loading={poolJobsLoading} errors={poolJobsErrors} onLoaded={() =>
          <Row className={"poolJobs-row"}>
            {nonNullPoolJobs.map(poolJob =>
              <Col key={poolJob._id} className={"poolJob-col"}>
                <PoolJobListItem>
                  <PoolJobPreview poolJob={poolJob} onClick={() => onClickPoolJob(poolJob._id)}/>
                </PoolJobListItem>
              </Col>
            )}
            <Col className={"poolJob-col"}>
              <PoolJobListItem>
                <PoolJobAdd onClick={onClickAddPoolJob}/>
              </PoolJobListItem>
            </Col>
          </Row>
        }/>
      </div>
    </>
  );
};
