import React from "react";
import ActionDiv from "../../../common/widgets/ActionDiv";
import "./TalentPoolAdd.scss";
import {Image} from "react-bootstrap";
import AddImage from "../../../../img/add.png";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";

export default function TalentPoolAdd(props) {

  const {onClick} = props;

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolAdd");

  return (
    <div className="TalentPoolAdd">
      <ActionDiv className="action" onClick={onClick}>
        <Image src={AddImage} alt={t("market:talent_pools_create_action")}/>
      </ActionDiv>
    </div>
  );
};
