import React from "react";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useMutationJobCategoryDelete from "./useMutationJobCategoryDelete";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";
import ActionIcon from "../../../common/widgets/ActionIcon";
import DeleteIcon from "@mui/icons-material/HighlightOff";
import useTranslationMarket from "../../useTranslationMarket";

export default function JobCategoryDeleteButton(props) {

  const {jobCategoryId, onCompleted} = props;

  const deleteJobCategory = () => {
    return mutate({
      variables: {
        id: jobCategoryId,
      },
    });
  }

  const onClickDelete = (event) => {
    event.preventDefault();
    launch(deleteJobCategory, onCompleted);
  }

  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "JobCategoryDeleteButton");

  const {mutate, loading: deleteLoading, errors: deleteErrors} = useMutationJobCategoryDelete();

  // Page refreshes rapidly after deletion, the success msg just creates a flicker, so don't display feedback
  //const successMsg = <p>{t("market:job_categories_delete_success_msg")}</p>;
  const successMsg = undefined;

  const errorMsg = useServerErrorFormatter(deleteErrors);
  const confirmMsg = <p>{t("market:job_categories_delete_confirm_msg")}</p>
  const {launch, FeedbackModal} = useFeedbackModal({successMsg, errorMsg, confirmMsg});

  return (
    <>
      {FeedbackModal}
      <ActionIcon onClick={onClickDelete} loading={deleteLoading} icon={DeleteIcon}>{t("market:job_categories_details_delete_action")}</ActionIcon>
    </>
  );
}
