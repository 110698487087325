import React from "react";
import InlineEditWysiwyg from "../../../common/widgets/InlineEditWysiwyg";
import ReportInlineEditInput from "./ReportInlineEditInput";

/**
 * Generic component for all report editable wysiwyg fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportInlineEditInputWysiwyg(props) {
  return <ReportInlineEditInput InlineEditInputComponent={InlineEditWysiwyg} {...props}/>
};
