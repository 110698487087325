import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ReportSearchResult from "../search/ReportSearchResult";
import useQueryReportLastViewed from "./useQueryReportLastViewed";
import LastViewedList from "../../common/LastViewedList";

const LIST_LENGTH = 20;

export default function LastViewedReports(props) {

  const {jobId} = props;

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "LastViewedReports");

  const {data: items, loading, errors} = useQueryReportLastViewed(LIST_LENGTH, jobId);

  const listTitle = t("reporting:reports_last_viewed_title");
  const emptyMsg = t("reporting:reports_search_empty_msg");
  const getItemId = (report) => report._id;

  return (
    <LastViewedList
      items = {items}
      loading = {loading}
      errors = {errors}
      className = {"LastViewedReports"}
      listTitle = {listTitle}
      emptyMsg = {emptyMsg}
      getItemId = {getItemId}
      SearchResult = {<ReportSearchResult/>}
    />
  );
};
