import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import QuestionnaireInlineEditInput from "./QuestionnaireInlineEditInput";

/**
 * Generic component for all questionnaire editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function QuestionnaireInlineEditInputText(props) {

  return <QuestionnaireInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
