import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "candidateUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $id: ID!,
      $name: String,
      $contactLines: [String],
      $note: String
    ) {
      ${QUERY_NAME} (
        id: $id,
        name: $name,
        contactLines: $contactLines
        note: $note
      ) {
        _id
        name
        contactLines
        note
      }
    }
  `;

export default function useMutationCandidateUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
