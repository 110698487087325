import React from "react";
import StringUtils from "../../../../utils/StringUtils";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import ClientInlineEditInputText from "./ClientInlineEditInputText";

export default function ClientInlineEditName(props) {

  const {client, viewAs} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ClientInlineEditName");

  const validateName = React.useCallback(value => {
    if (StringUtils.isNullOrEmpty(value))
      return t("reporting:clients_details_name_required_error");
  }, [t]);

  return (
    <ClientInlineEditInputText
      client={client}
      placeholder={t("reporting:clients_details_name_placeholder")}
      property={"name"}
      validate={validateName}
      viewAs={viewAs}
      className="ClientInlineEditName"
    />
  );
};
