import React from "react";
import ResumeDropbox from "./ResumeDropbox";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationCommon from "../../common/useTranslationCommon";
import useShowLoader from "../loading-widgets/useShowLoader";
import ActionButtonWithIconCancel from "../widgets/ActionButtonWithIconCancel";

export default function ResumeUpdate(props) {

  const {candidateId, onUploadResume, loading, errors, onSuccess, onAbort} = props;
  const {t, loading: tLoading} = useTranslationCommon();
  useShowLoader(tLoading, "ResumeUpdate");

  return (
    <div className="ResumeUpdate form-with-rows">
      <Row>
        <Col>
          <h1>{t("common:candidates_resume_upload_title")}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <ResumeDropbox candidateId={candidateId} onUploadResume={onUploadResume} loading={loading} errors={errors} onSuccess={onSuccess}/>
        </Col>
      </Row>
      <Row>
        <Col className={"form-actions"}>
          <ActionButtonWithIconCancel onClick={onAbort}>{t("common:candidates_resume_upload_cancel_action")}</ActionButtonWithIconCancel>
        </Col>
      </Row>
    </div>
  );
};
