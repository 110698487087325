import React from "react";
import useMutationCandidateUploadResume from "./useMutationCandidateUploadResume";
import ResumeUpdate from "../../../common/resume-upload/ResumeUpdate";

export default function CandidateResumeUpdate(props) {

  const {candidateId, onSuccess, onAbort} = props;

  const {mutate, loading, errors} = useMutationCandidateUploadResume();

  const onUploadResume = (candidateId, file) => {
    return mutate({
      variables: {
        id: candidateId,
        file: file,
      }
    });
  }

  return (
    <ResumeUpdate candidateId={candidateId} loading={loading} errors={errors} onSuccess={onSuccess} onUploadResume={onUploadResume} onAbort={onAbort}/>
  );
};
