import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useMutationCandidateDelete from "./useMutationCandidateDelete";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import useFeedbackModal from "../../../common/modals/useFeedbackModal";
import ActionIcon from "../../../common/widgets/ActionIcon";
import DeleteIcon from "@mui/icons-material/HighlightOff";

export default function CandidateDeleteButton(props) {

  const {candidateId, onCompleted} = props;

  const deleteCandidate = () => {
    return mutate({
      variables: {
        id: candidateId,
      },
    });
  }

  const onClickDelete = (event) => {
    event.preventDefault();
    launch(deleteCandidate, onCompleted);
  }

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "CandidateDeleteButton");

  const {mutate, loading: deleteLoading, errors: deleteErrors} = useMutationCandidateDelete();

  const successMsg = <p>{t("reporting:candidates_delete_success_msg")}</p>;
  const errorMsg = useServerErrorFormatter(deleteErrors);
  const confirmMsg = <p>{t("reporting:candidates_delete_confirm_msg")}</p>
  const {launch, FeedbackModal} = useFeedbackModal({successMsg, errorMsg, confirmMsg});

  return (
    <>
      {FeedbackModal}
      <ActionIcon onClick={onClickDelete} loading={deleteLoading} icon={DeleteIcon}>{t("reporting:candidates_details_delete_action")}</ActionIcon>
    </>
  );
}
