import React from "react";
import * as ROUTES from "../../constants/routes";
import Nav from "react-bootstrap/Nav";
import "./Menu.scss";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import isUserAuthorized from "../authentication-no-ui/isUserAuthorized";
import {LanguageMenuItem, MenuItem} from "./MenuComponents";
import useTranslationMenu from "./useTranslationMenu";

export default function Menu() {

  const {t, loading: tLoading} = useTranslationMenu();
  const authUser = useAuthUser();

  // Don't use useShowLoader because there is no loader to display over this component; just wait until there is something to display.
  if (tLoading) {
    return null;
  }

  return (
    <Nav className="Menu">

      {isUserAuthorized(authUser) && <MenuItem key="menu_item_sign_out" to={ROUTES.SIGN_OUT}>{t("menu:menu_item_sign_out")}</MenuItem>}

      <LanguageMenuItem/>

    </Nav>
  );
}
