import React from "react";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useQueryQuestionnaireLastViewed from "./useQueryQuestionnaireLastViewed";
import LastViewedList from "../../common/LastViewedList";
import QuestionnaireSearchResult from "../search/QuestionnaireSearchResult";

const LIST_LENGTH = 20;

export default function LastViewedQuestionnaires(/*props*/) {

  const {data: items, loading, errors} = useQueryQuestionnaireLastViewed(LIST_LENGTH);
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "LastViewedQuestionnaires");

  const listTitle = t("reporting:questionnaires_last_viewed_title");
  const emptyMsg = t("reporting:questionnaires_search_empty_msg");
  const getItemId = (questionnaire) => questionnaire._id;

  return (
    <LastViewedList
      items = {items}
      loading = {loading}
      errors = {errors}
      className = {"LastViewedQuestionnaires"}
      listTitle = {listTitle}
      emptyMsg = {emptyMsg}
      getItemId = {getItemId}
      SearchResult = {<QuestionnaireSearchResult/>}
    />
  )
};
