import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_CANDIDATE_SEARCH} from "../view/useQueryCandidatesByReportId";

const QUERY_NAME = "candidateDelete";

export const QUERY = gql`
    mutation ${QUERY_NAME} ($id: ID!) {
      ${QUERY_NAME} (id: $id) {
        _id
      }
    }
  `;

export default function useMutationCandidateDelete() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_CANDIDATE_SEARCH]
  });
}
