import React from "react";
import StringUtils from "../../../../utils/StringUtils";
import useTranslationMarket from "../../useTranslationMarket";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import TalentPoolInlineEditInputText from "./TalentPoolInlineEditInputText";

export default function TalentPoolInlineEditName(props) {

  const {talentPool, viewAs} = props;
  const {t, loading: tLoading} = useTranslationMarket();
  useShowLoader(tLoading, "TalentPoolInlineEditName");

  const validateName = React.useCallback(value => {
    if (StringUtils.isNullOrEmpty(value))
      return t("market:talent_pools_details_name_required_error");
  }, [t]);

  return (
    <TalentPoolInlineEditInputText
      talentPool={talentPool}
      placeholder={t("market:talent_pools_details_name_placeholder")}
      property={"name"}
      validate={validateName}
      viewAs={viewAs}
      className="TalentPoolInlineEditName"
    />
  );
}
