import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import PoolJobInlineEditInput from "./PoolJobInlineEditInput";

/**
 * Generic component for all pool job editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function PoolJobInlineEditInputText(props) {

  return <PoolJobInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
