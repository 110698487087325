import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import * as ROUTES from "../../constants/routes";
import useTranslationSidebar from "./useTranslationSidebar";
import {MenuItem} from "../menu/MenuComponents";
import "./Sidebar.scss";
import useAuthUser from "../authentication-no-ui/useAuthUser";

function Sidebar(/*props*/) {

  const {t, loading: tLoading} = useTranslationSidebar();
  const {authenticated} = useAuthUser();

  // Don't use useShowLoader because there is no loader to display over this component; just wait until there is something to display.
  if (tLoading) {
    return null;
  }

  return (
    <div className="Sidebar double-spaced-rows">
      {authenticated &&
        <>
          <Row>
            <Col>
              <div className={"simple-spaced-rows"}>
                <Row>
                  <Col className={"title-cell"}>
                    {t("sidebar:sidebar_sourcing_title")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MenuItem to={ROUTES.REPORTS}>{t("sidebar:sidebar_item_reports")}</MenuItem>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MenuItem to={ROUTES.QUESTIONNAIRES}>{t("sidebar:sidebar_item_questionnaires")}</MenuItem>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MenuItem to={ROUTES.SETTINGS}>{t("sidebar:sidebar_item_settings")}</MenuItem>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr className={"inter-section-separator"}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={"simple-spaced-rows"}>
                <Row>
                  <Col className={"title-cell"}>
                    {t("sidebar:sidebar_pool_title")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <MenuItem to={ROUTES.POOLS}>{t("sidebar:sidebar_item_pool_report")}</MenuItem>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <hr className={"inter-section-separator"}/>
            </Col>
          </Row>
        </>
      }
      <Row>
        <Col>
          <MenuItem to={ROUTES.HELP}>{t("sidebar:sidebar_item_help")}</MenuItem>
        </Col>
      </Row>
    </div>
  );
}

export default Sidebar;
