import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "candidateUploadResume";

export const QUERY = gql`
  mutation ${QUERY_NAME} (
  $id: ID!
  $file: Upload
  ) {
    ${QUERY_NAME} (
    id: $id
    file: $file
    ) {
    _id
    resumeFile {
      filename
    }
  }
  }
`;

export default function useMutationCandidateUploadResume() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
