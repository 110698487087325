import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "questionnaireAddQuestion";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
    $questionnaireId: ID!
    $text: String!
    ) {
      ${QUERY_NAME} (
      questionnaireId: $questionnaireId
      text: $text
      ) {
        _id
        questionCount
        questions {
          _id
          text
          questionnaireId
          mandatory
        }
      }
    }
  `;

export default function useMutationQuestionnaireAddQuestion(options) {

  return useWrappedMutation(QUERY, "", QUERY_NAME, options);
}
