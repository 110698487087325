import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import QuestionInlineEditInput from "./QuestionInlineEditInput";

/**
 * Generic component for all Question editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function QuestionInlineEditInputText(props) {

  return <QuestionInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
