import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "poolJobUpdate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $id: ID!,
      $name: String,
    ) {
      ${QUERY_NAME} (
        id: $id,
        name: $name,
      ) {
        _id
        name
      }
    }
  `;

export default function useMutationJobUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
