import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";
import {QUERY_NAME as QUERY_POOL_JOB_SEARCH} from "../view/useQueryPoolJobsByTalentPoolId";

const QUERY_NAME = "poolJobCreate";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $name: String!,
      $talentPoolId: ID!
    ) {
      ${QUERY_NAME} (
        name: $name,
        talentPoolId: $talentPoolId
      ) {
        _id
        name
        talentPoolId
      }
    }
  `;

export default function useMutationPoolJobCreate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME, {
    refetchQueries: [QUERY_POOL_JOB_SEARCH]
  });
}
