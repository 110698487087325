import React from "react";
import useMutationCandidateDeleteResume from "./useMutationCandidateDeleteResume";
import ResumeDeleteLink from "../../../common/resume-upload/ResumeDeleteLink";

export default function CandidateResumeDeleteLink(props) {

  const {candidateId, onCompleted, disabled, children} = props;

  const onDeleteResume = () => {
    return mutate({
      variables: {
        id: candidateId,
      },
    });
  }

  const {mutate, loading, errors} = useMutationCandidateDeleteResume();

  return (<ResumeDeleteLink onDeleteResume={onDeleteResume} loading={loading} errors={errors} onCompleted={onCompleted} disabled={disabled} children={children}/>);
}
