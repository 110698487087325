import React from 'react';
import StringUtils from "../../../../utils/StringUtils";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import HorizontalLoader from "../../../common/loading-widgets/HorizontalLoader";
import WaitForData from "../../../common/data-fetching/WaitForData";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import "./QuestionByQuestionnaireCount.scss";
import useQueryQuestionnaireQuestionCount from "../../questionnaires/view/useQueryQuestionnaireQuestionCount";

function QuestionByQuestionnaireCount(props) {

  const {questionnaireId} = props;
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "QuestionByQuestionnaireCount");

  const {data: count, loading, errors} = useQueryQuestionnaireQuestionCount(questionnaireId);

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() =>
          <ReportProblemOutlined className="LoadingError"/>
        }
        onLoaded={() =>
          // t can use the _plural key because we provide a "count" and a number
          t("reporting:questionnaires_search_question_count", {
            count: StringUtils.nullToZero(count)
          })
        }
      />
    </HorizontalLoader>
  );
}

export default QuestionByQuestionnaireCount;
