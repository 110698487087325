import {gql} from "@apollo/client";
import useWrappedMutation from "../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "profileAdd";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $firstName: String,
      $lastName: String,
      $email: String!
    ) {
      ${QUERY_NAME} (
        firstName: $firstName,
        lastName: $lastName,
        email: $email
      ) {
        _id
        firstName
        lastName
        email
      }
    }
  `;

export default function useMutationProfileAdd() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
