import {gql} from "@apollo/client";
import useWrappedQuery from "../../../common/data-fetching/useWrappedQuery";

export const QUERY_NAME = "candidateById";

export const QUERY = gql`
  query ${QUERY_NAME}($id: ID!) {
    ${QUERY_NAME}(id: $id) {
      _id
      name
      reportId
      contactLines
      note
      viewed
      resumeViewed
      resumeFile {
        filename
      }
      answers {
        _id
        question {
          _id
          text
          mandatory
        }
        answer {
          _id
          text
        }
      }
    }
  }
`;

export default function useQueryCandidateById(id) {

  const variables = {id};

  return useWrappedQuery(QUERY, "", QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response.
    // Do not rely on cache only, so that we see modifications to the questionnaire every time we fetch the candidate
    fetchPolicy: "cache-and-network"
  });
}
