import React from "react";
import InlineEditInputText from "../../../common/widgets/InlineEditInputText";
import CandidateInlineEditInput from "./CandidateInlineEditInput";

/**
 * Generic component for all candidate editable text fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CandidateInlineEditInputText(props) {

  return <CandidateInlineEditInput InlineEditInputComponent={InlineEditInputText} {...props}/>
};
