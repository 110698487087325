import React from "react";
import TypeUtils from "../../../utils/TypeUtils";
import StringUtils from "../../../utils/StringUtils";
import ServerError from "./ServerError";

/**
 * Convert an ApolloError into a normalized error
 * @param error ApolloError
 * @returns {ServerError} Normalized error with a name and a message property
 */
function convertApolloError(error) {
  if (!error) {
    return new ServerError();
  }

  const customName =
    error.extensions && error.extensions.exception
      ? error.extensions.exception.name
      : null;

  return new ServerError(
    !StringUtils.isNullOrEmpty(customName)
      ? customName
      : error.extensions.code,
    error.message
  );
}

/**
 * Convert errors sent by an Apollo GraphQL server into normalized error objects
 * with a name and a message property.
 * @param error Error as returned by a useQuery or useMutation hook
 * @returns {any[] | null} Array of normalized errors, or null if no error
 */
export default function useGraphQLErrorFormatter(error) {

  // Memoize because array address changes even if values are the same from render to render
  return React.useMemo(() => {

    if (!error) return null;

    // See https://www.apollographql.com/docs/react/data/error-handling/
    const newErrors = [];

    // Network error, such as no response or validation failed. There is only one.
    if (error.networkError) {
      if (
        error.networkError.result &&
        !TypeUtils.arrayIsEmpty(error.networkError.result.errors)
      ) {
        // Server responded
        // Example of a response when a validation failed:
        // error.networkError.name = "ServerError"
        // error.networkError.result.errors[0].extensions.code = GRAPHQL_VALIDATION_FAILED
        // error.networkError.result.errors[0].message = "Cannot parse..."
        newErrors.push(convertApolloError(error.networkError.result.errors[0]));
      } else {
        // Server did not respond
        // error.networkError.name = "TypeError"
        // error.networkError.message = "Failed to fetch"
        newErrors.push(new ServerError(error.networkError.name, error.networkError.message));
      }
    }

    // Errors thrown by resolvers. There might be many.
    // Example of a response when a resolver throw a NotFoundError():
    // error.graphQLErrors[0].extensions.code = INTERNAL_SERVER_ERROR
    // error.graphQLErrors[0].extensions.exception.name = "NotFoundError"
    // error.graphQLErrors[0].message = ""

    if (!TypeUtils.arrayIsEmpty(error.graphQLErrors)) {
      error.graphQLErrors.forEach((e) => newErrors.push(convertApolloError(e)));
    }

    return newErrors;
  }, [error]);
}
