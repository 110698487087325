import {gql} from "@apollo/client";
import useWrappedQuery from "../common/data-fetching/useWrappedQuery";

const QUERY_NAME = "profileByEmail";

export const QUERY = gql`
  query ${QUERY_NAME}($email: String!) {
    ${QUERY_NAME}(email: $email) {
      _id
      firstName
      lastName
      role
      settings {
        _id
        reporting {
          _id
          defaultNotificationMessage
        }
      }
    }
  }
`;

export default function useQueryProfileForEmail(email) {

  const variables = {
    email: email
  };

  return useWrappedQuery(QUERY, "", QUERY_NAME, {variables, skip: !email});
}
