import React from "react";
import useShowLoader from "../loading-widgets/useShowLoader";
import StringUtils from "../../../utils/StringUtils";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter";
import useErrorModal from "../../common/modals/useErrorModal";
import ActionLink from "../../common/widgets/ActionLink";
import useTranslationCommon from "../useTranslationCommon";

export default function ResumeDownloadLink(props) {

  const {candidateId, disabled, onFetch, children} = props;

  const onClickDownload = (event) => {
    event.preventDefault();
    setQueryLoading(true);

    return onFetch({id: candidateId}).then(downloadUrl => {

      if (!StringUtils.isNullOrEmpty(downloadUrl)) {
        // This will trigger the file download (window.open(downloadUrl) produces a glitch)
        const a = document.createElement('a');
        a.href = downloadUrl.toString();
        a.click();
      }
    }).catch(error => {
      setError(error);
      showErrorModal();
    }).finally(() => {
      setQueryLoading(false);
    })
  };

  const {t, loading: tLoading} = useTranslationCommon();
  useShowLoader(tLoading, "ResumeDownloadLink");
  // error returned by useLazyQueryResumeDownloadUrl is not updated after fetch, we have to manage it ourselves
  const [error, setError] = React.useState(null);
  const errorMsg = useServerErrorFormatter(error);
  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("common:candidates_resume_download_error")}</p>
      {errorMsg}
    </>
  );

  // loading status returned by useLazyQueryClientDownloadUrl is not updated after fetch, we have to manage it ourselves
  const [queryLoading, setQueryLoading] = React.useState(false);

  return (
    <>{ErrorModal}
      <ActionLink onClick={onClickDownload} disabled={disabled} loading={queryLoading}>{children}</ActionLink>
    </>
  );
}
