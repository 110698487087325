import React from "react";
import InlineEditInputBoolean from "../../../common/widgets/InlineEditInputBoolean";
import ReportInlineEditInput from "./ReportInlineEditInput";

/**
 * Generic component for all report editable boolean fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportInlineEditInputBoolean(props) {

  return <ReportInlineEditInput InlineEditInputComponent={InlineEditInputBoolean} {...props}/>
};
