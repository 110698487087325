import {gql} from "@apollo/client";
import useWrappedMutation from "../../../common/data-fetching/useWrappedMutation";

const QUERY_NAME = "profileUpdateReportingSettings";

export const QUERY = gql`
    mutation ${QUERY_NAME} (
      $profileId: ID!,
      $defaultNotificationMessage: String,
    ) {
      ${QUERY_NAME} (
        profileId: $profileId,
        defaultNotificationMessage: $defaultNotificationMessage,
      ) {
        _id,
        settings {
          _id,
          reporting {
            _id,
            defaultNotificationMessage
          }
        }
      }
    }
  `;

export default function useMutationProfileReportingSettingsUpdate() {

  return useWrappedMutation(QUERY, "", QUERY_NAME);
}
