import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import StringUtils from "../../utils/StringUtils";
import useMutationProfileAdd from "./useMutationProfileAdd";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi";
import useShowLoader from "../common/loading-widgets/useShowLoader";
import useAuthUser from "../authentication-no-ui/useAuthUser";
import useSignOut from "../authentication-no-ui/useSignOut";
import useServerErrorFormatter from "../common/modals/useServerErrorFormatter";
import ActionButtonWithIconGo from "../common/widgets/ActionButtonWithIconGo";
import ActionButtonWithIconCancel from "../common/widgets/ActionButtonWithIconCancel";
import useErrorModal from "../common/modals/useErrorModal";

export default function ProfileCreationForm() {

  const onSubmit = event => {
    event.preventDefault();
    createProfile();
  };

  const createProfile = () => {
    // Manage the loading state manually, don't tie it to the mutation, because we want to control
    // (we want it to stay loading until the user refetch is done)
    setLoading(true);
    setError(null);

    // When creating profile, use email as returned by Firebase because it lowercases it
    return mutate({
      variables: {
        firstName: firstName,
        lastName: lastName,
        email: authUser.email,
      }
    }).then(() => {
      // Refetch complete user, don't stop being in a loading state until refetch is done
      authUser.refetch();
    }).catch(error => {
      // Display error and let the user resubmit
      setError(error);
      setLoading(false);
      showErrorModal();
    });
  }

  const canSubmit = () => {
    return !StringUtils.isNullOrEmpty(firstName);
  };

  const onClickSignOut = event => {
    event.preventDefault();
    signOut();
  }

  const [error, setError] = React.useState(null);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const {mutate} = useMutationProfileAdd();
  const {signOut, loading: signOutLoading, ready: signOutReady} = useSignOut();
  const {t, tLoading} = useTranslationAuthenticationUi();
  const authUser = useAuthUser();
  const errorMessage = useServerErrorFormatter(error ? [error] : []);
  useShowLoader(tLoading || !signOutReady, "ProfileCreationForm");

  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      {errorMessage}
    </>
  );

  return (
    <>
      {ErrorModal}
      <Form onSubmit={onSubmit} className={"form-with-rows"} autoComplete={"off"}>
        <Row>
          <Col>
            <h1>{t("authentication-ui:missing_profile_msg")}</h1>
          </Col>
        </Row>
        <Row>
          <Col className={"form-label form-required"}>
            {t("authentication-ui:sign_up_firstName_placeholder")}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControl
              name="firstName"
              type="text"
              value={StringUtils.nullToEmpty(firstName)}
              onChange={event => setFirstName(event.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className={"form-label"}>
            {t("authentication-ui:sign_up_lastName_placeholder")}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormControl
              name="lastName"
              type="text"
              value={StringUtils.nullToEmpty(lastName)}
              onChange={event => setLastName(event.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className={"form-actions"}>
            <ActionButtonWithIconGo loading={loading} type="submit" disabled={!canSubmit()}>
              {t("authentication-ui:missing_profile_continue_action")}
            </ActionButtonWithIconGo>
            <ActionButtonWithIconCancel onClick={onClickSignOut} loading={signOutLoading}>
              {t("authentication-ui:sign_up_sign_out_action")}
            </ActionButtonWithIconCancel>
          </Col>
        </Row>
      </Form>
    </>
  );
}
