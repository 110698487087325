import React from "react";
import usePublicReportUrl from "./usePublicReportUrl";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import "./ReportPublicLink.scss";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CopyIcon from "@mui/icons-material/Assignment";

export default function ReportPublicLink(props) {

  const {report} = props;
  const publicReportUrl = usePublicReportUrl(report._id);
  const published = report.published;

  const [copied, setCopied] = React.useState(false);

  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportPublicLink");

  return (
    <div className="ReportPublicLink">
      {/* When target is _blank, add rel for security reasons */}
      {published && <a disabled={!published} href={publicReportUrl} target={"_blank"} rel="noopener noreferrer">{publicReportUrl}</a>}
      {!published && <span className={"unpublished-value"}>{publicReportUrl + " "}<span className={"unpublished-label"}>{t("reporting:reports_details_unpublished_label")}</span></span>}
      <CopyToClipboard text={publicReportUrl} onCopy={() => setCopied(true)}><span className={"copy-action"}>
        <CopyIcon titleAccess={t("reporting:reports_details_clipboard_copy_action")}/>
      </span></CopyToClipboard>
      <span className={"copied-label" + (copied ? " active" : "")}>{t("reporting:reports_details_clipboard_copied")}</span>
    </div>
  );
};
