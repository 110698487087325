import React from "react";
import useMutationReportUpdate from "./useMutationReportUpdate";
import useTranslationReporting from "../../useTranslationReporting";
import useShowLoader from "../../../common/loading-widgets/useShowLoader";
import useErrorModal from "../../../common/modals/useErrorModal";
import useServerErrorFormatter from "../../../common/modals/useServerErrorFormatter";
import StringUtils from "../../../../utils/StringUtils";

/**
 * Generic component for all report editable fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ReportInlineEditInput(props) {

  const {InlineEditInputComponent, report, viewAs, validate, placeholder, property, className, ...otherProps} = props;
  const {mutate, loading, errors} = useMutationReportUpdate();
  const {t, loading: tLoading} = useTranslationReporting();
  useShowLoader(tLoading, "ReportInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  const {ErrorModal, show: showErrorModal} = useErrorModal(
    <>
      <p>{t("reporting:reports_details_update_error")}</p>
      {errorMsg}
    </>
  );

  const {ErrorModal: PublicationErrorModal, show: showPublicationErrorModal} = useErrorModal(
    <>
      <p>{t("reporting:reports_details_publication_without_date_error")}</p>
    </>
  )

  const {ErrorModal: DatePublicationErrorModal, show: showDatePublicationErrorModal} = useErrorModal(
    <>
      <p>{t("reporting:reports_details_date_empty_publication_error")}</p>
    </>
  )


  // Check that we don't publish a report without a date
  const validatePublication = React.useCallback(value => {
    if (property === 'published' && value && !report.date) {
      showPublicationErrorModal();
      return false;
    }

    if (property === 'date' && !value && report.published) {
      showDatePublicationErrorModal();
      return false;
    }

    // Input is valid
    return true;
  }, [property, report, showPublicationErrorModal, showDatePublicationErrorModal]);


  const onSubmit = React.useCallback(value => {
    const variables = {};
    variables["id"] = report._id;
    variables[property] = value;

    if (validatePublication(value)) {
      return mutate({variables})
        .catch(error => {
          showErrorModal();
          // rethrow error so that InlineEdit component acts on it
          throw new Error(error);
        });
    } else {
      return Promise.reject();
    }
  }, [property, report, validatePublication, mutate, showErrorModal]);

  return (
    <>
      {ErrorModal} {PublicationErrorModal} {DatePublicationErrorModal}
      <div className={["ReportInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          placeholder={placeholder}
          value={StringUtils.nullToEmpty(report[property])}
          onSubmit={onSubmit}
          validate={validate}
          viewAs={viewAs}
          loading={loading}
          {...otherProps}
        />
      </div>
    </>
  );
}
